import { useState } from 'react'
import TemplateMainHeader from './Templete/CommonComp/TemplateMainHeader'
import BasicDetailsForm from './BasicDetailsForm'
import AddressAutocomplete from '../firstTimeUserExperience/commonCom/AddressAutocomplete'
import { PropertyVM } from '@propertelligent/client-api'

const FormSections = ({
    formDetails, handleChangeMainImage,
    handleDelete, mainImage, setFileName, handleChange, headerName,
    isUploader, isBasicDetail = false, propertyValue = undefined, setPropertyvalue = undefined
}) => {


    return (
        <div className='mb-3'>
            <TemplateMainHeader
                searchTerm={undefined}
                placeholder={undefined}
                setSearchTerm={undefined}
                headerName={headerName}
            />
            <div className="basicDetailsContainer">

                {headerName == "Office Address" &&
                    <div className='ms-2'>
                        <AddressAutocomplete
                            propertyValue={propertyValue}
                            setPropertyvalue={setPropertyvalue}
                            required={false}
                            showSelectedValue={false}
                        />
                    </div>
                }

                <div className="basicDetailsForm">
                    <BasicDetailsForm
                        formDetails={formDetails}
                        handleChangeMainImage={handleChangeMainImage}
                        handleDelete={handleDelete}
                        mainImage={mainImage}
                        setFileName={setFileName}
                        handleChange={handleChange}
                        isUploader={isUploader}
                        isBasicDetail={isBasicDetail}
                    />
                </div>
            </div>
        </div>
    )
}

export default FormSections