import React from 'react';
import StatusTag from '../common/StatusTag';
import ServiceIcon from '../common/serviceTypeImage';
import NameAvatarComponent from '../tenants/NameAvatarComponent';

const PaymentListCard = ({ paymentMode = undefined, data, onCardClick, tenantData, selectedItems }) => {
    
    const firstName = tenantData?.firstName
    const lastName = tenantData?.lastName
    const fullName = firstName + " " + lastName

    console.log("data",data);
    
    return (
        <>
            {
                data.map((item) => {
                    return (
                        <div key={item.id} className="paymentCardContainer" onClick={() => { onCardClick(item) }}>
                            <div className="paymentCardLeft">
                                {paymentMode === "Payables" ? (
                                    <div className="imgcommonstyle">
                                        <ServiceIcon serviceType={item.PaymentTypeId} />
                                    </div>
                                ) : (
                                    tenantData ? (
                                        <NameAvatarComponent
                                        firstName={firstName?.replace(/\s+/g, ' ').trim().split(" ")[0] || ''} 
                                        lastName={lastName?.replace(/\s+/g, ' ').trim().split(" ")[1] || ''}     
                                            heightWidth={44}
                                        />
                                    ) : (
                                        <NameAvatarComponent
                                            firstName={item.payerPayeeName ? item.payerPayeeName.split(" ")[0] : 'Unknown'}
                                            lastName={item.payerPayeeName ? item.payerPayeeName.split(" ")[1] : ''}
                                            heightWidth={44}
                                        />
                                    )
                                )}


                                <div className="paymentCard">
                                    <div className="propertyCardDetails">
                                        <div className="d-flex align-items-center">
                                            <span className="normal_text fw-500">
                                                {tenantData ? fullName : item.payerPayeeName}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="sub_text grey_subtext1">

                                                {paymentMode === "Payables" ?
                                                    (
                                                        <p> {item.formatted?.PaymentTypeId || 'N/A'}</p>
                                                    ) : (
                                                        <>
                                                            <div className='d-flex gap-1 align-items-center'>
                                                                <p>{tenantData ? tenantData.email : item.email}</p>
                                                                <p className="normal_text fw-700 text-nowrap grey_subtext1">•</p>
                                                                <p> {tenantData ? tenantData.phoneNumber : item.phoneNumber}
                                                                </p>
                                                            </div>
                                                        </>
                                                    )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex gap-3'>
                                {paymentMode != "Payables" && item.pastDue ? (
                                    <p className='sub_text'>{item.streetAddress || "B201 - 6868 Collins Ave"}</p>
                                ) : (

                                    ""
                                )}

                            </div>
                            <div className='d-flex gap-3'>
                                {paymentMode === "Payables" && item.pastDue ? (
                                    <StatusTag
                                        color={item.pastDue ? "red" : "blue"}
                                        statusText={`$${item.pastDue || 0} : ${item.pastDue ? "Past Overdue" : "Due in 15 days"}`}
                                    />
                                ) : (
                                    ""
                                )}
                                <p className='heading'>${item.amount || 0}</p>
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
}

export default PaymentListCard;
