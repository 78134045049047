import React, { useCallback, useState } from "react";
import "./tenantonboard.css";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import {
  OnboardingForms,
  OnboardingRentIncomeCheckForm,
} from "../../../typings/onboarding";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import { DocumentEntityTypesEnum, DocumentTypesEnum } from "@propertelligent/client-api";
import CustomInputs from "../../common/Inputs";
import RadioButton from "../../common/RadioButton";
import DragAndDropUploader from "../../common/CustomUploader/DragAndDropUploader";
import DisplayNamewithLeftBorder from "../../common/DisplayNamewithLeftBorder";

const StepTwo: React.FC<any> = ({
  formData,
  errors,
  setFormData,
  handleRemoveError,
  leaseApplicationid
}: {
  formData: OnboardingRentIncomeCheckForm;
  errors: { [key: string]: any };
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  handleRemoveError: (
    field: string,
    form: keyof typeof OnboardingStepsEnums,
    error?: { key: string; subKey?: string }
  ) => void;
  leaseApplicationid:Number
}) => {
  const [docsArr, setDocsArr] = useState([]);
  const [docsArrAssociated, setDocsArrAssociated] = useState([]);

  const handleChange = useCallback((key: string, value: string, subKey?: string) => {
    
    setFormData((prevData: OnboardingForms) => ({
      ...prevData,
      RentIncomeCheck: {
        ...prevData.RentIncomeCheck,
        [key]: subKey ? { ...prevData.RentIncomeCheck?.[key], [subKey]: value }: value,
      },
    }));

    handleRemoveError(key, OnboardingStepsEnums.RentIncomeCheck, { key,subKey, });
  },[]);

  const handleUploadServiceRequestDocument = async (selectedFile) => {
    if (selectedFile) {
      const newDoc = {
        // leaseapplication
        EntityTypeId: DocumentEntityTypesEnum.Tenant,
        EntityId: leaseApplicationid,
        DocumentTypeId: DocumentTypesEnum.IncomeVerification,
        IsMain: false,
        FileName: selectedFile?.name,
        Document: new Blob([selectedFile]),
        _rawFile: selectedFile,
      };

      setDocsArr((prevState) => [...prevState, newDoc]);
    }
  };

  const handleDelete = (fileNameToDelete) => {
    const updatedDocsArr = docsArr.filter((doc) => doc.FileName !== fileNameToDelete);

    setDocsArr(updatedDocsArr);
  };

  const UploadAssociatedRequestDocument = async (selectedFile) => {
    if (selectedFile) {
      const newDoc = {
        EntityTypeId: DocumentEntityTypesEnum.Tenant,
        EntityId: leaseApplicationid,
        DocumentTypeId: DocumentTypesEnum.AssistanceVerification,
        IsMain: false,
        FileName: selectedFile?.name,
        Document: new Blob([selectedFile]),
        _rawFile: selectedFile,
      };

      setDocsArrAssociated((prevState) => [...prevState, newDoc]);
    }
  };

  const handleDeleteAssociated = (fileNameToDelete) => {
    const updatedDocsArr = docsArr.filter((doc) => doc.FileName !== fileNameToDelete);
    setDocsArrAssociated(updatedDocsArr);
  };

  const Radiodata = [
    {
      id: 1,
      label: "Income",
      checked: formData?.payRentVia == "Income",
    },
    {
      id: 2,
      label: "Assisted",
      checked: formData?.payRentVia == "Assisted",
    }, {
      id: 3,
      label: "Both",
      checked: formData?.payRentVia === "Both"
    }
  ]

  const CurrentEmployee = [
    {
      id: 1,
      name: "employerName",
      labelText: "Employer Name*",
      placeholder: "Enter Employer Name",
      myClassName: "containerTextField",
      value: formData?.employmentVerification?.employerName,
      errorMessages: errors?.employmentVerification?.employerName,
      isError: errors?.employmentVerification?.employerName,
      type: FORM_CONTROLFIELD_TYPES.TEXT,
    },
    {
      id: 2,
      name: "income",
      value: formData?.employmentVerification?.income,
      labelText: "Gross Monthly Income",
      placeholder: "$ Gross Monthly Income",
      myClassName: undefined,
      isError: errors?.employmentVerification?.income,
      errorMessages: errors?.employmentVerification?.income,
      type: FORM_CONTROLFIELD_TYPES.TEXT,
    },
    {
      id: 3,
      name:"startDate",
      value: formData?.employmentVerification?.startDate,
      placeholder: "Select a date",
      labelText: "How long have you been employed",
      isError: errors?.employmentVerification?.startDate,
      errorMessages: errors?.employmentVerification?.startDate,
      type: FORM_CONTROLFIELD_TYPES.DATE,

    }

  ]

  const generateBasicDetails = (sectionName) => [
    {
      id: 1,
      name: "firstName",
      labelText: "First Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.[sectionName]?.firstName,
      placeholder: "Enter first name",
      myClassName: "containerTextField",
      isError: errors?.[sectionName]?.firstName,
      errorMessages: errors?.[sectionName]?.firstName,
    },
    {
      id: 2,
      name: "lastName",
      labelText: "Last Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.[sectionName]?.lastName,
      placeholder: "Enter last name",
      myClassName: "containerTextField",
      isError: errors?.[sectionName]?.lastName,
      errorMessages: errors?.[sectionName]?.lastName,
    },
    {
      id: 3,
      name: "email",
      labelText: "Email",
      type: FORM_CONTROLFIELD_TYPES.EMAIL,
      value: formData?.[sectionName]?.email,
      placeholder: "Enter email",
      myClassName: "containerTextField",
      isError: errors?.[sectionName]?.email,
      errorMessages: errors?.[sectionName]?.email,
    },
    {
      id: 4,
      name: "phoneNumber",
      labelText: "Phone",
      type: FORM_CONTROLFIELD_TYPES.NUMBER,
      value: formData?.[sectionName]?.phoneNumber,
      placeholder: "Enter phone no",
      myClassName: "containerTextField",
      isError: errors?.[sectionName]?.phoneNumber,
      errorMessages: errors?.[sectionName]?.phoneNumber,
    },
  ];

  const AuthorityDetail = [
    {
      id: 1,
      name: "AuthorityName",
      labelText: "Authority Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData?.socialWorkerDetails?.AuthorityName,
      placeholder: "Enter last name",
      myClassName: "containerTextField",
      isError: errors?.socialWorkerDetails?.AuthorityName,
      errorMessages:
        errors?.socialWorkerDetails?.AuthorityName
      ,

    },
    {
      id: 2,
      name: "AuthorityAmount",
      labelText: "Authority Amount",
      type: FORM_CONTROLFIELD_TYPES.CURRENCY,
      value: formData?.socialWorkerDetails?.AuthorityAmount,
      placeholder: "$ Enter Amount",
      myClassName: "containerTextField",
      isError: errors?.socialWorkerDetails?.AuthorityAmount,
      errorMessages: errors?.socialWorkerDetails?.AuthorityAmount,
    }
  ]

  return (
    <>
      <div className="onboarding-stepper">
        <div className="flexColumn gap-3">
          <p className="main_heading main_heading py-2">How you will be paying rent?</p>
          <div className="flexRow gap-5 ">
            {Radiodata?.map((item) => (
              <div key={item?.id}>
              <RadioButton
                id={`${item.id}`}
                label={item.label}
                value={formData?.payRentVia}
                name={"payRentVia"}
                checked={item.checked}
                onChange={() => handleChange("payRentVia", item.label)}
              />
              </div>
            ))}
          </div>
          {formData?.payRentVia === "Income" ||
            formData?.payRentVia === "Both" ? (
            <div className="onboarding-steppercard">
              <div className="onboardingstepper-form">
                <DisplayNamewithLeftBorder HeaderName={"Current Employer details"} />
                <div className="basic-form py-3">
                  {CurrentEmployee?.map((item) => (
                    <div key={item?.id}>
                    <CustomInputs
                      name={item.name}
                      type={item.type}
                      value={item.value}
                      labelText={item.labelText}
                      isFormControl={true}
                      onChange={(e) => handleChange("employmentVerification", e.target.value, item.name)}
                      placeholder={item.placeholder}
                      myClassName={item.myClassName}
                      isError={item.isError}
                      errorMessages={item.errorMessages}
                    />
                    </div>
                  ))}
                </div>
                <div>
                  <DisplayNamewithLeftBorder HeaderName={"CONTACT DETAILS"} />
                  <div className="social-worker py-3">
                    {generateBasicDetails("contactDetails")?.map((item) => (
                       <div key={item?.id}>
                      <CustomInputs
                        name={item.name}
                        type={item.type}
                        value={item.value}
                        labelText={item.labelText}
                        isFormControl={true}
                        onChange={(e) => handleChange("contactDetails", e.target.value, item.name)}
                        placeholder={item.placeholder}
                        myClassName={item.myClassName}
                        isError={item.isError}
                        errorMessages={item.errorMessages}
                      />
                      </div>
                    ))}
                  </div>
                </div>
                <DragAndDropUploader handleChange={handleUploadServiceRequestDocument} docsArr={docsArr} handleDelete={handleDelete} name="Income Verification Document (W2, 1099, PayStubs etc)" />
              </div>
            </div>
          ) : null}
          {/* Income Verification end here */}

          {/* Assisted Verification start here */}
          {formData?.payRentVia === "Assisted" ||
            formData?.payRentVia === "Both" ? (
            <div className="onboarding-steppercard">
              <div className="onboardingstepper-form">
                <div>
                  <DisplayNamewithLeftBorder HeaderName={"ASSITED DETAILS"} />
                  <div className="authority_form py-3">
                    {AuthorityDetail?.map((item) => (
                       <div key={item?.id}>
                      <CustomInputs
                        name={item.name}
                        type={item.type}
                        value={item.value}
                        labelText={item.labelText}
                        isFormControl={true}
                        onChange={(e) => handleChange("socialWorkerDetails", e.target.value, item.name)}
                        placeholder={item.placeholder}
                        myClassName={item.myClassName}
                        isError={item.isError}
                        errorMessages={item.errorMessages}
                      />
                      </div>
                    ))}
                  </div>
                  <div>
                    <DisplayNamewithLeftBorder HeaderName={"YOUR SOCIAL WORKER DETAILS"} />
                    <div className="social-worker py-3">
                      {generateBasicDetails("socialWorkerDetails")?.map((item) => (
                        <div key={item?.id}>
                          <CustomInputs
                            name={item.name}
                            type={item.type}
                            value={item.value}
                            labelText={item.labelText}
                            isFormControl={true}
                            onChange={(e) => handleChange("socialWorkerDetails", e.target.value, item.name)}
                            placeholder={item.placeholder}
                            myClassName={item.myClassName}
                            isError={item.isError}
                            errorMessages={item.errorMessages}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <DragAndDropUploader handleChange={UploadAssociatedRequestDocument} docsArr={docsArrAssociated} handleDelete={handleDeleteAssociated} name="Income Verification Document (W2, 1099, PayStubs etc)" />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default StepTwo;
