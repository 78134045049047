import React from 'react'
import RequestBreadcrumb from '../../common/RequestBreadcrumb'
import NameAvatarComponent from '../NameAvatarComponent'
import { URLS } from '../../../constants/constants'
import { GetImages } from '../../../utils/GetImages'
import LeaseDetails from '../../common/LeaseDetails'
import MoveOutDetails from './MoveOutDetails'
import FinalPayments from './FinalPayments'
import InstructionsAndInfo from './InstructionsAndInfo'
import { Button } from 'react-bootstrap'
import AddButtonWithArrow from '../../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { useNavigate } from 'react-router'

const MoveInAndOutContentBlocks = ({
    leaseData,
    downloadDocument,
    isUploading,
    submitHandler,
    initialOptions,
    formData,
    setFormData,
    pickupInstruction,
    setPickupInstruction,
    additionalInfo,
    setAdditionalInfo,
    isMoveOut = false
}) => {
    const navigate = useNavigate()

    return (
        <div className='move_out_container' id="tenantFormContainer">
            <RequestBreadcrumb
                firstName={"Tenant"}
                link={URLS.TENANTS}
                currentStep={"Moveout"}
            />
            <div className='moveOutContentSection'>
                <div className='moveOutSectionContainer'>
                    <div className="d-flex newLeftSideBox">
                        <NameAvatarComponent
                            firstName={"Brando"}
                            lastName={"Harris"}
                            heightWidth={48}
                        />
                        <div>
                            <div className="d-flex align-items-center gap-1">
                                <p className="sub_heading black mb-1">
                                    Brando Harris
                                </p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <img height={16} width={16} src={GetImages.homeIconOrange}></img>
                                <p className="sub_text grey_subtext1">
                                    5250 - Truman Dr
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex gap-3'>
                        <div className="mb-2 d-flex justify-content-start align-items-center gap-2">
                            <img
                                width={18}
                                height={18}
                                src={GetImages.mailIconOrange}
                            />
                            <p className="normal_text black">
                                smilesdavis@gmail.com
                            </p>
                        </div>

                        <div className=" d-flex justify-content-start align-items-center gap-2">
                            <img
                                width={18}
                                height={18}
                                src={GetImages.phoneIconOrange}
                            />
                            <p className="normal_text black">
                                (+1) 281-671-5221
                            </p>
                        </div>
                    </div>
                </div>
                <LeaseDetails
                    allLeaseData={undefined}
                    options={undefined}
                    selectedOption={undefined}
                    handleLeaseChange={undefined}
                    userData={undefined}
                    leaseData={leaseData}
                    downloadDocument={downloadDocument}
                    isUploading={isUploading}
                    isView={true}
                />

                {isMoveOut && <MoveOutDetails />}

                <form onSubmit={submitHandler}>
                    <FinalPayments
                        initialOptions={initialOptions}
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <InstructionsAndInfo
                        pickupInstruction={pickupInstruction}
                        setPickupInstruction={setPickupInstruction}
                        additionalInfo={additionalInfo}
                        setAdditionalInfo={setAdditionalInfo}
                    />

                    <div className="newTanentBottomBar gap-3">
                        <div>
                            <Button onClick={() => navigate(-1)} className="cancelButtonNewTanentBottomBar sub_text fw-600">
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <AddButtonWithArrow
                                type={"submit"}
                                buttonname={"Send Welcome Email"}
                                disabled={undefined}
                                iconDirection="left"
                                Icon={GetImages.WhiteForwardArrow}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default MoveInAndOutContentBlocks