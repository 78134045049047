import React, { useEffect, useState, useCallback } from "react";
import {
  PropertyService,
  UnitService,
  DocumentService,
  DocumentEntityTypesEnum,
  DocumentTypesEnum,
  UnitVM,
} from "@propertelligent/client-api";
import { UserLoginData } from "../common/charts/Enums";
import UnitForm from "./UnitForm/index";
import ErrorAlert from "../tenants/ErrorAlert";
import apiHelper from "../../utils/apiHelper";

interface Props {
  setShow?: any;
  unitData?: any;
  generatedMainImage?: any;
  fetchUnitDetails?: any;
  setMainImageId?: (newNumber: number) => void;
}

export const UnitCreate: React.FC<Props> = ({
  setShow,
  unitData,
  generatedMainImage,
  fetchUnitDetails,
  setMainImageId,
}) => {
  const organization_id = parseInt(
    localStorage.getItem(UserLoginData.organization_id)
  );
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [mainImage, setMainImage] = useState<any>(generatedMainImage);
  const [uploadImage, setUploadImage] = useState<Blob | null>(null);
  const [fileName, setFileName] = useState("");
  const [questionaireError, setQuestionaireError] = useState(false);
  const [unitVal, setUnitVal] = useState<UnitVM | any>(
    unitData || {
      propertyId: 0,
      mainImageId: 0,
    }
  );
  const [updatedMainImageIdObj, setUpdatedMainImageIdObj] = useState({
    mainImageId: 0,
  });

  useEffect(() => {
   apiHelper(PropertyService.getApiPropertyOrganizationlookup,{showNotification:false},organization_id)
      .then((response: any) => {
        setProperties(response?.data?.success);
      })
      .catch((ptyListErr: any) => { });
  }, []);

  const handleChangeMainImage = (selectedFile: File | null) => {
    setFileName(selectedFile?.name);
    const blob = new Blob([selectedFile], { type: "image/png|jpg" });
    setMainImage(blob);
    setUploadImage(blob);
    if (unitData?.id && unitData?.id > 0) {
      const imgData = {
        EntityTypeId: DocumentEntityTypesEnum.Unit,
        EntityId: Number(unitData?.id),
        DocumentTypeId: DocumentTypesEnum.BeforeImage,
        IsMain: true,
        Document: blob,
        FileName: selectedFile?.name,
      };

      apiHelper(DocumentService.postApiDocument,{showNotification:false},imgData)
        .then((imageResponse: any) => {
          const currentMainImageId = imageResponse?.data?.success?.id;

          setUpdatedMainImageIdObj({ mainImageId: currentMainImageId });
          DocumentService.getApiDocumentBase641(currentMainImageId)
            .then((imageResponse: any) => {
              const imageData = "data:image/*;base64," + imageResponse?.data?.success;
              setMainImage(imageData);
              if (setMainImageId) {
                setTimeout(() => {
                  setMainImageId(currentMainImageId);
                }, 1000);
              }
            })
            .catch((imageErr: any) => { });
        })
        .catch((imageErr: any) => { });
    }
  };

  const handleDelete = () => {
    setMainImage(null);
    setFileName("");
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedProperty(event.target.value);
      setUnitVal((prevUnitVal) => ({
        ...prevUnitVal,
        propertyId: Number(event.target.value), // Ensure propertyId is a number
      }));
    },
    []
  );

  const generateAndPostImgObj = async (currentUnitId: number) => {
    const imgData = {
      EntityTypeId: DocumentEntityTypesEnum.Unit,
      EntityId: Number(currentUnitId),
      DocumentTypeId: DocumentTypesEnum.BeforeImage,
      IsMain: true,
      Document: uploadImage,
      FileName: fileName,
    };

    apiHelper(DocumentService.postApiDocument,{showNotification:false},imgData)
      .then((imageResponse: any) => {
        setUpdatedMainImageIdObj({ mainImageId: imageResponse?.data?.success?.id });
        apiHelper(DocumentService.getApiDocumentBase641,{showNotification:false},imageResponse?.data?.success?.id)
          .then((imageResponse: any) => {
            const imageData = "data:image/*;base64," + imageResponse?.data?.success;
            setMainImage(imageData);
            if (setMainImageId) {
              setMainImageId(imageResponse?.data?.success?.id);
            }
          })
          .catch((imageErr: any) => { });
      })
      .catch((imageErr: any) => { });
  };

  const handleSubmit = async (e: any) => {
    // we can handle for both Create & Update purpose:
    try {
      e.preventDefault();
      if (unitData) {
        //Means EditUnit called
        if (updatedMainImageIdObj?.mainImageId != 0) {
          Object.assign(unitVal, {
            mainImageId: updatedMainImageIdObj?.mainImageId,
          });
        }
        apiHelper(UnitService.putApiUnit,{successMessage:"Unit Updated succesfully",failureMessage:"",showNotification:true},unitVal)
          .then((res: any) => {
            if (res.data.success) {
              setShow(false);
              fetchUnitDetails();
            }
          })
          .catch((putErr: any) => {
            setQuestionaireError(true);
          });
      } else {
        //Means NewUnit called
        const Newdata = await apiHelper(UnitService.postApiUnit,{successMessage:"Unit added successfully",failureMessage:"Unable to add unit",showNotification:true},unitVal);
        if (Newdata?.data?.success) {
          if (mainImage) {
            await generateAndPostImgObj(Newdata?.data?.success?.id);
            setTimeout(() => {
              setShow(false);
            }, 500);
          } else {
            setTimeout(() => {
              setShow(false);
            }, 500);
          }
          setUnitVal({});
        }
      }
    } catch (error) { }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="add-unit-canvas">
        <UnitForm
          title="Add Unit"
          properties={properties}
          handleChange={handleChange}
          mainImage={mainImage}
          setFileName={setFileName}
          unitVal={unitVal}
          setUnitVal={setUnitVal}
          handleDelete={handleDelete}
          selectedProperty={selectedProperty}
          handleChangeImage={handleChangeMainImage}
          setShow={setShow}
          isEditForm={!!unitData}
        />
      </form>

    </>
  );
};
