import React from "react";
import { Button, Modal } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";

const CommonConfirmation = ({
  onConfirm,
  onClose,
  heading,
  subHeading,
  confirmLabel,
  cancelLabel = "Cancel",
  show
}) => {

  return (
    <div className="p-3">
      <Modal
        className="commonConfirmationModal d-flex align-items-center justify-content-center"
        show={show}
        onHide={onClose}
      >
        <div className="d-flex flex-column align-items-between justify-content-between h-100 p-3">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="heading black">{heading}</p>
              {!onClose ? "":
               <img
               className="clickable"
               onClick={() => onClose()}
               src={GetImages.closeIconBlack}
             />
              }
             
            </div>
            <p style={{ whiteSpace: "wrap" }} className="typeMailPhoneText normal_text  mt-2">
              {subHeading}
            </p>
          </div>
          <div className="d-flex justify-content-end align-items-center gap-3 mt-3">
          {!onClose ? "": 
          <p onClick={() =>onClose()} className="normal_text fw-600 blue clickable">
          {cancelLabel}
        </p>
          }
            <button onClick={onConfirm} className="saveChangesButton1 sub_text fw-600">
              {confirmLabel}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CommonConfirmation;
