import EditCustomComponent from '../tenants/EditCustomComponent';
import { Col, Image, Row } from "react-bootstrap";
import Uploader from '../firstTimeUserExperience/commonCom/Uploader';
import Delete from "../../assets/Delete.png";
import Replace from "../../assets/Replace.png";

const BasicDetailsForm = ({
    handleChangeMainImage, handleDelete, mainImage,
    setFileName, formDetails, handleChange, isUploader = false,
    isBasicDetail = false
}) => {
    return (
        <>
            {isUploader &&
                <Row className="paymentinputrow">
                    <Col>
                        <div className="uploader-add-tenant mb-2">
                            <div className="addressUploader">
                                <div className="uploader clickable">
                                    <Uploader
                                        handleChange={handleChangeMainImage}
                                        handleDelete={handleDelete}
                                        mainImage={mainImage}
                                        setFileName={setFileName}
                                        name="Add Logo"
                                        Size={{ size: "Icon120", iconSize: "Icon32" }}
                                    />
                                    {mainImage && (
                                        <div>
                                            <div className="deletereplacemain">
                                                <div
                                                    onClick={() => setFileName(null)}
                                                    className="deletereplace"
                                                >
                                                    <Image src={Replace} className="iconstyle" />
                                                    <p className="replace">Replace</p>
                                                </div>
                                                <div className="divider"></div>
                                                <div onClick={handleDelete} className="deletereplace">
                                                    <Image src={Delete} className="iconstyle" />
                                                    <p className="delete">Delete</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <p className='small_text grey_subtext1'>
                            Image format jpeg and png. Max image size 2 MB
                        </p>
                    </Col>
                </Row>
            }
            <div className="d-flex addContractorInputContainer1"
                style={{ flexDirection: isBasicDetail ? "column" : "row" }}
            >
                {formDetails.map((item) => {
                    return (
                        <EditCustomComponent
                            key={item.label}
                            label={item.label}
                            placeholder={item.placeholder}
                            value={item.value}
                            name={item.name}
                            onChangeFunction={handleChange}
                            onBlurFunction={item.blurFunction}
                            errorMessages={item.errorMessages}
                            isError={item.isError}
                            linkWebsite={item.linkWebsite}
                            width="332px"
                            borderRadius="4px"
                            type={item.type}
                        />
                    );
                })}
            </div>
        </>
    )
}

export default BasicDetailsForm