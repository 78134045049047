import { useEffect, useState } from "react";
import { CommunicationService, MessageVM, TenantService, } from "@propertelligent/client-api";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import NameAvatarComponent from "../../tenants/NameAvatarComponent";
import ChatBox from "./ChatBox";
import EmptyData from "../../common/EmptyData";
import AddButton from "../../firstTimeUserExperience/commonCom/AddButton";
import { GetImages } from "../../../utils/GetImages";
import { UserLoginData } from "../../common/charts/Enums";
import DateHelper from "../../../utils/DateHelper";
import Search from "../../common/search";
import SimpleSpinner from "../../loader";

const useScreenWidth = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobileView;
};

const ChatView = () => {
  const [searchValues, setSearchValues] = useState("");
  const [entityId, setEntityId] = useState();
  const [entityTypeId, setEntityTypeId] = useState();
  const [chatname, setChatName] = useState("");
  const [showSection, setShowSection] = useState("");
  const [messageChannel, setMessageChannel] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobileView = useScreenWidth();
  const [showMessage, setShowMessage] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [tenantList, settenantList] = useState([]);

  const [messageData, setmessageData] = useState<MessageVM>({
    messageData: "",
    entityTypeId: entityTypeId,
    entityId: entityId,
  });

  const fetchMessageChannel = (fromClick = false) => {
    if (isMobileView) {
      setShowMessage(true);
    }

    setLoading(true);
    const searchQuery =
      typeof searchValues === "string" ? searchValues.toLowerCase() : "";

    CommunicationService.getApiMessageOrganizationlistMessagechannels(
      parseInt(localStorage.getItem(UserLoginData.organization_id)),
      undefined,
      undefined,
      undefined,
      `usr.FirstName.ToLower().Contains("${searchQuery}") or usr.LastName.ToLower().Contains("${searchQuery}")`
    )
      .then((res) => {
        setMessageChannel(res.success);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMessageChannel();
  }, [searchValues, messageChannel?.length, entityId]);

  const handleSearchChange = (value) => {
    if (typeof value === "string") {
      setSearchValues(value);
    }
  };

  const fetchList = () => {
    TenantService.getApiTenantOrganizationlookup(
      parseInt(localStorage.getItem(UserLoginData.organization_id)),
      undefined,
      undefined,
      undefined,
      `User.FirstName.ToLower().Contains("${inputValue.toLowerCase()}")or User.LastName.ToLower().Contains("${inputValue.toLowerCase()}")`
    )
      .then((res) => {
        settenantList(res.success);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    fetchList();
  }, [inputValue]);

  const fullName = localStorage.getItem(UserLoginData.user_name) || "";
  const nameParts = fullName.split(/\s+/).filter(Boolean);

  const firstName = nameParts[0] || "";
  const lastName = nameParts[1] || "";

  return (
    <>
      <div className="chatMainContainer">
        <div className="chatSubContainer ">
          {isMobileView ? (
            !showMessage && (
              <div className="chatLeftSection ">
                <div className="chatHeader d-flex flex-row justify-content-between">
                  <p className="heading black">Messages</p>
                  <AddTraspButton
                    onClick={() => {
                      // setEntityId(null);
                      setShowSection("selectChat");
                    }}
                    bname="New Message"
                    iconPosition="right"
                    imageValue={GetImages.BlueAdd}
                    border={false}
                  />
                </div>
                <div className="searchSection ">
                  <Search
                    placeholderName="Search chat"
                    searchFilterChange={handleSearchChange}
                    searchValue={searchValues || ""}
                  />

                  <div className="chatFilter">
                    <img src={GetImages.Filter} className="iconstyle" />
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="chatLeftSection ">
              <div className="chatHeader d-flex flex-row justify-content-between">
                <p className="heading black">Messages</p>
                <AddTraspButton
                  onClick={() => {
                    // setEntityId(null);
                    setShowSection("selectChat");
                  }}
                  bname="New Message"
                  iconPosition="right"
                  imageValue={GetImages.BlueAdd}
                  border={false}
                />
              </div>
              <div className="searchSection ">
                <Search
                  placeholderName="Search chat"
                  searchFilterChange={handleSearchChange}
                  searchValue={searchValues || ""}
                />

                <div className="chatFilter">
                  <img src={GetImages.Filter} className="iconstyle" />
                </div>
              </div>

              {loading ? (
                <SimpleSpinner />
              ) : (
                <div
                  className="chatItemContaner "
                  style={{ overflowX: "hidden" }}
                >
                  {messageChannel?.length == 0 ? (
                    <EmptyData
                      mainText="No Message Here"
                      subText="Please click the button below to start the conversation"
                      button={
                        <AddButton
                          onClick={undefined}
                          bname="Start Conversation"
                        />
                      }
                    />
                  ) : (
                    messageChannel?.map((item, i) => {
                      // const name = item?.name?.split(" ");
                      const name = item?.name?.split(/\s+/).filter(Boolean);
                      return (
                        <div
                          className="chatItem "
                          style={{
                            background:
                              item.entityId === entityId
                                ? "var(--Primary-Next-level-Lighter, #EBF5FF)"
                                : "",
                          }}
                          key={i}
                          onClick={() => {
                            setEntityId(item.entityId);
                            setEntityTypeId(item?.entityTypeId);
                            setChatName(item?.name);
                            setShowSection("chat");
                            fetchMessageChannel();
                          }}
                        >
                          <div className="userIcon">
                            <NameAvatarComponent
                              firstName={name[0]}
                              lastName={name[1]}
                              heightWidth={40}
                            />
                          </div>

                          <div className="chatDetailsView">
                            <div className=" d-flex flex-row justify-content-between align-items-center w-100">
                              <p className="normal_text black fw-500">
                                {item?.name}
                              </p>
                              <p className="sub_text  grey_subtext1">
                                {DateHelper.convertUTCtoDateTime(
                                  item?.lastMessageDateTime,
                                  true
                                )}
                              </p>
                            </div>
                            <div className="message_count sub_text d-flex justify-content-between align-items-center w-100 ">
                              <p
                                className="sub_text  grey_subtext1"
                                id="message-data"
                              >
                                {item?.lastMessageData}
                              </p>
                              {item.unreadMessagesCount > 0 ? (
                                <div className="meesageCount">
                                  {" "}
                                  <p className="small_text white">
                                    {item.unreadMessagesCount}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              )}
            </div>
          )}
          {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

          {isMobileView ? (
            showMessage && (
              <div className="chatRightSection ">
                <ChatBox
                  tenantId={undefined}
                  setShowMessage={setShowMessage}
                  isMobileView={isMobileView}
                  showMessage={showMessage}
                  entityId={entityId}
                  setEntityId={setEntityId}
                  entityTypeId={entityTypeId}
                  chatname={chatname}
                  setChatName={setChatName}
                  setEntityTypeId={setEntityTypeId}
                  fetchData={fetchMessageChannel}
                  messageData={messageData}
                  setmessageData={setmessageData}
                  tenantList={tenantList}
                  firstName={firstName}
                  lastName={lastName}
                />
              </div>
            )
          ) : (
            <div className="chatRightSection ">
              <ChatBox
                tenantId={undefined}
                setShowMessage={setShowMessage}
                entityId={entityId}
                setEntityId={setEntityId}
                entityTypeId={entityTypeId}
                chatname={chatname}
                setChatName={setChatName}
                setEntityTypeId={setEntityTypeId}
                fetchData={fetchMessageChannel}
                messageData={messageData}
                setmessageData={setmessageData}
                tenantList={tenantList}
                firstName={firstName}
                lastName={lastName}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatView;
