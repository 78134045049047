import * as Yup from "yup";
import {
  OnBoardingRentIncomeEnum,
  OnboardingStepsEnums,
} from "../../../constants/onboarding";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const basicDetailsSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required(),
    password: Yup.string().required(),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(),
});

const rentIncomeCheckSchema = Yup.object({
  payRentVia: Yup.string()
    // .oneOf(Object.keys(OnBoardingRentIncomeEnum))
    .required(),
  employmentVerification: Yup.object({
    employerName: Yup.string().when(
      "$payRentVia",
      (payRentVia: any, schema) => {
        if (
          [
            OnBoardingRentIncomeEnum.Income,
            OnBoardingRentIncomeEnum.Both,
          ].includes(payRentVia[0])
        )
          return schema.required("Must enter employerName");
        return schema.nullable();
      }
    ),
   
    income: Yup.string().when("$payRentVia", (payRentVia: any, schema) => {
      if (
        [
          OnBoardingRentIncomeEnum.Income,
          OnBoardingRentIncomeEnum.Both,
        ].includes(payRentVia[0])
      )
        return schema.required("Must enter income");
      return schema.nullable();
    }),
    startDate: Yup.string().when("$payRentVia", (payRentVia: any, schema) => {
      if (
        [
          OnBoardingRentIncomeEnum.Income,
          OnBoardingRentIncomeEnum.Both,
        ].includes(payRentVia[0])
      )
        return schema.required("Must enter startDate");
      return schema.nullable();
    }),
  }),
  socialWorkerDetails: Yup.object({
    firstName: Yup.string().when("$payRentVia", (payRentVia: any, schema) => {
      if (
        [
          OnBoardingRentIncomeEnum.Assisted,
          OnBoardingRentIncomeEnum.Both,
        ].includes(payRentVia[0])
      )
        return schema.required("Must enter firstName");
      return schema.nullable();
    }),
    lastName: Yup.string().when("$payRentVia", (payRentVia: any, schema) => {
      if (
        [
          OnBoardingRentIncomeEnum.Assisted,
          OnBoardingRentIncomeEnum.Both,
        ].includes(payRentVia[0])
      )
        return schema.required("Must enter lastName");
      return schema.nullable();
    }),
    AuthorityName: Yup.string().when("$payRentVia", (payRentVia: any, schema) => {
      if (
        [
          OnBoardingRentIncomeEnum.Assisted,
          OnBoardingRentIncomeEnum.Both,
        ].includes(payRentVia[0])
      )
        return schema.required("Must enter AuthorityName");
      return schema.nullable();
    }),
    AuthorityAmount: Yup.string().when("$payRentVia", (payRentVia: any, schema) => {
      if (
        [
          OnBoardingRentIncomeEnum.Assisted,
          OnBoardingRentIncomeEnum.Both,
        ].includes(payRentVia[0])
      )
        return schema.required("Must enter AuthorityAmount");
      return schema.nullable();
    }),
    email: Yup.string()
      .email()
      .when("$payRentVia", (payRentVia: any, schema) => {
        if (
          [
            OnBoardingRentIncomeEnum.Assisted,
            OnBoardingRentIncomeEnum.Both,
          ].includes(payRentVia[0])
        )
          return schema.required("Must enter email");
        return schema.nullable();
      }),
    phoneNumber: Yup.string().when("$payRentVia", (payRentVia: any, schema) => {
      if (
        [
          OnBoardingRentIncomeEnum.Assisted,
          OnBoardingRentIncomeEnum.Both,
        ].includes(payRentVia[0])
      )
        return schema.required("Must enter phoneNumber");
      return schema.nullable();
    }),
  }),
  contactDetails: Yup.object({
    firstName: Yup.string().when("$payRentVia", (payRentVia: any, schema) => {
      if (
        [
          OnBoardingRentIncomeEnum.Income,
          OnBoardingRentIncomeEnum.Both,
        ].includes(payRentVia[0])
      )
        return schema.required("Must enter firstName");
      return schema.nullable();
    }),
    lastName: Yup.string().when("$payRentVia", (payRentVia: any, schema) => {
      if (
        [
          OnBoardingRentIncomeEnum.Income,
          OnBoardingRentIncomeEnum.Both,
        ].includes(payRentVia[0])
      )
        return schema.required("Must enter lastName");
      return schema.nullable();
    }),
    email: Yup.string()
      .email()
      .when("$payRentVia", (payRentVia: any, schema) => {
        if (
          [
            OnBoardingRentIncomeEnum.Income,
            OnBoardingRentIncomeEnum.Both,
          ].includes(payRentVia[0])
        )
          return schema.required("Must enter email");
        return schema.nullable();
      }),
    phoneNumber: Yup.string().when("$payRentVia", (payRentVia: any, schema) => {
      if (
        [
          OnBoardingRentIncomeEnum.Income,
          OnBoardingRentIncomeEnum.Both,
        ].includes(payRentVia[0])
      )
        return schema.required("Must enter phoneNumber");
      return schema.nullable();
    }),
  }),
});

const creditCheckSchema = Yup.object({
  socialSecurityNumber: Yup.string().required(),
  creditPermission: Yup.string().oneOf(["yes", "no"]),
});

const employmentHistorySchema = Yup.object({
  employerName: Yup.string().required(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required(),
});

const employmentHistoryArraySchema = Yup.array().of(employmentHistorySchema);

const questionnaireSchema = Yup.object({
  // movingInDate: Yup.string().required(),
  // // reasonForMovingIn: Yup.number(),
  // arrestAdditionalDetails: Yup.string().required(),
  // evictAdditionalDetails: Yup.string().required(),
});

const referencesSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required(),
  referenceType: Yup.string().required(),
});
const referencesSchemaArraySchema = Yup.array().of(referencesSchema);
const applicationFeesSchema = Yup.object({
  feeSubmitOptions: Yup.string().oneOf(["Card", "Bank"]),
  nameOnCard: Yup.string().required("Must enter nameOnCard"),
  cardNumber: Yup.string().when(
    "feeSubmitOptions",
    (feeSubmitOptions: any, schema) => {
      if (feeSubmitOptions == "Card")
        return schema.required("Must enter feeSubmitOptions");
      return schema.nullable();
    }
  ),
  billingZipCode: Yup.string().when(
    "feeSubmitOptions",
    (feeSubmitOptions: any, schema) => {
      if (feeSubmitOptions == "Card")
        return schema.required("Must enter billingZipCode");
      return schema.nullable();
    }
  ),
  expiryDate: Yup.string().when(
    "feeSubmitOptions",
    (feeSubmitOptions: any, schema) => {
      if (feeSubmitOptions == "Card")
        return schema.required("Must enter expiryDate");
      return schema.nullable();
    }
  ),
  cvv: Yup.string().when(
    "feeSubmitOptions",
    (feeSubmitOptions: any, schema) => {
      if (feeSubmitOptions == "Card") return schema.required("Must enter cvv");
      return schema.nullable();
    }
  ),

  // feeSubmitOptions == "Bank"
  accountNumber: Yup.string().when(
    "feeSubmitOptions",
    (feeSubmitOptions: any, schema) => {
      if (feeSubmitOptions == "Bank")
        return schema.required("Must enter accountNumber");
      return schema.nullable();
    }
  ),
  routingNumber: Yup.string().when(
    "feeSubmitOptions",
    (feeSubmitOptions: any, schema) => {
      if (feeSubmitOptions == "Bank")
        return schema.required("Must enter routingNumber");
      return schema.nullable();
    }
  ),
  // saveDetails: Yup.string().when(
  //   "feeSubmitOptions",
  //   (feeSubmitOptions: any, schema) => {
  //     if (feeSubmitOptions == "Bank")
  //       return schema.required("Must enter saveDetails");
  //     return schema.nullable();
  //   }
  // ),
});

export const OnboardingStepsSchemas = {
  BasicDetails: basicDetailsSchema,
  RentIncomeCheck: rentIncomeCheckSchema,
  CreditCheck: creditCheckSchema,
  EmploymentHistory: employmentHistoryArraySchema,
  References: referencesSchemaArraySchema,
  // Questionnaire: questionnaireSchema,
  ApplicationFeesClause: applicationFeesSchema,
};
