import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import penGray from "../../../assets/pen-gray.png";
import { useNavigate } from "react-router";
import CustomDropDownMenu from "../../common/CustomDropDownMenu";
import { GetImages } from "../../../utils/GetImages";
import AddButton from "../../firstTimeUserExperience/commonCom/AddButton";

const QuestionHeader = ({ postData, setPostData ,duplicateTemplete ,deleteTemplete }) => {
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [action, setAction] = useState("Action");
  const [updatePostData, setUpdatePostData] = useState(postData);
  const [editedTemplateName, setEditedTemplateName] = useState(
    postData?.name || ""
  );
  const [editedDescription, setEditedDescription] = useState(
    postData?.description || ""
  );
  const [editedApplicationFee, setEditedApplicationFee] = useState(
    postData?.applicationFee || 0
  );
  useEffect(() => {
    setUpdatePostData(postData);
    setEditedApplicationFee(postData?.applicationFee);
    setEditedDescription(postData?.description);
    setEditedTemplateName(postData?.name);
  }, [postData]);

  const handleSave = () => {
    const updatedPostValue = {
      ...postData,
      name: editedTemplateName,
      description: editedDescription,
      applicationFee: editedApplicationFee,
    };
    setUpdatePostData(updatedPostValue);
    setPostData(updatedPostValue);
    setEditMode(false);
  };

  const data = [
    {
      id: 1,
      img: GetImages.CopyBlue,
      onClick: () => duplicateTemplete() ,
      name: "Duplicate",
      linkpath: undefined,
      textClass: 'sub_text fw-600 blue'

    },
    {
      id: 2,
      img: postData?.isInUse==false ?  GetImages.deleteIconRed :GetImages.greyDelete,
      name: "Delete",
      onClick: () => deleteTemplete(),
      linkpath: undefined,
      textClass:postData?.isInUse==false ? 'sub_text fw-600 red' :'sub_text  fw-600 grey'
    },

  ]
 
   

  return (
    <>
      <div className="application-top-card">
        {!editMode ? (<>
          <div className="application-top-card-left">
            <div className="d-flex align-items-center justify-content-start gap-2">
              <p className="normal_text black">Template Name*  </p>
              <p className="main_heading black">{postData.name} </p>             
                <Button variant="primary" onClick={() => setEditMode(true)}>
                  <img src={penGray} alt="pen-gray.png"  width={16}
                 height={16} className="styleIcon" />
                </Button>
                </div>               
                <p className="normal_text black">Template Description</p>
                <p className="normal_text black fst-italic">{postData?.description}</p>             
          </div>
          <div className="application-top-card-right">
               <>
               <div className="actionsDropdownContainer clickable">
               < CustomDropDownMenu Action={true} Element={<img src={GetImages.BlueChevronDown} alt='BlueChevronDown'  />} data={data} />
                </div>
                <div>
                  <p className="normal_text black">Application Fee</p>
                  <p className="normal_text black"> ${postData.applicationFee}</p>
                  </div>
              </>          
          </div>
        </>)
          : (
            <>
              <div className="application-top-card-edit ">
                <div className="application-top-card-edit-last gap-3 ">                
                    <div className=" d-flex justify-content-center w-100 ">                     
                      <label>Template Name* </label>
                      <input
                        className="formFeild"
                        type="text"
                        value={postData.name}
                        onChange={                       
                       (e) => 
                         { setPostData({...postData,name:e.target.value})
                           setEditedTemplateName(e.target.value)
                        }}
                      />
                    </div>                
                    <div className=" d-flex align-items-center justify-content-center justify-content-center w-100">
                      <label>Application Fee</label>
                      <input
                        className="formFeild applicationFeild"
                        type="text"
                        value={postData.applicationFee}
                        onChange={
                          (e) =>
                         { setEditedApplicationFee(e.target.value)
                          setPostData({...postData,applicationFee:e.target.value})
                         }
                        }
                      />
                    </div>                
                </div>
                <div className="application-top-card-edit-last gap-3">                
                    <div className="d-flex align-items-center justify-content-center d-flex  w-100">
                      <p className="normal_text black">Template Description: </p>
                      <input
                        type="text"
                        className="formFeild descriptionfeild "
                        value={postData.description}
                        onChange={(e) => 
                          { setPostData({...postData,description:e.target.value})
                          setEditedDescription(e.target.value)}
                         }
                      />
                    </div>
                    <div>
                    <AddButton
                      onClick={handleSave}
                      bname="Save"
                    />
                  </div>
                </div>
              </div>
            </>)}

      </div>
    </>
  );
};

export default QuestionHeader;
