import React from 'react'
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder'

const ApplicationCard = ({cardHeaderName ,component ,editButton=null}) => {
  return (
    <>
      <div className="common_application_card m-2">
            <div className={` ${editButton ? ' common_application_card_header m-3 d-flex justify-content-between align-items-center' :" common_application_card_header m-3"} `}>
            <DisplayNamewithLeftBorder HeaderName={cardHeaderName} />
            {editButton}
            </div>
            <div className="common_application_card_detail">
          { component}
            </div>
           </div>
    </>
  )
}

export default ApplicationCard