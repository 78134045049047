import React, { useCallback, useEffect, useMemo, useState } from "react";
import QuestionHeader from "../QuestionHeader";
import { ChoiceOptionVM, QuestionTypeEnum, QuestionVM, TemplateEntityTypesEnum, TemplateSectionVM, TemplateService, TemplateTypeEnum, TemplateVM } from "@propertelligent/client-api";
import { useNavigate, useParams } from "react-router";
import "./AddTemplate.css"
import RequestBreadcrumb from "../../../common/RequestBreadcrumb";
import AddTraspButton from "../../../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButtonWithArrow from "../../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../../utils/GetImages";
import QuestionOptional from "../QuestionOptional";
import EmploymentHistoryEdit from "../EmploymentHistoryEdit";
import Reference from "../RefrenceEdit";
import QuestionHistoryEdit from "../QuestionEditTemplete";
import FeeTemplete from "../FeeTemplete";
import ConfirmSignature from "../ConfirmSignature";
import BoardingReview from "../../../TenantOnboard/RenderSteps/BoardingReview";
import apiHelper from "../../../../utils/apiHelper";


const AddTemplate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeSection, setActiveSection] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editSection, setEditSection] = useState(false);
  const [postData, setPostData] = useState<any | undefined>(
    {
      name: "Tenant Application 2",
      description: "Application for prospective tenants",
      templateTypeId:TemplateTypeEnum.LeaseApplication,
      entityTypeId: TemplateEntityTypesEnum.Organization,
      entityId: 507,
      isInUse: false,
      applicationFee: 50,
       templateSections: {
        CreditCheck: { requiredRange: 2 },
        RentIncomeCheck: { requiredRange: 2 },
        EmploymentHistory: { requiredRange: 2 },
        References: { requiredRange: 2 },
        Questionnaire:
          { questions: [{ questionTypeId: 1, questionText: "Do you smoke?", isRequired: false, choices: [{ id:1, optionText: "Click to Change" }], followUpQuestionChoiceId: 1, followUpQuestionText: "Please provide details?" }] },
          SignatureConfirmation: { clause: "Are You agree for everyting" },
        FeeDetails: { clause: " Fee is non refundable" }
      } as TemplateSectionVM,
    })
 
  useEffect(() => {
    if (id) {
      getTempleteData();
    }
  }, [id]);

  useEffect(() => {

  }, [postData]);

  const buttonClose = () => {
    navigate("/templates");
  }


  const handleSubmit = useCallback(async () => {
    
    try {
      setIsLoading(true);
      const apiCall = id
        ? TemplateService.putApiTemplate(id, postData) // PUT for update
        : TemplateService.postApiTemplate(postData);   // POST for create

      // Await the API response
      const res = await apiCall;

    

      if (res.success) {
        navigate("/organization/templates");
      }
    } catch (error) {
      console.error("Error occurred while submitting data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [id, postData, navigate]);
  
  const getTempleteData = () => {
    TemplateService.getApiTemplate(Number(id))
      .then((res) => {
        if (res?.success) {
          setPostData(res?.success);        
        }
      })
      .catch((err) => {

      });

  };

  const duplicateTemplete = () => {

    if(id){
    TemplateService.postApiTemplateDuplicate(Number(id))
      .then((res) => {
        if (res?.success) {        
          navigate(`/organization/templates/edit/${res?.success.id}`);
        }
      })
      .catch((err) => {

      });
    }
  };

  const deleteTemplete = () => {
    if(id){    
    TemplateService.deleteApiTemplate(Number(id))
      .then((res) => {      
        navigate("/organization/templates");
      })
      .catch((err) => {
        
      });
    }
  };


  const HandleEditFeature = (sectionName: string) => {
    if (sectionName) {
      setActiveSection(sectionName);
    } else {
      setActiveSection("");
    }
  };

  const handleSectionToggle = useCallback(
    (sectionName: string) => {
      setPostData((prevData) => {
        const newData = { ...prevData };
  
        // Ensure templateSections is initialized
        newData.templateSections = newData.templateSections || {};
  
        if (newData.templateSections[sectionName]) {
          delete newData.templateSections[sectionName]; // Disable (remove)
        } else {
          newData.templateSections[sectionName] = getDefaultSectionDetails(sectionName); // Enable (add)
        }
  
        return newData;
      });
    },
    [setPostData] // Dependencies
  );

  const getDefaultSectionDetails = (sectionName: string) => {
    switch (sectionName) {
      case "RentIncomeCheck":
      case "CreditCheck":
      case "EmploymentHistory":
      case "References":
        return { requiredRange: 2 };
      case "Questionnaire":
        return {
          questions: [
            {
              questionTypeId: 1,
              questionText: "Do you smoke?",
              choices: [
                { id: 1, optionText: "Click to Change" },
                { id: 2, optionText: "Click to Change" },
              ],
              followUpQuestionChoiceId: 1,
              followUpQuestionText: "Please provide details?",
            },
          ],
          requiredRange: 8,
        };
      case "FeeDetails":
        return { clause: "Fee is non refundable" };
      case "SignatureConfirmation":
        return { clause: "Are You agree for everyting" };
      default:
        return {};
    }
  };

  const data = useMemo(() =>[
    {
      id: 1,
      name: "Credit Check",
      disbale:postData?.templateSections?.["CreditCheck"] ? true :false,
      editable: false,
      onClick: () => handleSectionToggle("CreditCheck"),
      editClick: undefined,
      setEditSection: setEditSection

    },
    {
      id: 2,
      name: "Rent Income Check",
      disbale:postData?.templateSections?.["RentIncomeCheck"] ?true :false,
      editable: false,
      onClick: () => handleSectionToggle("RentIncomeCheck"),
      editClick: undefined,
      setEditSection: setEditSection

    },
    {
      id: 3,
      name: "Employment History",
      disbale:postData?.templateSections?.["EmploymentHistory"] ?true :false,
      editable: true,
      onClick: () => handleSectionToggle("EmploymentHistory"),
      editClick: () => HandleEditFeature("EmploymentHistory"),
      setEditSection: setEditSection

    },
    {
      id: 4,
      name: "References",
      disbale:postData?.templateSections?.["References"] ?true :false,
      editable: true,
      onClick: () => handleSectionToggle("References"),
      editClick: () => HandleEditFeature("References"),
      setEditSection: setEditSection

    },
    {
      id: 5,
      name: "Questionnaire",
      disbale:postData?.templateSections?.["Questionnaire"] ?true :false,
      editable: true,
      onClick: () => handleSectionToggle("Questionnaire"),
      editClick: () => HandleEditFeature("Questionnaire"),
      setEditSection: setEditSection,


    },
    {
      id: 6,
      name: "Signature Confirmation",
      editable: true,
       disbale:postData?.templateSections?.["SignatureConfirmation"] ?true :false,
      onClick: () => handleSectionToggle("SignatureConfirmation"),
      editClick: () => HandleEditFeature("SignatureConfirmation"),
      setEditSection: setEditSection

    },
    {
      id: 7,
      name: "Fee Details",
      editable: true,
       disbale:postData?.templateSections?.["FeeDetails"] ?true :false,
      onClick: () => handleSectionToggle("FeeDetails"),
      editClick: () => HandleEditFeature("FeeDetails"),
      setEditSection: setEditSection

    },
 


  ], [postData, handleSectionToggle, setEditSection,id]);


  return (
    <>
      <div className="application-section">
        <RequestBreadcrumb
          firstName={"Template"}
          link={"/organization/templates"}
          currentStep={"Questionnaire template"}
        />
        <div className="application-section-main">
          <QuestionHeader postData={postData} setPostData={setPostData} duplicateTemplete={duplicateTemplete} deleteTemplete={deleteTemplete}/>
          <section className="application_review">
         <div className="application_review_left">
          <QuestionOptional
            data={data}
            postData={postData}
          />
        </div>

        <div className="application_review_right">
          {activeSection === "EmploymentHistory" && (
            <EmploymentHistoryEdit
              postData={postData}
              setPostData={setPostData}
            />
          )}
          {activeSection === "References" && (
            <Reference
              postData={postData}
              setPostData={setPostData}
            />
          )}
          {activeSection === "Questionnaire" && (
            <QuestionHistoryEdit
             postData={postData}
              setPostData={setPostData}            

            />
          )}
          {activeSection === "FeeDetails" && (
            <FeeTemplete

              postData={postData}
              setPostData={setPostData}
              

            />
          )}
          {activeSection === "SignatureConfirmation" && (
            <ConfirmSignature            
              postData={postData}
              setPostData={setPostData}
            />
          )}
          {!activeSection && (
            <BoardingReview
              postData={postData}
              setPostData={setPostData}
              editable={false}
              Template={true}
              name="invite-tenant"
            />
          )}
        </div>


      </section>

          <div className="application-section-bttom-view">
            <AddTraspButton bname={"Cancel"} iconPosition={undefined} onClick={buttonClose} border={true} />
            <AddButtonWithArrow buttonname={isLoading ? "Saving..." : "Save"} onClick={handleSubmit} disabled={undefined} iconDirection={"left"} Icon={GetImages.WhiteForwardArrow} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTemplate;
