import { useState } from 'react'
import apiHelper from '../../utils/apiHelper'
import { DocumentService } from '@propertelligent/client-api'
import { convertStreamAndDownload } from '../../utils/helper'
import MoveInAndOutContentBlocks from './common/MoveInAndOutContentBlocks'

const MoveOut = () => {
    const [isUploading, setIsUploading] = useState(false)
    const [pickupInstruction, setPickupInstruction] = useState("")
    const [additionalInfo, setAdditionalInfo] = useState("")
    const initialOptions = [
        { name: "Security Deposit", value: "Security Deposit" },
        { name: "Final rent payment", value: "Final rent payment" },
        { name: "Electricity payment", value: "Electricity payment" },
        { name: "Bills", value: "Bills" },
        { name: "Others", value: "Others" },
    ]

    const [formData, setFormData] = useState([
        {
            id: 1,
            paymentType: 'Security Deposit',
            amount: 200,
            dueDate: '2024-04-18',
            gracePeriod: 3,
            description: 'Security Deposit',
        },
        {
            id: 2,
            paymentType: 'Final rent payment',
            amount: 200,
            dueDate: '2024-04-18',
            gracePeriod: 3,
            description: 'Final rent payment',
        },
    ]);

    const leaseData = [
        {
            "id": 3083,
            "unitId": 6375,
            "tenantId": 3220,
            "tenantLeaseTypeId": 2,
            "leaseStart": "2024-04-17T02:21:53.262819Z",
            "leaseEnd": "2025-04-17T02:21:53.262819Z",
            "securityDeposit": 0.0,
            "rent": 1602,
            "leaseDocumentId": null,
            "formatted": {}
        }
    ]

    const downloadDocument = (id, fileName) => {
        setIsUploading(true);

        apiHelper(DocumentService.getApiDocumentBase641, { showNotification: false }, id)
            .then((docRes) => {
                //Conversion alogorithm
                convertStreamAndDownload(docRes?.data?.success, fileName);
                setIsUploading(false);
            })
            .catch((docErr) => {
                setIsUploading(false);
            });
    };

    const submitHandler = (e) => {
        e.preventDefault();
        // console.log("formData", formData);
        // console.log("pickupInstruction", pickupInstruction);
        // console.log("additionalInfo", additionalInfo);
    }


    return (
        <MoveInAndOutContentBlocks
            leaseData={leaseData}
            downloadDocument={downloadDocument}
            isUploading={isUploading}
            submitHandler={submitHandler}
            initialOptions={initialOptions}
            formData={formData}
            setFormData={setFormData}
            pickupInstruction={pickupInstruction}
            setPickupInstruction={setPickupInstruction}
            additionalInfo={additionalInfo}
            setAdditionalInfo={setAdditionalInfo}
            isMoveOut={true}
        />
    )
}

export default MoveOut