import NewSelectAuto from '../NewSelectAuto'
import { useError } from '../../../utils/context/ErrorContext';

const PropertyUnitSelect = ({
    selectBasic,
    isPropertyDisabled,
    setIsPropertyDisabled,
    selectedProperty,
    setSelectedProperty,
    propertyData,
    setUnitData,
    propertySelectError,
    setPropertySelectError,
    isUnitDisabled,
    setIsUnitDisabled,
    selectedUnit,
    setSelectedUnit,
    unitData,
    unitSelectError,
    setUnitSelectError,
    selectedTemplate,
    setSelectedTemplate,
    templeteData,
    allUnitData = undefined
}) => {
    const { errors, errorMessages } = useError();

    return (
        <>
            <div className="newTenantDesciptionContainer">
                <p className="newTenantDescriptionText sub_text black">
                    {selectBasic == "1" ? "Select Property & Unit" : "Select Unit"}
                </p>
            </div>
            <div className="newTenantSelectInputs d-flex">
                {selectBasic == "1" ? (
                    <>
                        <div>
                            <NewSelectAuto
                                disabled={isPropertyDisabled}
                                setDisabled={setIsPropertyDisabled}
                                selected={selectedProperty}
                                setSelected={setSelectedProperty}
                                data={propertyData && propertyData}
                                title={"Wait till the data fetches"}
                                type="property"
                                setResetDependencies={setUnitData}
                                error={propertySelectError}
                                setError={setPropertySelectError}
                                fromContractor={true}
                                label='Property *'
                            />
                            {propertySelectError && (
                                <p className="errorMessage"> {errorMessages.property} </p>
                            )}
                        </div>

                        <div>
                            <NewSelectAuto
                                disabled={isUnitDisabled}
                                setDisabled={setIsUnitDisabled}
                                selected={selectedUnit}
                                setSelected={setSelectedUnit}
                                data={unitData && unitData}
                                title={"Please select property first"}
                                type="unit"
                                error={unitSelectError}
                                setError={setUnitSelectError}
                                fromContractor={true}
                                label='Unit *'
                            />
                            {unitSelectError && (
                                <p className="errorMessage"> {errorMessages.unit} </p>
                            )}
                        </div>
                    </>
                ) : (
                    <div>
                        <NewSelectAuto
                            disabled={false}
                            setDisabled={undefined}
                            selected={selectedUnit}
                            setSelected={setSelectedUnit}
                            data={allUnitData && allUnitData}
                            title={undefined}
                            type="Unit"
                            error={unitSelectError}
                            setError={setUnitSelectError}
                            fromContractor={true}
                            label='Unit *'
                        />
                        {unitSelectError && (
                            <p className="errorMessage"> {errorMessages.unit} </p>
                        )}
                    </div>
                )
                }
            </div>
            {selectBasic == "1" && (
                <>
                    <NewSelectAuto
                        disabled={isPropertyDisabled}
                        setDisabled={setIsPropertyDisabled}
                        selected={selectedTemplate}
                        setSelected={setSelectedTemplate}
                        data={templeteData && templeteData}
                        title={"Wait till the data fetches"}
                        type="templete"
                        error={propertySelectError}
                        setError={setPropertySelectError}
                        fromContractor={true}
                        label='Select Template *'
                    />
                    {errors.template && (
                        <p className="errorMessage"> {errorMessages.template} </p>
                    )}
                </>
            )}
        </>
    )
}

export default PropertyUnitSelect