import React, { createContext, useContext, useState } from 'react';

const PaymentContext = createContext(null);

export const PaymentProvider = ({ children }) => {
    const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]); 
    const [selectedOption, setSelectedOption] = useState({});
    const [tenantId,setTenantId] = useState()

    const values = { 
        payeeAccountsArr,
        setPayeeAccountsArr,
        selectedItems,
        setSelectedItems,
        selectedOption,
        setSelectedOption,
        tenantId,
        setTenantId
    }

    return (
        <PaymentContext.Provider value={values}>
            {children}
        </PaymentContext.Provider>
    );
};

export const usePaymentContext = () => {
    return useContext(PaymentContext);
};

export { PaymentContext };
