import React from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { GetImages } from '../../../utils/GetImages'

const DragAndDropUploader = ({handleChange,docsArr ,handleDelete,name}) => {
  return (
    <>
         <div className="dragAndDropUploader_main ">
         <p className="normal_text">
               {name}
              </p>
            <div className="fileUploader_first">
              <FileUploader
                handleChange={handleChange}
                classes="fileuploaderbutton clickable"
                children={
                  <>
                    <div className="dragAndDropUploader">
                      <img
                        width={24}
                        height={24}
                        src={GetImages.upload}
                      />
                      <div>
                        <p>
                          Drag & Drop or{" "}
                          <span className="highlightedText">
                            {" "}
                            Browse
                          </span>{" "}
                        </p>
                        <p> .pdf, .png, .jpeg; Max file size: 5mb</p>
                      </div>
                    </div>
                  </>
                }
              />

            </div>
            <div className="fileDeleteSection">
              {docsArr.length > 0 &&
                docsArr.map((doc) => (
                  <div
                    style={{ width: "296px" }}
                    className="d-flex align-items-center justify-content-between documentContainerEditForm "
                  >
                    <div className="d-flex gap-2 align-items-center">
                      <img width={20} height={24} src={GetImages.pdfImage} />
                      <p className="licenseDocumentText">
                        {doc?.FileName}
                      </p>
                    </div>
                    <img width={20}
                      height={20}
                      onClick={() => handleDelete(doc.FileName)}
                      src={GetImages.deleteIconRed}
                    />
                  </div>
                ))}

            </div>
          </div>
    </>
  )
}

export default DragAndDropUploader