import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { GetImages } from "../../utils/GetImages";
import DisplayNamewithLeftBorder from "../common/DisplayNamewithLeftBorder";
import { Col, Form, Row, Image } from "react-bootstrap";
import CustomInputs from "../common/Inputs";
import { FORM_CONTROLFIELD_TYPES, URLS } from "../../constants/constants";
import AddButtonWithArrow from "../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import AddPayee from "./Modals/AddPayee";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import Delete from "../../assets/deleteIconRed.svg";
import { FileUploader } from "react-drag-drop-files";
import apiHelper from "../../utils/apiHelper";
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, EntityType, PaymentService, PropertyService, TenantService, UnitService } from "@propertelligent/client-api";
import { UserLoginData } from "../common/charts/Enums";
import { useError } from "../../utils/context/ErrorContext";
import DynamicAddMoreForm from "../common/DynamicAddMoreForm";
import LastTransaction from "./Modals/LastTransaction";

const AddPaymentForm = ({ onConfirm = (x) => { } }) => {
  const [paymentMode, setPaymentMode] = useState("Payables");
  const [show, setShow] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [duePaymentModal, setDuePaymentModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState("Organization");
  const [propertyData, setPropertyData] = useState<any>([]);
  const [unitData, setUnitData] = useState<any>([]);
  const [tenantData, setTenantData] = useState<any>([]);
  const [selectedTenant, setSelectedTenant] = useState<string>("");
  const [selectedUnit, setSelectedUnit] = useState<string>('');
  const [selectedProperty, setSelectedPropery] = useState<string>('');
  const [isPropertyDisabled, setIsPropertyDisabled] = useState<boolean>(true);
  const [isUnitDisabled, setIsUnitDisabled] = useState<boolean>(true);
  const [payeeAccountId, setPayeeAccountId] = useState("");
  const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [paymentTypeId, setPaymentTypeId] = useState()
  const [fileName, setFileName] = useState<any>([]);
  const [mainImage, setMainImage] = useState<any>();
  const [fileType, setFileType] = useState("image");
  const [uploadDocument, setUploadDocument] = useState<any>();
  const [paymentDetails, setPaymentDetails] = useState([
    {
      dueDate: "",
      amount: "",
      gracePeriods: "",
      description: "",
      refrenceNum: "",
    },
  ]);

  const navigate = useNavigate();

  const accountOptions = [
    { id: 1, name: "Organization" },
    { id: 2, name: "Property" },
    { id: 3, name: "Unit" },
  ];


  const initialOptions = [
    { name: "Security Deposit", value: "Security Deposit" },
    { name: "Final rent payment", value: "Final rent payment" },
    { name: "Electricity payment", value: "Electricity payment" },
    { name: "Bills", value: "Bills" },
    { name: "Others", value: "Others" },
  ]

  const [formData, setFormData] = useState([
    {
      id: 1,
      paymentType: 'Security Deposit',
      amount: 200,
      dueDate: '2024-04-18',
      gracePeriod: 3,
      description: 'Security Deposit',
    },
    {
      id: 2,
      paymentType: 'Final rent payment',
      amount: 200,
      dueDate: '2024-04-18',
      gracePeriod: 3,
      description: 'Final rent payment',
    },
  ]);


  const checkEmptyFields = (): boolean => {

    clearErrors();
    const newErrors = { ...errors };
    const newErrorMessages = { ...errorMessages };
    let hasErrors = false;
    paymentDetails.forEach((payment, index) => {
      if (!payment.dueDate.trim()) {
        newErrors[`dueDate-${index}`] = true;
        newErrorMessages[`dueDate-${index}`] = "Due date is required";
        hasErrors = true;
      } else {
        newErrors[`dueDate-${index}`] = false;
      }

      if (!payment.gracePeriods) {
        newErrors[`gracePeriods-${index}`] = true;
        newErrorMessages[`gracePeriods-${index}`] = "Grace period is required";
        hasErrors = true;
      } else {
        newErrors[`gracePeriod-${index}`] = false;
      }

      if (!payment.amount) {
        newErrors[`amount-${index}`] = true;
        newErrorMessages[`amount-${index}`] = "Amount is required";
        hasErrors = true;
      } else {
        newErrors[`amount-${index}`] = false;
      }

      if (!payment.refrenceNum) {
        newErrors[`refrenceNum-${index}`] = true;
        newErrorMessages[`refrenceNum-${index}`] =
          "Reference number is required";
        hasErrors = true;
      } else {
        newErrors[`refrenceNum-${index}`] = false;
      }

      if (!payment.description.trim()) {
        newErrors[`description-${index}`] = true;
        newErrorMessages[`description-${index}`] = "Description is required";
        hasErrors = true;
      } else {
        newErrors[`description-${index}`] = false;
      }
    });

    setErrors(newErrors);
    setErrorMessages(newErrorMessages);

    return hasErrors;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (checkEmptyFields()) {
    } else {
      const paymentData = {
        payeeAccountId,
        paymentDetails,
      };
      onConfirm(paymentData);
    }
  };

  const addPaymentRow = () => {
    clearErrors();

    setPaymentDetails([
      ...paymentDetails,
      {
        dueDate: "",
        amount: "",
        gracePeriods: "",
        description: "",
        refrenceNum: "",
      },
    ]);
  };



  const headers = ["Amount ($)", "Due Date", "Grace Period", "Description", ""]

  const handleAddPayment = () => {
    setFormData([
      ...formData,
      {
        id: formData.length + 1,
        paymentType: '',
        amount: 0,
        dueDate: '',
        gracePeriod: 0,
        description: '',
      },
    ]);
  };
  const handleImageChange = (selectedFile: File | null) => {
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadDocument(blob);
      setMainImage(blob);
    } else {
      setFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadDocument(blob);
      setFileType("file");
      fetchImageBlob();
    }
  };
  const fetchImageBlob = async () => {
    try {
      const response = await fetch(GetImages.pdfImage);
      const blob = await response.blob();
      setMainImage(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const uploadDocumentFunction = (e) => {
    if (uploadDocument) {
      const docData = {
        EntityTypeId: DocumentEntityTypesEnum.Payment,
        EntityId: paymentTypeId,
        DocumentTypeId: DocumentTypesEnum.PaymentReference,
        IsMain: false,
        FileName: fileName,
        Document: uploadDocument,
      };

      apiHelper(DocumentService.postApiDocument, { showNotification: false }, docData)
        .then((res) => {
          setUploadDocument(null);
          setMainImage(null);
          // getDocuments();
        })
        .catch((error) => { });
    }
  };

  console.log("paymentIDdd", paymentTypeId);


  const deletePaymentRow = (index) => {
    if (paymentDetails.length > 1) {
      const updatedPaymentDetails = paymentDetails.filter(
        (_, i) => i !== index
      );
      setPaymentDetails(updatedPaymentDetails);
    } else {
      alert("At least one payment detail must remain.");
    }
  };

  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));

  const getPayeeList = async () => {
    await apiHelper(
      PaymentService.getApiPayeeAccountOrganizationlist,
      { showNotification: false },
      orgId
    )
      .then((res: any) => {
        if (res?.data?.success) {
          setPayeeAccountsArr(res?.data?.success);
          setPaymentTypeId(res?.data?.success?.paymentTypeId)
          console.log("ressssss payment", res?.data?.success);

        }
      })
      .catch((error) => { });
  };

  const getPropertyList = async () => {
    await apiHelper(PropertyService.getApiPropertyOrganizationlookup, { showNotification: false }, orgId)
      .then((res) => {
        setPropertyData(res?.data?.success);
        setIsPropertyDisabled(false);
      })
      .catch((error) => { });
  };

  const getUnitList = async () => {
    await apiHelper(UnitService.getApiUnitOrganizationlookup, { showNotification: false }, orgId)
      .then((res) => {
        setUnitData(res?.data?.success || []);
        setIsUnitDisabled(false);
      })
      .catch((error) => { });
  };

  const getTenantsData = async () => {
    try {
      const res = await apiHelper(TenantService.getApiTenantOrganizationlist, { showNotification: false }, orgId);
      setTenantData(res?.data?.success);
      if (res?.data?.success && res.data.success.length > 0) {
        setSelectedTenant(res.data.success[0].firstName);
      }
    } catch (error) {
    }
  };

  const handlePaymentModeChange = (mode: "Payables" | "Receivables") => {
    setPaymentMode(mode);
  };

  const handleEdit = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setTransactionModal(false)
    setDuePaymentModal(false)
  };

  useEffect(() => {
    getPayeeList();
    getTenantsData();
  }, []);

  const handleCardClick = (paymentData) => {
    navigate(`/payment/settle-payment/${paymentData.payeeAccountId}`, { state: { selectedPayment: paymentData, paymentMode } });
  };

  const handleTransactionModal = () => {
    setTransactionModal(true)
  }

  const handleSubmitPaymentAccountRecord = ({ values, payvalues }) => {
    const {
      description,
      dueDate,
      amount,
      entityId,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      isRecordPayment,
      paymentTypeId,
    } = values;
    const { fromPaymentMethodId } = payvalues;

    const updatedValues = {
      amount,
      description,
      dueDate: new Date(dueDate).toISOString(),
      entityId,
      entityTypeId: EntityType.Property,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      fromPaymentMethodId,
      isRecordPayment,
      paymentTypeId,
    };

    apiHelper(
      PaymentService.postApiPaymentDueAccount,
      {
        successMessage: "Payment added successfully",
        failureMessage: "Unable to add payment",
        showNotification: true,
      },
      updatedValues
    )
      .then((res: any) => {
        if (res?.data?.success) {
          console.log("response paymentID", res?.data?.success);

        }
      })
      .catch((error) => { });
  };

  return (
    <>
      <div className="addPayment">
        <div className="newTenantFormTopBar d-flex align-items-center">
          <img
            onClick={() => {
              navigate(-1);
            }}
            height={28}
            width={28}
            src={GetImages.leftChevronBlack}
            className="clickable"
          />
          <p className="main_heading black">Add Payment</p>
        </div>

        <DisplayNamewithLeftBorder
          HeaderName="BASIC DETAIL"
          className="sub_text ps-1"
        />

        <div className="monthhead pt-3">
          <button
            onClick={() => handlePaymentModeChange("Payables")}
            className={
              paymentMode === "Payables"
                ? "overviewSelectedButton normal_text fw-500 black"
                : "overviewUnselectedButton normal_text fw-500 black"
            }
          >
            <img src={GetImages.Payables} alt="payables" />
            Payables
          </button>
          <button
            onClick={() => handlePaymentModeChange("Receivables")}
            className={
              paymentMode === "Receivables"
                ? "overviewSelectedButton normal_text fw-500 black"
                : "overviewUnselectedButton normal_text fw-500 black"
            }
          >
            <img src={GetImages.Receivables} alt="Receivables" />
            Receivables
          </button>
        </div>
        <div className="settlePaymentLeft">
          <Form className="paymentForm" onSubmit={handleFormSubmit}>
            {paymentMode === "Payables" && (
              <Row className="paymentinputrow p-0">
                <div className="d-flex flex-column">
                  <div className="selectPayee">
                    <div>
                      <Col className="paymentinputCol p-0">
                        <Form.Group className="mb-2">
                          <Form.Label className="normal_text  grey_subtext1">
                            Select Payee*
                          </Form.Label>
                          <Form.Select
                            className="normal_text grey_subtext1"
                            value={payeeAccountId}
                            onChange={(e) => setPayeeAccountId(e.target.value)}
                          >
                            <option value="">Select Payee</option>
                            {payeeAccountsArr.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.accountName}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback
                            type="invalid"
                            className="invalid-feedback"
                          >
                            Please select a Payee.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </div>
                    {
                      payeeAccountId && payeeAccountId !== "" ?
                        <>
                          <div className="transactionBtn">
                            <AddTraspButton bname="View Last 5 Transactions" border={true} type="button" onClick={() => { setTransactionModal(true) }} />
                            <AddTraspButton bname="View All Dues" border={true} type="button" onClick={() => { setDuePaymentModal(true) }} />
                          </div>
                        </>
                        : ""
                    }
                    {transactionModal &&
                      <LastTransaction
                        onClose={handleClose}
                        heading={"Last 5 Transactions"}
                        show={true}
                        payeeAccountId={payeeAccountId}
                        duePayment={false}
                      />
                    }
                    {duePaymentModal &&
                      <LastTransaction
                        payeeAccountId={payeeAccountId}
                        onClose={handleClose}
                        heading={"Due Payments"}
                        show={true}
                        duePayment={true} />
                    }

                  </div>

                  <Form.Label className="sub_text  grey_subtext1">
                    Can’t see the payee? To create new,{" "}
                    <span className="navigateMe" onClick={() => handleEdit()}>
                      {" "}
                      click here
                    </span>
                    {show ? (
                      <AddPayee
                        onClose={handleClose}
                        heading="Add Payee"
                        show={true}
                      />
                    ) : (
                      ""
                    )}
                  </Form.Label>
                </div>
              </Row>
            )}

            <div>
              <Row className="paymentinputrow">
                {
                  paymentMode === "Payables" && (
                    <Col className="paymentinputCol p-0">
                      <Form.Group controlId="paymentMethodSelect">
                        <Form.Label className="normal_text black">
                          Attach Payment to
                        </Form.Label>
                        <Form.Select onChange={(e) => setSelectedAttachment(e.target.value)} className="normal_text">
                          <option value="">Select Attach Payment to</option>
                          {accountOptions.map((item) => (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback
                          type="invalid"
                          className="invalid-feedback"
                        >
                          Please select account.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  )
                }

                {paymentMode === "Receivables" && (

                  <Col className="paymentinputCol">
                    <Form.Group className="mb-2">
                      <Form.Label className="normal_text  grey_subtext1">
                        Select  Tenant
                      </Form.Label>
                      <Form.Select
                        className="normal_text grey_subtext1"
                        value={selectedTenant}
                        onChange={(e) => setSelectedTenant(e.target.value)}
                      >
                        <option value="">Select Tenant</option>
                        {tenantData.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.firstName + " " + item.lastName}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback
                        type="invalid"
                        className="invalid-feedback"
                      >
                        Please select Tenant.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}


                {selectedAttachment === "Property" && (
                  <Col className="paymentinputCol">
                    <Form.Group controlId="paymentMethodSelect">
                      <Form.Label className="normal_text black">
                        Select Property
                      </Form.Label>
                      <Form.Select
                        value={selectedProperty}
                        onClick={getPropertyList}
                        onChange={(e) => setSelectedPropery(e.target.value)}
                        className="normal_text">
                        <option value="">Select Property</option>
                        {propertyData.map((item) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback
                        type="invalid"
                        className="invalid-feedback"
                      >
                        Please select account.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}

                {selectedAttachment === "Unit" && (
                  <Col className="paymentinputCol">
                    <Form.Group controlId="paymentMethodSelect">
                      <Form.Label className="normal_text black">
                        Select Unit
                      </Form.Label>
                      <Form.Select
                        value={selectedUnit}
                        onClick={getUnitList}
                        onChange={(e) => setSelectedUnit(e.target.value)}
                        className="normal_text"
                      >
                        <option value="">Select Unit</option>
                        {unitData.map((item) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback
                        type="invalid"
                        className="invalid-feedback"
                      >
                        Please select a unit.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}

                <Col className="paymentinputCol">
                  <div
                    className="d-flex flex-column justify-content-center w-100"
                    style={{ maxWidth: "240px" }}
                  >
                    <div className="labelContainer">
                      <p className="normal_text black mb-2">Upload Document</p>
                    </div>
                    <FileUploader
                      classes="documentUploaderContractor w-100"
                      handleChange={handleImageChange}
                      children={
                        <>
                          <div className="fileUploaderTenantDocument normal_text w-100">
                            <img
                              width={24}
                              height={24}
                              src={GetImages.upload}
                            />
                            <p>
                              Drag & Drop or{" "}
                              <span className="normal_text blue"> Browse</span>
                            </p>
                          </div>
                        </>
                      }
                    />
                  </div>
                </Col>

                {paymentMode === "Payables" && (
                  <Col className="paymentinputCol">
                    <Form.Group controlId="payeeAccountSelect">
                      <CustomInputs
                        labelText="Reference No."
                        placeholder="Enter Reference No."
                        name="refrenceNo"
                        value={paymentDetails[0].refrenceNum}
                        onChange={(e) => {
                          const newPaymentDetails = [...paymentDetails];
                          newPaymentDetails[0].refrenceNum = e.target.value;
                          setPaymentDetails(newPaymentDetails);
                          setErrors({ ...errors, refrenceNum: false });
                        }}
                        isError={errors[`refrenceNum-${0}`]}
                        errorMessages={errorMessages[`refrenceNum-${0}`]}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </div>

            {/* <DisplayNamewithLeftBorder HeaderName="PAYMENT DETAILS" className="sub_text ps-1" />

            <div className="payment-details">
              {paymentDetails.map((payment, index) => {
                return (
                  <Row key={index} className="paymentinputrow flex-wrap">
                    <Col className="paymentinputCol">
                      <Form.Group controlId={`dueDateInput-${index}`}>
                        <CustomInputs
                          name="dueDate"
                          labelText="Due Date"
                          type={FORM_CONTROLFIELD_TYPES.DATE}
                          value={payment.dueDate}
                          onChange={(e) => {
                            const newPaymentDetails = [...paymentDetails];
                            newPaymentDetails[index].dueDate = e.target.value;
                            setPaymentDetails(newPaymentDetails);
                            setErrors({
                              ...errors,
                              [`dueDate-${index}`]: false,
                            });
                          }}
                          placeholder="Select Date"
                          isError={errors[`dueDate-${index}`]}
                          errorMessages={errorMessages[`dueDate-${index}`]}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="paymentinputCol">
                      <Form.Group controlId={`amountInput-${index}`}>
                        <CustomInputs
                          name="amount"
                          labelText="Amount ($)"
                          type={FORM_CONTROLFIELD_TYPES.NUMBER}
                          value={payment.amount}
                          onChange={(e) => {
                            const newPaymentDetails = [...paymentDetails];
                            newPaymentDetails[index].amount = e.target.value;
                            setPaymentDetails(newPaymentDetails);
                            setErrors({
                              ...errors,
                              [`amount-${index}`]: false,
                            });
                          }}
                          placeholder="Enter Amount"
                          isError={errors[`amount-${index}`]}
                          errorMessages={errorMessages[`amount-${index}`]}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="paymentinputCol">
                      <Form.Group controlId={`gracePeriods-${index}`}>
                        <CustomInputs
                          name="gracePeriods"
                          labelText="Grace Period"
                          type={FORM_CONTROLFIELD_TYPES.NUMBER}
                          value={payment.gracePeriods}
                          onChange={(e) => {
                            const newPaymentDetails = [...paymentDetails];
                            newPaymentDetails[index].gracePeriods =
                              e.target.value;
                            setPaymentDetails(newPaymentDetails);
                            setErrors({
                              ...errors,
                              [`gracePeriods-${index}`]: false,
                            });
                          }}
                          placeholder="0"
                          isError={errors[`gracePeriods-${index}`]}
                          errorMessages={errorMessages[`gracePeriods-${index}`]}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="paymentinputCol">
                      <Form.Group controlId={`description-${index}`}>
                        <CustomInputs
                          name="description"
                          labelText="Description"
                          value={payment.description}
                          onChange={(e) => {
                            const newPaymentDetails = [...paymentDetails];
                            newPaymentDetails[index].description =
                              e.target.value;
                            setPaymentDetails(newPaymentDetails);
                            setErrors({
                              ...errors,
                              [`description-${index}`]: false,
                            });
                          }}
                          placeholder="Enter Description"
                          isError={errors[`description-${index}`]}
                          errorMessages={errorMessages[`description-${index}`]}
                        />
                      </Form.Group>
                    </Col>

                    {paymentDetails.length > 1 && (
                      <Col className="d-flex justify-content-start align-items-center">
                        <AddTraspButton
                          type="button"
                          width={0}
                          bname={undefined}
                          imageValue={Delete}
                          iconPosition={"right"}
                          IconClassName="delete-background"
                          border={false}
                          onClick={() => deletePaymentRow(index)}
                        />
                      </Col>
                    )}
                  </Row>
                );
              })}
            </div> */}

            <div className="rentLedgerContainer p-3">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-baseline gap-2">
                  <DisplayNamewithLeftBorder
                    HeaderName="PAYMENT DETAILS"
                    className="sub_text black mb-3"
                  />
                </div>
              </div>

              <DynamicAddMoreForm
                headers={headers}
                data={formData}
                setData={setFormData}
                initialOptions={initialOptions}
                handleAddPayment={handleAddPayment}
              />
            </div>

            {/* <AddTraspButton type='button' bname="Add More" onClick={() => addPaymentRow()} /> */}
            <Row className="paymentinputrow p-0">
              <Col>
                <div className="tenantFormFooter">
                  <div className="footerRightButtons d-flex justify-content-between">
                    <AddTraspButton
                      bname={"Save Payment"}
                      border={"1px solid #1A56DB"}
                    />
                    <AddButtonWithArrow
                      type="button"
                      buttonname={"Settle Payment"}
                      onClick={handleCardClick}
                      disabled={undefined}
                      iconDirection="left"
                      Icon={GetImages.WhiteForwardArrow}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddPaymentForm;
