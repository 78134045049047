import React from 'react'

const DisplayNamewithLeftBorder = ({ HeaderName, className = undefined }) => {
  return (
    <>
      <p className={`${className} text-uppercase ps-2 leftborder normal_text fw-normal black`}>
        {HeaderName}
      </p>
    </>
  )
}

export default DisplayNamewithLeftBorder