import React, { useEffect, useState } from "react";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import Inbox from "./components/Inbox";
import { CommunicationService } from "@propertelligent/client-api";
import Compose from "./components/Compose";
import ShowEmail from "./components/ShowEmail";
import AddGmailAccount from "./components/AddGmailAccount";
import { GetImages } from "../../../utils/GetImages";
import CustomDropDownMenu from "../../common/CustomDropDownMenu";
import EmptyData from "../../common/EmptyData";
import Search from "../../common/search";
import AddSign from "../../../assets/add.png";


const Emailview = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [refreshtoken, setRefreshToken] = useState<Boolean>()
  const [emaillist, setEmailList] = useState([])
  const [threadlist, setThreadList] = useState([])
  const [emailId, setEmailId] = useState()
  const [searchEmail, setSearchEmail] = useState("")
  const [emailCat, setEmailCat] = useState("all")
  const [query, setQuery] = useState("inbox")
  const [compose, setcompose] = useState<Boolean>();
  const [maxresult,setMaxResult]=useState(15)



  const checkActive = (index, className) => activeIndex === index ? className : "";

  const onGetEmailList = () => {
    CommunicationService.getApiGmailListEmailThreads(undefined, maxresult, `in:${query} subject:${searchEmail} is:${emailCat}`)
    .then((res) => {
      const allresults: any = res.success

      setThreadList(allresults?.emailThreads)
    }
    ).catch((error) => {})

  }

  useEffect(() => {
    CommunicationService.getApiGmailHasRefreshToken().then((res) => {
      if (res.success == true) {
        setRefreshToken(res?.success)
      } 
    }).catch((error) => {})
    onGetEmailList()
  }, [refreshtoken, emaillist?.length, searchEmail, emailCat, query])


  const data = [
    {
      id: 1,
      img: undefined,
      onClick: () => setEmailCat("all"),
      name: "All",
      linkpath: undefined

    },
    {
      id: 2,
      img: undefined,
      name: "Read",
      onClick: () => setEmailCat("read"),
      linkpath: undefined
    },
    {
      id: 3,
      img: undefined,
      name: "Unread",
      onClick: () => setEmailCat("unread"),
      linkpath: undefined
    },
  ]


  return (
    <>
      <div className="emailMainContainer  ">
        {
          !refreshtoken ?
            <AddGmailAccount
              border={true}
            /> :
            <div className="emailSubContainer ">
              <div className="emailLeftSection ">
              
                <div className="emailHeader d-flex flex-row justify-content-between">
                  <p className="heading black">Emails ({threadlist?.length})</p>

                  <AddTraspButton
                    onClick={() => {
                      setEmailId(null)
                      setcompose(true)
                    }}
                    bname="Compose"
                    iconPosition="right"
                    imageValue={AddSign}
                    border={false}
                  />
                </div>
                <div className="p-tabs">
                  <button
                    className={`emailtabsection normal_text grey_subtext1 ${checkActive(
                      1,
                      "active sub_heading  blue "
                    )}`}
                    onClick={() => {
                      setQuery("inbox")
                      setActiveIndex(1)
                    }}
                  >
                    Inbox
                  </button>
                  <button
                    className={`emailtabsection  normal_text grey_subtext1 ${checkActive(
                      2,
                      "active sub_heading  blue "
                    )}`}
                    onClick={() => {
                      setQuery("sent")
                      setActiveIndex(2)
                    }}
                  >
                    Sent
                  </button>
                  <button
                    className={`emailtabsection  normal_text grey_subtext1 ${checkActive(
                      3,
                      "active sub_heading  blue "
                    )}`}
                    onClick={() => {
                      setQuery("important")
                      setActiveIndex(3)
                    }}
                  >
                    Important
                  </button>
                </div>
                <div className="searchSection ">
                  <Search
                    placeholderName="Search email"
                    searchFilterChange={(e) => setSearchEmail(e.target.value)} 
                    searchValue={searchEmail}
                  />
                  <div className="emailFilter">
                    <CustomDropDownMenu data={data} Element={<img src={GetImages.Filter} className='iconstyle' alt='Filter' />} />

                  </div>
                </div>
                  <div className={`panel w-100 ${checkActive(1, "active")}`} style={{overflowX: 'hidden', overflowY: 'auto'}}>
                    <Inbox threadlist={threadlist} emaillist={emaillist} setEmailId={setEmailId} emailId={emailId} />
                  </div>
                  <div className={`panel w-100 ${checkActive(2, "active")}`} style={{overflowX: 'hidden', overflowY: 'auto'}}>
                    <Inbox threadlist={threadlist} emaillist={emaillist} setEmailId={setEmailId} emailId={emailId} />
                  </div>
                  <div className={`panel w-100 ${checkActive(3, "active")}`} style={{overflowX: 'hidden', overflowY: 'auto'}}>
                    <Inbox threadlist={threadlist} emaillist={emaillist} setEmailId={setEmailId} emailId={emailId} />
                  </div>
              </div>
              <div className="emailRightSection">
                {emailId ?
                  <ShowEmail emailId={emailId} onGetEmailList={onGetEmailList} setEmailId={setEmailId} />
                  : compose == true ? <> 
                    <div className="composeContainer">
                      <Compose getEmailMessages={undefined} replyId={undefined} emailId={undefined} setReplyId={undefined} replytype={undefined} />
                    </div>
                  </> : <EmptyData mainText={undefined} subText="Click on the chat from left to start the conversation" button={undefined} />
                }
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default Emailview;
