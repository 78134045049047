import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import showPasswordIcon from "../../assets/ShowPasswordIcon.svg";
import hidePasswordIcon from "../../assets/hidePassword.svg";
import "./style.css";

const CustomInputs = ({
  labelText = "",
  label_img=undefined,
  value = undefined,
  onChange,
  onBlur = (value: any): any => { },
  onFocus = () => { },
  errorMessages = "",
  id = undefined,
  name = undefined,
  type = "text",
  placeholder = "",
  isPassword = false,
  focus = false,
  disabled = false,
  required = false,
  maxLength = undefined,
  myClassName = "",
  isFormControl = false,
  isError = false,
  min = undefined,
  max = undefined,
  onImageUpload = undefined,
  currencySymbol = undefined,
  isRadio = false,
  checked = false,
  height = undefined,
  multiple = false,
  rows = undefined,
  width = undefined,
  currencyHeight = undefined,
  ref = undefined
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleChange = (event) => {
    if (type === "file" && onImageUpload) {
      onImageUpload(event);
    } else {
      onChange(event);
    }
  };

  const toggleShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  let injectErrorClasses = "";
  if (typeof isError === "boolean") {
    injectErrorClasses = isError
      ? "errorBorder errorOutline"
      : "normalBorder normalOutline";
  }

  return (
    <div
      className="singleInputsWrapper"
      style={{
        display: type === "file" ? "none" : "block",
        width: width,
        marginBottom: isError ? "8px" : "8px",
      }}
    >
      <div>
      {labelText &&  <div className="d-flex justify-content-between"> <p className="normal_text black pb-2">{labelText}</p> {label_img && <img src={label_img} height={16} />} </div> }
      </div>
      {isRadio ? (
        <div className="custom-radio-wrapper">
          <input
            type="radio"
            id={id}
            onChange={onChange}
            name="achAccount"
            className="ms-auto"
            checked={checked}
          />
        </div>
      ) : (
        <div className="passwordInputBox">
          {isFormControl ? (
            <div className="input-group d-flex flex-nowrap">
              {currencySymbol && (
                <div className="input-group-prepend ">
                  <span
                    className={`${injectErrorClasses} ${myClassName} input-group-text removeRightBorder`}
                    style={{ height: currencyHeight }}
                  >
                    {currencySymbol}
                  </span>
                </div>
              )}
              <Form.Control
                id={id}
                className={`${injectErrorClasses} ${myClassName} inputClass normal_text`}
                type={isPassword ? (showPassword ? "text" : "password") : type}
                name={name}
                value={type === "file" ? undefined : value}
                placeholder={placeholder}
                onBlur={onBlur}
                required={required}
                onChange={handleChange}
                disabled={disabled}
                autoFocus={focus}
                onFocus={onFocus}
                maxLength={maxLength}
                multiple={multiple}
                min={min}
                max={max}
                ref={ref}
                aria-rowcount={rows}
                style={{
                  border: isError ? "2px solid #F05252" : "1px solid #E5E7EB",

                  marginBottom: isError ? "0px" : "8px",
                  outlineColor: isError ? "#E02424" : "#1A56DB",
                  height: height,
                }}
              />
            </div>
          ) : (
            <input
              id={id}
              className={`${injectErrorClasses} ${myClassName} inputClass normal_text`}
              type={isPassword ? (showPassword ? "text" : "password") : type}
              name={name}
              value={type === "file" ? undefined : value}
              placeholder={placeholder}
              onBlur={onBlur}
              required={required}
              onChange={handleChange}
              disabled={disabled}
              autoFocus={focus}
              maxLength={maxLength}
              onFocus={onFocus}
              ref={ref}
              style={{
                border: isError ? "2px solid #F05252" : "1px solid #E5E7EB",
                marginBottom: isError ? "0px" : "8px",
                outlineColor: isError ? "#E02424" : "#1A56DB",
                height: height,
              }}
            />
          )}

          {isPassword && value?.length > 0 && (
            <span
              onClick={toggleShowPassword}
              className="showPasswordIcon"
              style={{
                backgroundImage: `url(${showPassword ? hidePasswordIcon : showPasswordIcon
                  })`,
                transform: isError ? "translateY(0%)" : "translateY(-50%)",
                top: isError ? "30%" : "50%",
              }}
            />
          )}
        </div>
      )}

      {isError && (
        <div style={{ marginTop: "8px" }}>
          <p className="errorMessageStyles">{errorMessages}</p>
        </div>
      )}
    </div>
  );
};

export default CustomInputs;
