import React, { useState } from 'react'
import RangeDatePicker from '../common/formControls/RangeDatePicker'
import { StaticRange } from 'react-date-range';
import { Range } from 'react-date-range';
import { subDays, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import AddTraspButton from '../firstTimeUserExperience/commonCom/AddTraspButton';
import filters from "../../assets/filter.svg"
import CustomDropDownMenu from '../common/CustomDropDownMenu';
import { GetImages } from '../../utils/GetImages';
import FilterDropdown from './filterDropdown';

const TransactionView = () => {

    const [relativeDateString, setRelativeDateString] = useState("")
    const [range, setRange] = useState<Range[]>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const [formValidated, setFormValidated] = useState({
        isSelectedProperties: false,
        isSelectDuration: false
    })


    const customRanges: StaticRange[] = [

        {
            label: 'Last 30 Days',
            isSelected: () => false,
            range: () => ({
                startDate: subDays(new Date(), 30),
                endDate: new Date(),
            }),
        },
        {
            label: 'Last 3 Months',
            isSelected: () => false,
            range: () => ({
                startDate: startOfMonth(subMonths(new Date(), 3)),
                endDate: endOfMonth(subMonths(new Date(), 1)),
            }),
        },
        {
            label: 'Last 6 Months',
            isSelected: () => false,
            range: () => ({
                startDate: startOfMonth(subMonths(new Date(), 6)),
                endDate: endOfMonth(subMonths(new Date(), 1)),
            }),
        },
        {
            label: 'Last Year',
            isSelected: () => false,
            range: () => ({
                startDate: new Date(new Date().getFullYear() - 1, 0, 1),
                endDate: new Date(new Date().getFullYear() - 1, 11, 31),
            }),
        },
        {
            label: 'Custom',
            isSelected: () => false,
            range: () => ({
                startDate: range[0].startDate,
                endDate: range[0].endDate,
            }),
        },
    ];

    const data = [
        {
            id: 1,
            img: undefined,
            //   onClick: () => setEmailCat("all"),
            name: "All",
            linkpath: undefined

        },
        {
            id: 2,
            img: undefined,
            name: "Read",
            //   onClick: () => setEmailCat("read"),
            linkpath: undefined
        },
        {
            id: 3,
            img: undefined,
            name: "Unread",
            //   onClick: () => setEmailCat("unread"),
            linkpath: undefined
        },
    ]


    const onChangeHandler = (value) => {
        setRange(value);
        setFormValidated({
            ...formValidated,
            isSelectDuration: false,
        });
    }

    const filters = [
        {
            category: 'View By',
            options: ['Organisation', 'Property', 'Unit']
        },
        {
            category: 'Categories',
            options: ['Rent', 'Tax', 'Insurance', 'Maintenance']
        },
        {
            category: 'Payment Method',
            options: ['Online', 'Cash', 'Cheque', 'ACH']
        },
        {
            category: 'Payee',
            options: ['City tax collector', 'Electricity board', 'Water department']
        },
        {
            category: 'Payer',
            options: ['City tax collector', 'Electricity board', 'Water department']
        }
    ];

    const [selectedFilters, setSelectedFilters] = useState({
        'View By': [],
        'Categories': [],
        'Payment Method': [],
        'Payee': [],
        'Payer': [],
    });

    const handleFilterChange = (category, value) => {
        setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            [category]: prevFilters[category].includes(value)
                ? prevFilters[category].filter((item) => item !== value)
                : [...prevFilters[category], value]
        }));
    };
    return (
        <>
            <div>
                <div className="transactionHeader border-bottom-0 d-flex flex-column">
                    <div className="d-flex justify-content-between">
                        <p className="top_header black">Transactions</p>
                        <div className="monthhead gap-3">
                            <div>
                                <RangeDatePicker
                                    range={range}
                                    customRanges={customRanges}
                                    onChangeHandler={onChangeHandler}
                                    relativeDateString={relativeDateString}
                                    setRelativeDateString={setRelativeDateString}
                                />
                            </div>
                            <div>
                                <CustomDropDownMenu
                                    data={[]}
                                    Element={
                                        <div className="d-flex align-items-center normal_text gap-2 px-2">
                                            <img src={GetImages.Filter} className="iconstyle" alt="Filter" />
                                            <p>Filter</p>
                                        </div>
                                    }
                                    content={
                                        <FilterDropdown
                                            filters={filters}
                                            selectedFilters={selectedFilters}
                                            onFilterChange={handleFilterChange}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionView