import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

const CustomSwitchToggle = ({onClick,checked }) => {
    
  return (
    <Form.Check
        type="switch"
        id="custom-switch"
        checked={ checked}
        onChange={onClick}
        // disabled={disabled}
      />
  )
}

export default CustomSwitchToggle