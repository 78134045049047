import { useState } from 'react'
import GenericSearchAbleDropdown from './GenericSearchAbleDropdown';
import StatusTag from './StatusTag';
import DateHelper from '../../utils/DateHelper';
import SeparaterLine from './SeparaterLine';
import { GetImages } from '../../utils/GetImages';
import { useNavigate } from 'react-router';
import DisplayNamewithLeftBorder from './DisplayNamewithLeftBorder';
import EmptyData from './EmptyData';

const LeaseDetails = ({
    allLeaseData,
    options,
    selectedOption,
    handleLeaseChange,
    userData,
    leaseData,
    downloadDocument,
    isUploading,
    isView = false
}) => {
    const [selectedManageOption, setSelectedManageOption] = useState("")
    const manageOptions = [
        {
            id: "1",
            name: "Renew Lease"
        },
        {
            id: "2",
            name: "Move Out"
        },
        {
            id: "3",
            name: "Move In"
        },
    ]
    const navigate = useNavigate()

    const handleManageChange = (e) => {
        const selectedId = e.target.value;
        setSelectedManageOption(selectedId);
        if (selectedId == 1) {
            navigate("/tenants/renew-lease")
        } else if (selectedId == 2) {
            navigate("/tenants/move-out")
        } else if (selectedId == 3) {
            navigate("/tenants/move-in")
        }
    };

    return (
        <div className="ledgerNotesSection leaseDetailsSection">
            <div className="rentLedgerContainer">
                <div className="d-flex justify-content-between">
                    <div className='d-flex align-items-baseline gap-2'>
                        <DisplayNamewithLeftBorder
                            HeaderName="Lease details"
                            className="sub_text black mb-3"
                        />
                        {userData && userData?.currentLeaseInfo?.formatted?.TenantLeaseTypeId &&
                            <StatusTag
                                color={"red"}
                                statusText={"Lease expiring soon"}
                            />
                        }
                    </div>
                    {!isView &&
                        <div className='d-flex gap-3'>
                            {allLeaseData && allLeaseData?.length > 1 ? (
                                <div style={{ width: "150px" }}>
                                    <GenericSearchAbleDropdown
                                        fieldName={"name"}
                                        initialOptions={options}
                                        name={"selectedOption"}
                                        isFormControl={true}
                                        value={selectedOption}
                                        required={true}
                                        onChange={handleLeaseChange}
                                        placeholder="Select Options"
                                        myClassName="sub_text grey_subtext1"
                                    />
                                </div>
                            ) : null}
                            <div style={{ width: "150px" }}>
                                <GenericSearchAbleDropdown
                                    fieldName={"name"}
                                    initialOptions={manageOptions}
                                    name={"selectedManageOption"}
                                    isFormControl={true}
                                    value={selectedManageOption}
                                    required={true}
                                    onChange={handleManageChange}
                                    placeholder="Manage"
                                    myClassName="sub_text grey_subtext1"
                                    defaultOption={"Manage"}
                                />
                            </div>
                        </div>
                    }
                </div>
                {leaseData && leaseData.length > 0 ?
                    leaseData && leaseData.map((data, index) => (
                        <div key={index} className="mt-2">
                            <div className="d-flex justify-content-between leaseDetailDataContainer">
                                <div className="pt-2">
                                    <p className="sub_text black mb-2">
                                        Lease Type
                                    </p>
                                    <StatusTag
                                        color={"blue"}
                                        statusText={
                                            userData?.currentLeaseInfo?.formatted?.TenantLeaseTypeId === "Fixed-Term Lease"
                                                ? "Fixed"
                                                : userData?.currentLeaseInfo?.formatted?.TenantLeaseTypeId === "Month-to-Month"
                                                    ? "Month to Month"
                                                    : "Fixed"
                                        }
                                    />
                                </div>
                                <SeparaterLine
                                    height={"48px"}
                                    background={"#e5e7eb"}
                                />
                                <div>
                                    <p className="sub_text">Start Date</p>
                                    <span className="normal_text fw-500">
                                        {DateHelper.formatDateonly(data?.leaseStart)}
                                    </span>
                                </div>
                                <SeparaterLine
                                    height={"48px"}
                                    background={"#e5e7eb"}
                                />
                                <div>
                                    <p className="sub_text">End Date</p>
                                    <span className="normal_text fw-500">
                                        {data?.leaseEnd ? DateHelper.formatDateonly(data?.leaseEnd) : "N/A"}
                                    </span>
                                </div>
                                <SeparaterLine
                                    height={"48px"}
                                    background={"#e5e7eb"}
                                />
                                <div>
                                    <p className="sub_text">Security Deposit</p>
                                    <span className="normal_text fw-500">
                                        ${data?.securityDeposit}
                                    </span>
                                </div>
                                <SeparaterLine
                                    height={"48px"}
                                    background={"#e5e7eb"}
                                />
                                <div>
                                    <p className="sub_text">Amount</p>
                                    <span className="normal_text fw-500">
                                        ${data?.rent}
                                    </span>
                                </div>
                            </div>
                            {data?.leaseDocumentId && (
                                <div className="d-flex align-items-center gap-3 mt-3">
                                    <div
                                        onClick={() => {
                                            downloadDocument(
                                                data?.leaseDocumentId,
                                                "Lease Agreement"
                                            );
                                        }}
                                        className="newDocumentItem clickable"
                                    >
                                        {isUploading ? (
                                            <div className="d-flex justify-content-center align-items-center w-100">
                                                <img
                                                    height={40}
                                                    src={GetImages.loadingGif}
                                                />
                                            </div>
                                        ) : (
                                            <div className="d-flex justify-content-between align-items-center gap-2 p-2">
                                                <div className="d-flex align-items-center gap-2">
                                                    <img src={GetImages.pdfImage} />
                                                    <p className="sub_text blue">
                                                        Lease Agreement
                                                    </p>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        downloadDocument(
                                                            data?.leaseDocumentId,
                                                            "Lease Agreement"
                                                        );
                                                    }}
                                                    className="clickable"
                                                >
                                                    <img
                                                        src={GetImages.blueDownloadIcon}
                                                        style={{ height: "20px" }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* {data?.leaseDocumentId && (
                                    <div
                                        onClick={() => {
                                            downloadDocument(
                                                data?.leaseDocumentId,
                                                "Lease Agreement"
                                            );
                                        }}
                                        className="newDocumentItem clickable"
                                    >
                                        {isUploading ? (
                                            <div className="d-flex justify-content-center align-items-center w-100">
                                                <img
                                                    height={40}
                                                    src={GetImages.loadingGif}
                                                />
                                            </div>
                                        ) : (
                                            <div className="d-flex justify-content-between align-items-center p-2">
                                                <div className="d-flex gap-2">
                                                    <img src={GetImages.pdfImage} />
                                                    <p className="sub_text blue">
                                                        Lease Agreement
                                                    </p>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        downloadDocument(
                                                            data?.leaseDocumentId,
                                                            "Lease Agreement"
                                                        );
                                                    }}
                                                    className="clickable"
                                                >
                                                    <img
                                                        src={GetImages.blueDownloadIcon}
                                                        style={{ height: "20px" }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )} */}
                                </div>
                            )}
                        </div>
                    ))
                    : (
                        <>
                            <EmptyData
                                mainText={undefined}
                                subText={"Data Not Available"}
                                button={undefined}
                            />
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default LeaseDetails