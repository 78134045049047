import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Offcanvas, Spinner } from "react-bootstrap";
import { AnalyticsService, DocumentService, EntityType, NoteService, NotesEntityTypesEnum, TenantService, UnitService, PaymentService } from "@propertelligent/client-api";
import EditTenentForm from "./EditTenantForm";
import { UserLoginData } from "../common/charts/Enums";
import NameAvatarComponent from "./NameAvatarComponent";
import { convertStreamAndDownload } from "../../utils/helper";
import { GetImages } from "../../utils/GetImages";
import { usePaginationHook } from "../../hooks/usePaginationHook";
import PaymentForm from "../common/Payment/PaymentForm";
import Transactions from "../properties/components/transactions";
import { URLS } from "../../constants/constants";
import { MenuContext } from "../../utils/context/MenuContext";
import AddViewNotes from "./AddViewNotes";
import "./tenantStyles.css";
import apiHelper from "../../utils/apiHelper";
import CommonConfirmation from "../common/CommonConfirmation";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import CustomDropDownMenu from "../common/CustomDropDownMenu";
import LeaseDetails from "../common/LeaseDetails";
import TenantChatSection from "./TenantChatSection";

const NewSingleTenantView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [noteText, setNoteText] = useState("");
  const [noteError, setNoteError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    tenantData: true,
    notesData: true,
    transactionDataLoading: true,
  }); // Loading state


  const [selectedOption, setSelectedOption] = useState("");
  const [showNotes, setShowNotes] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [FullyQualifiedFilters, setFullyQualifiedFilters] = useState(null);
  const [leaseData, setLeaseData] = useState([]);
  const [allLeaseData, setAllLeaseData] = useState([]);
  const [options, setOptions] = useState([]);

  const actions = [
    {
      id: 1,
      img: undefined,
      onClick: () => handleActionSelect("editTenant"),
      name: "Edit Tenant",
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    },
    {
      id: 2,
      img: undefined,
      name: "Add Payment",
      onClick: () => handleActionSelect("addPayment"),
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    },
    {
      id: 3,
      img: undefined,
      name: "Add/View Notes",
      onClick: () => handleActionSelect("addViewNotes"),
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    },
    {
      id: 4,
      img: undefined,
      name: "Delete",
      onClick: () => handleActionSelect("delete"),
      linkpath: undefined,
      textClass: "sub_text fw-600 blue",
    },
  ];

  useEffect(() => {
    getTenantsData();
  }, []);

  useEffect(() => {
    getLeaseData();
  }, [userData]);

  useEffect(() => {
    getTenantsData();
  }, [showModal]);

  const getTenantsData = async () => {
    const tenantsResponse = await apiHelper(TenantService.getApiTenant1,
      { showNotification: false },
      Number(id)
    );

    if (tenantsResponse?.data?.success?.unitId) {
      const unitResponse = await apiHelper(
        UnitService.getApiUnit1,
        { showNotification: false },
        tenantsResponse?.data?.success?.unitId
      );
      if (unitResponse?.data?.success) {
        setUserData({
          unitData: unitResponse?.data?.success,
          ...tenantsResponse?.data?.success,
        });
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          tenantData: false,
        }));
      }
    }
  };

  const downloadDocument = (id, fileName) => {
    setIsUploading(true);

    apiHelper(
      DocumentService.getApiDocumentBase641,
      { showNotification: false },
      id
    )
      .then((docRes) => {
        //Conversion alogorithm
        convertStreamAndDownload(docRes?.data?.success, fileName);
        setIsUploading(false);
      })
      .catch((docErr) => {
        setIsUploading(false);
      });
  };

  const addNoteHandler = () => {
    if (noteText.trim() == "") {
      setNoteError(true);
    } else {
      const noteObject = {
        noteData: noteText,
        entityId: Number(id),
        entityTypeId: NotesEntityTypesEnum.Tenant,
        modifiedBy: localStorage.getItem(UserLoginData.user_name),
      };

      apiHelper(
        NoteService.postApiNote,
        {
          successMessage: "Note posted Successfully",
          failureMessage: "Unable to post not",
          showNotification: true,
        },
        noteObject
      )
        .then((res) => {
          fetchData1(1);
          setNoteText("");
        })
        .catch((err) => { });
    }
  };

  const {
    rows,
    fetchData,
    loading: transactionDataLoading,
  } = usePaginationHook({
    httpFetchDataFn: PaymentService.getApiPaymentDueAccountTenantlist,
    paginationDataMapper: null,
    parentEntityId: Number(id),
    isDependentEntity: true,
    FullyQualifiedFilters: null,
  });

  let {
    rows: rows1,
    loading: loading1,
    hasMore,
    fetchData: fetchData1,
    currentPage,
  } = infiniteScrollHook({
    httpFetchDataFn: NoteService.getApiNoteTenantlist,
    parentEntityId: Number(id),
    isDependentEntity: true,
    FullyQualifiedFilters: FullyQualifiedFilters,
  });

  useEffect(() => {
    const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
    const tenantId = parseInt(id);

    apiHelper(
      PaymentService.getApiPaymentMethodTenantlist,
      { showNotification: false },
      tenantId
    ).then((res) => {
      if (res?.data?.success) {
        setPaymentMethodsArr(res?.data?.success);
      }
    });

    apiHelper(
      PaymentService.getApiPayeeAccountOrganizationlist,
      { showNotification: false },
      orgId
    )
      .then((ptRes) => {
        if (ptRes?.data?.success) {
          setPayeeAccountsArr(ptRes?.data?.success);
        }
      })
      .catch((ptErr) => { });
  }, [id]);

  const handleSubmitPaymentAccountRecord = ({ values, payvalues }) => {
    const {
      description,
      dueDate,
      amount,
      entityId,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      isRecordPayment,
      paymentTypeId,
    } = values;
    const { fromPaymentMethodId } = payvalues;

    const updatedValues = {
      amount,
      description,
      dueDate: new Date(dueDate).toISOString(),
      entityId,
      entityTypeId: EntityType.Tenant,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      fromPaymentMethodId,
      isRecordPayment,
      paymentTypeId,
    };

    apiHelper(
      PaymentService.postApiPaymentDueAccount,
      {
        successMessage: "Payment Added Successfully",
        failureMessage: "Unable to add payment",
        showNotification: true,
      },
      updatedValues
    )
      .then((res: any) => {
        if (res?.data?.success) {
          setShowModel(false);
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (!transactionDataLoading) {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        transactionDataLoading: false,
      }));
    }
  }, [transactionDataLoading]);

  useEffect(() => {
    if (!loading1) {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        notesData: false,
      }));
    }
  }, [loading1]);

  useEffect(() => {
    if (
      !loadingStates.notesData &&
      !loadingStates.tenantData &&
      !loadingStates.transactionDataLoading
    ) {
      setLoading(false);
    }
  }, [loadingStates]);

  const { isOpen } = useContext(MenuContext);

  const handleActionSelect = (action: string) => {
    switch (action) {
      case "editTenant":
        setShowModal(true);
        break;
      case "addPayment":
        setShowModel(true);
        break;
      case "addViewNotes":
        setShowNotes(true);
        break;
      case "delete":
        setShowConfirmation(true);
        break;
      default:
        break;
    }
  };

  const handleDeleteClose = () => setShowConfirmation(false);

  const handleDelete = () => {
    const tenantId = parseInt(id);
    apiHelper(
      TenantService.deleteApiTenant,
      { showNotification: true },
      tenantId
    )
      .then((delRes) => {
        if (delRes?.data?.success) {
          setShowConfirmation(false);
          setTimeout(() => {
            navigate(URLS.TENANTS);
          }, 2000);
        }
      })
      .catch((delErr) => { });
  };

  useEffect(() => {
    if (searchValue !== "") {
      setFullyQualifiedFilters(`NoteData.Contains("${searchValue}")`);
    } else {
      setFullyQualifiedFilters(null);
    }
  }, [searchValue]);

  const getLeaseData = () => {
    const tenantId = parseInt(id);
    apiHelper(TenantService.getApiTenantLeases, { showNotification: false }, tenantId)
      .then((Res) => {
        if (Res?.data?.success) {
          const leases = Res?.data?.success;
          setAllLeaseData(leases);

          const currentLease = leases.find(
            (lease) => lease.id === userData?.currentLeaseInfo?.id
          );

          const leaseOptions = leases
            .filter((lease) => lease.id !== currentLease?.id)
            .map((lease) => {
              const start = new Date(lease.leaseStart);
              const end = new Date(lease.leaseEnd);

              const formatDate = (date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");
                return `${year}-${month}-${day}`;
              };

              return {
                id: lease.id,
                name: `${formatDate(start)} - ${formatDate(end)}`,
              };
            });

          if (currentLease) {
            leaseOptions.unshift({
              id: currentLease.id,
              name: "Current Lease",
            });
            setLeaseData([currentLease]);
          }

          setOptions(leaseOptions);
        }
      })
      .catch((Err) => { });
  };

  const handleLeaseChange = (e) => {
    const selectedLeaseId = e.target.value;
    setSelectedOption(selectedLeaseId);

    const selectedLease = allLeaseData.find(
      (lease) => lease.id == selectedLeaseId
    );
    if (selectedLease) {
      setLeaseData([selectedLease]);
    }
  };


  return (
    <>
      {loading && ( // Check loading state
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div style={{ overflow: "hidden" }}>
        <div style={{ display: loading == true && "none" }} className="singleTenantView">
          <div className={`sticky-top-property ${isOpen ? "sidebar-open" : "sidebar-closed"}`}>
            <div className="singleTenantTopbar">
              <div
                onClick={() => {
                  navigate("/tenants");
                }}
                className="clickable"
              >
                <p className="normal_text fw-500 black">Tenant</p>
              </div>
              <img src={GetImages.BlackChevronRight} />
              <p className="normal_texzt fw-500 grey">Tenant Detail</p>
            </div>

            <div className="newNameSectionContainer">
              <div className="newSingleTenantSubTopBar d-flex align-items-center justify-content-between">
                <div className="d-flex newLeftSideBox">
                  <NameAvatarComponent
                    firstName={userData?.firstName}
                    lastName={userData?.lastName}
                    heightWidth={48}
                  />
                  <div>
                    <div className="d-flex align-items-center gap-1">
                      <p className="sub_heading black mb-1">
                        {userData &&
                          `${userData.firstName} ${userData.lastName}`}
                      </p>

                      {userData?.expensesDue > 0 && (
                        <div className="dueRedBox">
                          <div>
                            <img src={GetImages.redArrow} />
                          </div>
                          <p className="small_text red">{`$${userData?.expensesDue} due`}</p>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img height={16} width={16} src={GetImages.time}></img>
                      <p className="sub_text grey_subtext1">
                        Resident Since{" "}
                        {userData !== null && userData?.tenancySince !== null
                          ? userData?.tenancySince
                          : "Since 0 months"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="newPersonalInfoBox d-flex justify-content-between flex-wrap gap-2">
                <div>
                  <div className="newPersonalInfoBoxItem mb-2 d-flex justify-content-start">
                    <img
                      width={18}
                      height={18}
                      src={GetImages.homeIconOrange}
                    />
                    {userData && (
                      <p className="normal_text black">
                        {`${userData?.unitData?.property?.address
                          ?.streetAddress ||
                          userData?.unitData?.property?.name
                          } ${userData?.unitData?.property?.address?.city} ${userData?.unitData?.property?.address?.state
                          } ${userData?.unitData?.property?.address?.zip}`}
                      </p>
                    )}
                  </div>

                  <div className="newPersonalInfoBoxItem  d-flex justify-content-start">
                    <img width={18} height={18} src={GetImages.OrangeUnit} />
                    <p className="normal_text black">
                      {userData &&
                        `${userData?.unitNumber} ${userData?.unitData?.formatted?.UnitTypeId} `}
                    </p>
                    <div className="mb-2 d-flex justify-content-start" style={{ gap: "3px" }}>
                      <img
                        width={18}
                        height={18}
                        src={GetImages.mailIconOrange}
                      />
                      <p className="normal_text black">
                        {userData && userData.email}
                      </p>
                    </div>

                    <div className=" d-flex justify-content-start" style={{ gap: "3px" }}>
                      <img
                        width={18}
                        height={18}
                        src={GetImages.phoneIconOrange}
                      />
                      <p className="normal_text black">
                        {userData && userData.phoneNumber}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="actionsDropdownContainer clickable">
                <CustomDropDownMenu
                  Action={true}
                  Element={
                    <img
                      src={GetImages.BlueChevronDown}
                      alt="BlueChevronDown"
                    />
                  }
                  data={actions}
                />
                <Offcanvas
                  className="offcanvas"
                  show={showModel}
                  onHide={() => setShowModel(false)}
                  placement={"end"}
                  scroll={true}
                >
                  <Offcanvas.Header className="canvasHeader" closeButton>
                    <Offcanvas.Title>Add Payment</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <PaymentForm
                      showModel={showModel}
                      entityId={id && Number(id)}
                      payeeAccountsArr={payeeAccountsArr}
                      paymentMethodsArr={paymentMethodsArr}
                      onClose={() => setShowModel(false)}
                      onConfirm={(values: any) =>
                        handleSubmitPaymentAccountRecord(values)
                      }
                      title={"Add Entity Due Payment"}
                      amt={null}
                    />
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
          </div>

          <div className="bottom-tenant-scrolling">
            <div
              style={{
                height: "calc(100vh - 230px)",
                overflow: "auto",
              }}>
              <LeaseDetails
                allLeaseData={allLeaseData}
                options={options}
                selectedOption={selectedOption}
                handleLeaseChange={handleLeaseChange}
                userData={userData}
                leaseData={leaseData}
                downloadDocument={downloadDocument}
                isUploading={isUploading}
              />

              <div className="ledgerNotesSectionContainer d-flex flex-wrap">
                <div className="ledgerNotesSection">
                  <p className="heading black mb-3">Messages</p>
                  <div className="rentLedgerContainer p-3" id="tenant-chat">
                    <TenantChatSection
                      tenantId={id}
                      chatName={userData?.firstName + " " + userData?.lastName}
                    />
                  </div>
                </div>
                <div className="ledgerNotesSection">
                  <p className="heading black mb-3">Last 5 Transactions</p>
                  <div className="rentLedgerContainer p-3">
                    <Transactions
                      rows={rows}
                      sorting="last-five"
                      paymentMethodsArr={paymentMethodsArr}
                      fetchData={fetchData}
                      fetchAndFormatExpensebycategory={undefined}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Offcanvas
              className="editTanentOffcanvas"
              show={showModal}
              onHide={() => setShowModal(false)}
              placement={"end"}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit Tenant</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {userData && (
                  <EditTenentForm
                    id={userData?.id}
                    setShowModal={setShowModal}
                  />
                )}
              </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas
              className="editTanentOffcanvas"
              show={showNotes}
              onHide={() => setShowNotes(false)}
              placement={"end"}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Notes</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <AddViewNotes
                  noteText={noteText}
                  setNoteText={setNoteText}
                  noteError={noteError}
                  setNoteError={setNoteError}
                  notesData={rows1}
                  addNoteHandler={addNoteHandler}
                  fetchData={fetchData1}
                  tenantId={id}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  loading={loading1}
                  hasMore={hasMore}
                  currentPage={currentPage}
                />
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </div>
      {showConfirmation && (
        <CommonConfirmation
          show={true}
          onConfirm={handleDelete}
          onClose={handleDeleteClose}
          heading="Delete Tenant"
          subHeading={`Do you want to delete this tenant ${userData?.firstName} ?`}
          confirmLabel="Delete"
        />
      )}
    </>
  );
};

export default NewSingleTenantView;
