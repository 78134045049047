import React from "react";
import { GetImages } from "../../utils/GetImages";
import "./tenantStyles.css";
import CustomInputs from "../common/Inputs";
import { useError } from "../../utils/context/ErrorContext";

const EditCustomComponent = ({
  label,
  placeholder,
  value,
  name,
  onChangeFunction,
  onBlurFunction,
  errorMessages = "",
  isError = false,
  isPasswordField = false,
  width = "216px",
  borderRadius = "8px",
  type = "text",
  linkWebsite = false,
  disabled = false,
}) => {
  const { setErrors, setErrorMessages } = useError();

  const goToWebsiteHandler = () => {
    window.open("https://" + value, "_blank");
  };

  const handleBlur = (e) => {
    onBlurFunction(e);
  };

  return (
    <div
      style={{
        width: width,
        borderRadius: borderRadius,
      }}
      className="customEditTenantInputContainerStyles"
    >
      <div>
        <p className="normal_text black mb-2">{label}</p>
      </div>
      <div className="editInputContainer">
        <CustomInputs
          name={name}
          value={value}
          onChange={onChangeFunction}
          onBlur={handleBlur}
          placeholder={placeholder}
          isError={isError}
          disabled={disabled}
          isFormControl={true}
          myClassName="normal_text grey_subtext1"
          type={isPasswordField ? "password" : type}
        />
        
        {linkWebsite && value.length > 0 && (
          <span
            onClick={goToWebsiteHandler}
            className="showPasswordIcon"
            style={{
              backgroundImage: `url(${GetImages.goToWebsiteIcon})`,
              top: "50%",
            }}
          />
        )}
      </div>
      {isError && (
        <p className="errorMessage">{errorMessages}</p>
      )}
    </div>
  );
};

export default EditCustomComponent;
