import React from 'react'
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder'
import SeparaterLine from '../../common/SeparaterLine'
import { FloatingLabel, Form } from 'react-bootstrap'

const InstructionsAndInfo = ({ pickupInstruction, setPickupInstruction, additionalInfo, setAdditionalInfo }) => {
    return (
        <div className="ledgerNotesSection leaseDetailsSection">
            <div className="rentLedgerContainer InstructionsAndInfo">
                <div className="d-flex justify-content-between">
                    <div className='d-flex align-items-baseline gap-2'>
                        <DisplayNamewithLeftBorder
                            HeaderName="Key Drop-off Instructions"
                            className="sub_text black mb-2"
                        />
                    </div>
                </div>

                <div className="my-3">
                    <FloatingLabel controlId="floatingTextarea2" label="Add Key pickup Instructions...." className='grey_subtext1 normal_text'>
                        <Form.Control
                            as="textarea"
                            placeholder="Add Key pickup Instructions...."
                            style={{ height: '100px' }}
                            name='pickupInstruction'
                            value={pickupInstruction}
                            onChange={(e) => setPickupInstruction(e.target.value)}
                        />
                    </FloatingLabel>
                </div>

                <div className="d-flex justify-content-between">
                    <div className='d-flex align-items-baseline gap-2'>
                        <DisplayNamewithLeftBorder
                            HeaderName="Additional Info"
                            className="sub_text black mb-2"
                        />
                    </div>
                </div>

                <div className="my-2">
                    <FloatingLabel controlId="floatingTextarea2" label="Add Additional Info...." className='grey_subtext1 normal_text'>
                        <Form.Control
                            as="textarea"
                            placeholder="Add Additional Info...."
                            style={{ height: '100px' }}
                            name='additionalInfo'
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                        />
                    </FloatingLabel>
                </div>
            </div>
        </div>
    )
}

export default InstructionsAndInfo