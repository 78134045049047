import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Form } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { emailRegex, mobileNumberRegex, nameRegex } from "../common/Regex";
import NewSelectAuto from "../tenants/NewSelectAuto";
import EditCustomComponent from "../tenants/EditCustomComponent";
import {
  ContractorService,
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  LookupService,
  LookupTypesEnum,
} from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";
import { FORM_CONTROLFIELD_TYPES, URLS } from "../../constants/constants";
import { CustomDatePicker } from "../common/formControls";
import apiHelper from "../../utils/apiHelper";
import { useError } from "../../utils/context/ErrorContext";
import CustomInputs from "../common/Inputs";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateNumber,
} from "../../utils/validateHelper";

const AddContractor = () => {
  const navigate = useNavigate();
  const [isServiceTypeDisabled, setIsServiceTypeDisabled] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [serviceTypeSelectError, setServiceTypeSelectError] = useState(false);
  const [subServiceTypeError, setSubServiceTypeError] = useState(false);
  const [isSubServiceTypeDisabled, setIsSubServiceTypeDisabled] =
    useState(false);
  const [isInHouseValue, setIsInHouseValue] = useState(true);
  const [selectedSubServiceType, setSelectedSubServiceType] = useState(null);
  const [licenseExpiryDate, setLicenceExpiryDate] = useState("");
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState("");
  const [uploadLicenseFileName, setUploadLicenseFileName] = useState<any>("");
  const [uploadLicenseDocument, setUploadLicenseDocument] = useState<any>(null);
  const [uploadInsuranceFileName, setUploadInsuranceFileName] = useState("");
  const [uploadInsuranceDocument, setUploadInsuranceDocument] = useState(null);
  const [serviceTypeFields, setServiceTypeFields] = useState([]);
  const [subServiceTypeFields, setSubServiceTypeFields] = useState([]);
  const [licenseDocumentFileType, setLicenceDocumentFileType] = useState("pdf");
  const [insuranceDocumentFileType, setInsuranceDocumentFileType] =
    useState("pdf");
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const { errors, errorMessages, setErrors, setErrorMessages, clearErrors } =
    useError();

  useEffect(() => {
    if (selectedServiceType && ![1, 2, 3].includes(selectedServiceType)) {
      setSelectedSubServiceType(null);
      getSubServiceType();
    }
    clearErrors();
  }, [selectedServiceType]);

  const getSubServiceType = () => {
    apiHelper(
      LookupService.getApiLookup1,
      { showNotification: false },
      LookupTypesEnum.ServiceSubTypes,
      selectedServiceType
    )
      .then((res) => {
        setSubServiceTypeFields(res?.data?.success);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getServiceTypeFields();
    clearErrors();
  }, []);

  const getServiceTypeFields = async () => {
    apiHelper(
      LookupService.getApiLookup,
      { showNotification: false },
      LookupTypesEnum.ServiceTypes
    )
      .then((res) => {
        setServiceTypeFields(res?.data?.success);
      })
      .catch((error) => {});
  };

  const handleInsuranceExpiryDateChange = (e) => {
    setInsuranceExpiryDate(e.target.value);
  };

  const handleLicenceExpiryDateChange = (e) => {
    setLicenceExpiryDate(e.target.value);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues((prev) => ({ ...prev, [name]: value }));
    // Clear error on change
    setErrors((prev) => ({ ...prev, [name]: false }));
  };

  const checkEmptyFields = (): boolean => {
    let hasError = false;
    clearErrors();
    const newErrors = { ...errors };
    const newErrorMessages = { ...errorMessages };

    if (values.email.trim() === "") {
      newErrors.email = true;
      newErrorMessages.email = "Email is required";
      hasError = true;
    } else {
      newErrors.email = false;
    }

    if (values.firstName.trim() === "") {
      newErrors.firstName = true;
      newErrorMessages.firstName = "First Name is required";
      hasError = true;
    } else {
      newErrors.firstName = false;
    }

    if (values.lastName.trim() === "") {
      newErrors.lastName = true;
      newErrorMessages.lastName = "Last Name is required";
      hasError = true;
    } else {
      newErrors.lastName = false;
    }

    if (values.phoneNumber.trim() === "") {
      newErrors.phoneNumber = true;
      newErrorMessages.phoneNumber = "Phone Number is required";
      hasError = true;
    } else {
      newErrors.phoneNumber = false;
    }

    if (selectedServiceType === null) {
      setServiceTypeSelectError(true);
      hasError = true;
    } else {
      setServiceTypeSelectError(false);
    }

    if (subServiceTypeFields.length > 0 && selectedSubServiceType === null) {
      setSubServiceTypeError(true);
      hasError = true;
    } else {
      setSubServiceTypeError(false);
    }

    setErrors(newErrors);
    setErrorMessages(newErrorMessages);

    return hasError;
  };

  useEffect(() => {
    return () => {
      clearErrors();
    };
  }, []);

  const formDetails = [
    {
      label: "First Name *",
      placeholder: "Enter First Name",
      value: values.firstName,
      name: "firstName",
      blurFunction: () => validateFirstName(values.firstName, setErrors),
      errorMessages: errorMessages.firstName,
      isError: errors.firstName,
      type: "text",
    },
    {
      label: "Last Name *",
      placeholder: "Enter Last Name",
      value: values.lastName,
      name: "lastName",
      blurFunction: () => validateLastName(values.lastName, setErrors),
      errorMessages: errorMessages.lastName,
      isError: errors.lastName,
      type: "text,",
    },
    {
      label: "Email Address *",
      placeholder: "Enter Email Address",
      value: values.email,
      name: "email",
      blurFunction: () =>
        validateEmail(values.email, setErrors, setErrorMessages),
      errorMessages: errorMessages.email,
      isError: errors.email,
      type: "email",
    },
    {
      label: "Phone Number *",
      placeholder: "Enter Phone No.",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: () => validateNumber(values.phoneNumber, setErrors),
      errorMessages: errorMessages.phoneNumber,
      isError: errors.phoneNumber,
      type: "tel",
    },
  ];

  const handleUploadInsuranceDocumentChange = (selectedFile: File | null) => {
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setInsuranceDocumentFileType("image");
      setUploadInsuranceFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadInsuranceDocument(blob);
    } else {
      setInsuranceDocumentFileType("pdf");

      setUploadInsuranceFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadInsuranceDocument(blob);
    }
  };

  const handleUploadLicenseDocumentChange = (selectedFile: File | null) => {
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setLicenceDocumentFileType("image");
      setUploadLicenseFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadLicenseDocument(blob);
    } else {
      setLicenceDocumentFileType("pdf");

      setUploadLicenseFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadLicenseDocument(blob);
    }
  };

  const submitTimeError = () => {
    return (
      errors.firstName ||
      errors.lastName ||
      errors.email ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === "" ||
      selectedServiceType === null ||
      (subServiceTypeFields.length > 0 && selectedSubServiceType === null)
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();
    checkEmptyFields();
    if (submitTimeError()) {
      
    } else {
      let newValues;
      if (selectedSubServiceType) {
        newValues = {
          ...values,
          email: values.email.toLowerCase(),
          ServiceTypesIds: [selectedServiceType],
          serviceSubTypesIds: [selectedSubServiceType],
          isInHouse: isInHouseValue,
        };
      } else {
        newValues = {
          ...values,
          email: values.email.toLowerCase(),
          ServiceTypesIds: [selectedServiceType],
          isInHouse: isInHouseValue,
        };
      }

      apiHelper(
        ContractorService.postApiContractor,
        {
          successMessage: "Contractor Added Successfully",
          failureMessage: "Unable to Add Contractor",
          showNotification: true,
        },
        newValues
      )
        .then((res) => {
          const contractorId = res?.data?.success.id;

          if (uploadLicenseDocument) {
            if (licenseExpiryDate.trim() != "") {
              var formattedlicenseExpiryDate = new Date(
                licenseExpiryDate
              ).toISOString();
            } else {
              var formattedlicenseExpiryDate = "";
            }

            const uploadLicenseDocData = {
              EntityTypeId: DocumentEntityTypesEnum.Contractor,
              EntityId: contractorId,
              DocumentTypeId: DocumentTypesEnum.License,
              IsMain: true,
              FileName: uploadLicenseFileName,
              Document: uploadLicenseDocument,
              ValidUntil: formattedlicenseExpiryDate,
            };

            apiHelper(
              DocumentService.postApiDocument,
              { showNotification: false },
              uploadLicenseDocData
            )
              .then((res) => {
                setUploadLicenseFileName(null);
                setUploadLicenseDocument(null);
                setLicenceExpiryDate("");
              })
              .catch((error) => {
              });
          }

          if (uploadInsuranceDocument) {
            if (insuranceExpiryDate.trim() != "") {
              var formattedinsuranceExpiryDate = new Date(
                insuranceExpiryDate
              ).toISOString();
            } else {
              var formattedinsuranceExpiryDate = "";
            }

            const uploadInsuranceDocData = {
              EntityTypeId: DocumentEntityTypesEnum.Contractor,
              EntityId: contractorId,
              DocumentTypeId: DocumentTypesEnum.Insurance,
              IsMain: true,
              FileName: uploadInsuranceFileName,
              Document: uploadInsuranceDocument,
              ValidUntil: formattedinsuranceExpiryDate,
            };

            apiHelper(
              DocumentService.postApiDocument,
              { showNotification: false },
              uploadInsuranceDocData
            )
              .then((res) => {
                setUploadInsuranceDocument(null);
                setUploadInsuranceFileName(null);
                setInsuranceExpiryDate("");
              })
              .catch((error) => {
              });
          }

          setValues({
            ...values,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
          });
          setSelectedServiceType(null);
          setSelectedSubServiceType(null);
          setTimeout(() => {
            navigate("/contractors");
          }, 3000);
        })
        .catch((error) => {
        });
    }
  };

  return (
    <>
      <Form id="contractorForm" onSubmit={submitHandler}>
        <div className="singleTenantTopbar">
          <div
            onClick={() => {
              navigate(URLS.CONTRACTORS);
            }}
            className="clickable"
          >
            <p className="normal_text fw-500 black">Contractor</p>
          </div>
          <img src={GetImages.BlackChevronRight} />
          <p className="normal_texzt fw-500 grey">Add contractor</p>
        </div>
        <div className="addContractorFormContainer">
          <div className="newTenantFormTopBar d-flex align-items-center">
            <img
              onClick={() => {
                navigate(URLS.CONTRACTORS);
              }}
              className="clickable"
              height={28}
              width={28}
              src={GetImages.leftChevronBlack}
            />
            <p className="main_heading black"> Add contractor</p>
          </div>

          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText sub_text black">Select contractor type *</p>
          </div>
          <div className="d-flex radioButtonContainer sub_heading">
            <Form.Check
              className="clickable"
              name="group1"
              type="radio"
              id="inHouseRadioButton"
              checked={isInHouseValue}
              onChange={() => setIsInHouseValue(true)}
            />
            <label className="clickable" htmlFor="inHouseRadioButton">
              In House
            </label>
            <Form.Check
              name="group1"
              type="radio"
              checked={!isInHouseValue}
              onChange={() => setIsInHouseValue(false)}
              id="thirdPartyRadioButton"
            />
            <label className="clickable" htmlFor="thirdPartyRadioButton">
              Third-Party
            </label>
          </div>
          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText sub_text black">
              Enter basic details of contractor
            </p>
          </div>
          <div className="d-flex addContractorInputContainer">
            {formDetails.map((item) => {
              return (
                <EditCustomComponent
                  key={item.label}
                  label={item.label}
                  placeholder={item.placeholder}
                  value={item.value}
                  name={item.name}
                  onChangeFunction={handleChange}
                  onBlurFunction={item.blurFunction}
                  errorMessages={item.errorMessages}
                  isError={item.isError}
                  width="290px"
                  type={item.type}
                />
              );
            })}

            <div className="typeContainer">
              <NewSelectAuto
                disabled={isServiceTypeDisabled}
                setDisabled={setIsServiceTypeDisabled}
                selected={selectedServiceType}
                setSelected={setSelectedServiceType}
                data={serviceTypeFields && serviceTypeFields}
                title={"Wait till the data fetches"}
                type="Service Type"
                error={serviceTypeSelectError}
                setError={setServiceTypeSelectError}
                fromContractor={true}
                label={"Service Type *"}
                width="290px"
              />

              {serviceTypeSelectError && (
                <p className="errorMessage"> {errorMessages.serviceType} </p>
              )}
            </div>

            {subServiceTypeFields.length > 0 && (
              <div className="typeContainer">
                <NewSelectAuto
                  disabled={isSubServiceTypeDisabled}
                  setDisabled={setIsSubServiceTypeDisabled}
                  selected={selectedSubServiceType}
                  setSelected={setSelectedSubServiceType}
                  data={subServiceTypeFields && subServiceTypeFields}
                  title={"Please select property first"}
                  type="Service Sub Type"
                  error={subServiceTypeError}
                  setError={setSubServiceTypeError}
                  fromContractor={true}
                  label={"Service Sub Type *"}
                  width="290px"
                />
                {subServiceTypeError && (
                  <p className="errorMessage">
                    {" "}
                    {errorMessages.subServiceType}{" "}
                  </p>
                )}
              </div>
            )}
          </div>

          <div
            className="newTenantDesciptionContainer mb-3"
            style={{ marginTop: "40px" }}
          >
            <p className="newTenantDescriptionText sub_text black">
              License Detail (Optional)
            </p>
          </div>

          <div className="documentExpiryContainer d-flex flex-wrap">
            <div>
              <p className="normal_text black mb-2">Upload License Document</p>
              {uploadLicenseDocument ? (
                <div
                  style={{ width: "296px" }}
                  className="d-flex align-items-center justify-content-between documentContainerEditForm "
                >
                  <div className="d-flex gap-2 align-items-center">
                    <img
                      width={20}
                      height={24}
                      src={
                        insuranceDocumentFileType === "pdf"
                          ? GetImages.pdfImage
                          : GetImages.imageIcon
                      }
                    />
                    <p className="normal_text grey_subtext1">
                      {uploadLicenseFileName}
                    </p>
                  </div>
                  <img
                    onClick={() => {
                      setUploadLicenseFileName("");
                      setUploadLicenseDocument(null);
                    }}
                    width={20}
                    height={20}
                    src={GetImages.deleteIconRed}
                  />
                </div>
              ) : (
                <FileUploader
                  classes="documentUploaderContractor clickable"
                  handleChange={handleUploadLicenseDocumentChange}
                  children={
                    <>
                      <div className="fileUploaderTenantDocument normal_text">
                        <img width={24} height={24} src={GetImages.upload} />
                        <p>
                          Drag & Drop or{" "}
                          <span className="normal_text grey">Browse</span>
                        </p>
                      </div>
                    </>
                  }
                />
              )}
            </div>
            <div className="monthComponentItem">
              <div>
                <Form.Group controlId="formDate">
                  <CustomInputs 
                  myClassName="normal_text grey"
                    labelText="Valid Until"
                    name={"licenseExpiryDate"}
                    type={FORM_CONTROLFIELD_TYPES.DATE}
                    value={licenseExpiryDate}
                    onChange={(e) => handleLicenceExpiryDateChange(e)}
                    placeholder="Select a Date"
                    min={new Date().toISOString().split("T")[0]}
                  />
                </Form.Group>
              </div>
              <div></div>
            </div>
          </div>

          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText sub_text black">
              Insurance Detail (Optional)
            </p>
          </div>

          <div className="documentExpiryContainer d-flex flex-wrap ">
            <div>
              <p className="normal_text black mb-2">Upload Insurance Document</p>

              {uploadInsuranceDocument ? (
                <div
                  style={{ width: "296px" }}
                  className="d-flex align-items-center justify-content-between documentContainerEditForm "
                >
                  <div className="d-flex gap-2 align-items-center">
                    <img
                      width={20}
                      height={24}
                      src={
                        licenseDocumentFileType === "pdf"
                          ? GetImages.pdfImage
                          : GetImages.imageIcon
                      }
                    />
                    <p className="normal_text grey_subtext1">
                      {uploadInsuranceFileName}
                    </p>
                  </div>
                  <img
                    onClick={() => {
                      setUploadInsuranceDocument(null);
                      setUploadInsuranceFileName("");
                    }}
                    width={20}
                    height={20}
                    src={GetImages.deleteIconRed}
                  />
                </div>
              ) : (
                <FileUploader
                  classes="documentUploaderContractor clickable"
                  handleChange={handleUploadInsuranceDocumentChange}
                  children={
                    <>
                      <div className="fileUploaderTenantDocument normal_text">
                        <img width={24} height={24} src={GetImages.upload} />
                        <p>
                          Drag & Drop or{" "}
                          <span className="normal_text grey">Browse</span>
                        </p>
                      </div>
                    </>
                  }
                />
              )}
            </div>
            <div className="monthComponentItem">
              <div>
                <Form.Group controlId="formDate">
                  <CustomInputs
                   myClassName="normal_text grey"
                    labelText="Valid Until"
                    name={"insuranceExpiryDate"}
                    type={FORM_CONTROLFIELD_TYPES.DATE}
                    value={insuranceExpiryDate}
                    onChange={(e) => handleInsuranceExpiryDateChange(e)}
                    placeholder="Select a date"
                    min={new Date().toISOString().split("T")[0]}
                  />
                </Form.Group>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "75px" }} className="newTanentBottomBar">
          <div>
            <Button
              onClick={() => {
                navigate("/contractors");
              }}
              className="cancelButtonNewTanentBottomBar sub_text fw-600"
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button type="submit" className="saveEditTenantButton sub_text fw-600 white">
              Add Contractor <img src={GetImages.WhiteForwardArrow} />
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddContractor;
