import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { GetImages } from '../../utils/GetImages';
import SelectType from './common/SelectType';
import SelectLeaseAndUploader from '../common/SelectLeaseAndUploader';
import AddButtonWithArrow from '../firstTimeUserExperience/commonCom/AddButtonWithArrow';
import { useError } from '../../utils/context/ErrorContext';

const RenewalLease = () => {
    const [selectLease, setSelectLease] = useState<string>("0");
    const [selectedOption, setSelectedOption] = useState("2")

    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [amount, setAmount] = useState<string>("");

    const [leaseDetails, setLeaseDetails] = useState<any>({
        startDate: "",
        endDate: "",
        amount: "",
        // securityDeposit: ""
    })
    const [fileName, setFileName] = useState<any>("");
    const [agreementFileName, setAgreementFileName] = useState<any>("");
    const [uploadDocument, setUploadDocument] = useState<any>();
    const [uploadAgreement, setUploadAgreement] = useState<any>();
    const [uploadAgreementError, setUploadAgreementError] = useState<any>();

    const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } = useError();
    const navigate = useNavigate()

    const checkEmptyFields = (): boolean => {
        let hasError = false;
        clearErrors();
        const newErrors = { ...errors };

        if (leaseDetails.startDate.trim() === "") {
            newErrors.startDate = true;
            hasError = true;
        }

        if (leaseDetails.endDate.trim() === "") {
            newErrors.endDate = true;
            hasError = true;
        }

        if (!leaseDetails.amount) {
            newErrors.amount = true;
            hasError = true;
        }

        if (uploadAgreement == undefined) {
            setUploadAgreementError(true)
            hasError = true;
        }
        setErrors(newErrors);
        return hasError; // Return true if there are errors
    };

    const submitTimeError = () => {
        if (selectLease == "1") {
            return false;
        } else {
            if (selectedOption === "2") {
                return (
                    errors.startDate || errors.endDate || errors.amount || leaseDetails.amount === "" || errors.leaseAgreement
                )
            } else if (selectedOption === "1") {
                return (
                    errors.startDate || errors.amount || leaseDetails.amount === "" || errors.leaseAgreement
                )
            }
        }
    }

    const formSubmitHandler = (event: React.FormEvent) => {
        checkEmptyFields();
        event.preventDefault();
        if (submitTimeError()) { }
        else {
            console.log("checked");
        }
    }

    useEffect(() => {
        return () => {
            clearErrors();
            setUploadAgreementError(false)
        };
    }, [selectedOption]);

    return (
        <>
            {selectLease === "0" ? (
                <SelectType
                    title="Lease"
                    firstOption="eLease"
                    secondOption="Upload Lease Agreement"
                    icon1={GetImages.BlueFile}
                    icon2={GetImages.BlueCloud}
                    selectBasic={selectLease}
                    setSelectBasic={setSelectLease}
                    handleNext={undefined}
                    showNextBtn={false}
                />
            ) : null}

            {selectLease === "2" && (
                <form className="createTenantForm pt-4" onSubmit={formSubmitHandler} id="tenantFormContainer">
                    <div className="newTenantFormTopBar d-flex align-items-center">
                        <img className="clickable me-2"
                            onClick={() => {
                                navigate(-1);
                            }}
                            src={GetImages.leftChevronBlack}
                        />
                        <p className="main_heading black">
                            Add Existing Tenant
                        </p>
                    </div>
                    <div className="newTenantFormContainer">
                        <SelectLeaseAndUploader
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            values={leaseDetails}
                            setValues={setLeaseDetails}
                            uploadDocument={uploadDocument}
                            setUploadDocument={setUploadDocument}
                            fileName={fileName}
                            setFileName={setFileName}
                            uploadAgreement={uploadAgreement}
                            setUploadAgreement={setUploadAgreement}
                            agreementFileName={agreementFileName}
                            setAgreementFileName={setAgreementFileName}
                            isShowLeaseApplicationUploader={false}
                            uploadAgreementError={uploadAgreementError}
                        />
                    </div>
                    <div className="newTanentBottomBar gap-3">
                        <div>
                            <AddButtonWithArrow
                                type={"submit"}
                                buttonname={"Next"}
                                disabled={undefined}
                                iconDirection="left"
                                Icon={GetImages.WhiteForwardArrow}
                            />
                        </div>
                    </div>
                </form>
            )}

            {selectLease === "1" && (
                <div>E-Lease</div>
            )}
        </>
    )
}

export default RenewalLease