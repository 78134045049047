import {
  OnboardingApplicationFeeForm,
  OnboardingEmploymentHistoryForm,
   OnboardingQuestionnaireForm,
  OnboardingReferencesForm,
} from "../typings/onboarding";

export enum OnBoardingRentIncomeEnum {
  "Income" = "Income",
  "Assisted" = "Assisted",
  "Both" = "Both",
}
export type OnBoardingRentIncomeType = keyof typeof OnBoardingRentIncomeEnum;

export enum OnboardingStepsEnums {
  //  Welcome = "Welcome",
  BasicDetails = "BasicDetails",
  RentIncomeCheck = "RentIncomeCheck",
  CreditCheck = "CreditCheck",
  EmploymentHistory = "EmploymentHistory",
  References = "References",
  Questionnaire = "Questionnaire",
  ApplicationFeesClause = "ApplicationFeesClause",
  SignatureConfirmationClause = "SignatureConfirmationClause",
   Thankyou = "Thankyou",
  
}

// Here is where we can change the sequence of Onboarding steps
export const DefaultOnboardingStepsSequence = [
  //  OnboardingStepsEnums.Welcome,
  OnboardingStepsEnums.BasicDetails,
  OnboardingStepsEnums.RentIncomeCheck,
  OnboardingStepsEnums.CreditCheck,
  OnboardingStepsEnums.EmploymentHistory,
  OnboardingStepsEnums.References,
  OnboardingStepsEnums.Questionnaire,
  OnboardingStepsEnums.ApplicationFeesClause,
  OnboardingStepsEnums.Thankyou,
];

 export const DefaultEmploymentHistory: OnboardingEmploymentHistoryForm = {
   employerName: "",
   startDate: "",
   endDate: "",
   firstName: "",
   lastName: "",
   email: "",
   phoneNumber: ""
 };
;
export const DefaultReferences: OnboardingReferencesForm = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  referenceType: "Employer",
};

export const DefaultQuestionnaire: OnboardingQuestionnaireForm = [];

export const DefaultFeeFormData: OnboardingApplicationFeeForm = {
  feeSubmitOptions: "Card",
  nameOnCard: null,
  accountNumber: null,
  routingNumber: null,
  cardNumber: null,
  billingZipCode: null,
  expiryDate: null,
  cvv: null,
  saveDetails: null,
  applicationFee:null
};
