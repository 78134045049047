import React, { ChangeEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { emailRegex, mobileNumberRegex, nameRegex } from "../common/Regex";
import EditCustomComponent from "../tenants/EditCustomComponent";
import NewSelectAuto from "../tenants/NewSelectAuto";

import {
  ContractorService,
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  LookupService,
  LookupTypesEnum,
} from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";
import { CustomDatePicker } from "../common/formControls";
import { FORM_CONTROLFIELD_TYPES } from "../../constants/constants";
import apiHelper from "../../utils/apiHelper";
import { useError } from "../../utils/context/ErrorContext";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateNumber,
} from "../../utils/validateHelper";
import CustomInputs from "../common/Inputs";

const EditContractorForm = ({ id, setShowModal }) => {
  const [isServiceTypeDisabled, setIsServiceTypeDisabled] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [isInHouseValue, setIsInHouseValue] = useState(true);
  const [subServiceTypeData, setSubServiceTypeData] = useState([]);
  const [serviceTypeSelectError, setServiceTypeSelectError] = useState(false);
  const [subServiceTypeError, setSubServiceTypeError] = useState(false);
  const [isSubServiceTypeDisabled, setIsSubServiceTypeDisabled] =
    useState(false);
  const [selectedSubServiceType, setSelectedSubServiceType] = useState(null);
  const [licenseExpiryDate, setLicenceExpiryDate] = useState("");
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState("");
  const [uploadLicenseFileName, setUploadLicenseFileName] =
    useState<any>("License Document");
  const [uploadLicenseDocument, setUploadLicenseDocument] = useState<any>(null);
  const [uploadInsuranceFileName, setUploadInsuranceFileName] =
    useState("Insurance Document");
  const [uploadInsuranceDocument, setUploadInsuranceDocument] = useState(null);
  const [insuranceDocumentId, setInsuranceDocumentId] = useState(null);
  const [licenseDocumentId, setLicenseDocumentId] = useState(null);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const { errors, setErrors, errorMessages, setErrorMessages } = useError();

  const [serviceTypeFields, setServiceTypeFields] = useState([]);
  const [subServiceTypeFields, setSubServiceTypeFields] = useState([]);

  useEffect(() => {
    getContractorData();
  }, []);

  useEffect(() => {
    if (selectedServiceType) {
      getSubServiceType(selectedServiceType);
    }
  }, [selectedServiceType]);

  const getSubServiceType = (x) => {
    apiHelper(
      LookupService.getApiLookup1,
      { showNotification: false },
      LookupTypesEnum.ServiceSubTypes,
      x
    )
      .then((res) => setSubServiceTypeFields(res?.data?.success))
      .catch((error) => {});
  };

  const getServiceTypeFields = async () => {
    apiHelper(
      LookupService.getApiLookup,
      { showNotification: false },
      LookupTypesEnum.ServiceTypes
    )
      .then((res) => {
        setServiceTypeFields(res?.data?.success);
      })
      .catch((error) => {});
  };

  const getContractorData = async () => {
    const contractorResponse = await apiHelper(
      ContractorService.getApiContractor1,
      { showNotification: false },
      Number(id)
    );

    if (contractorResponse?.data?.success) {
      setValues({
        ...values,
        firstName: contractorResponse?.data?.success?.firstName,
        lastName: contractorResponse?.data?.success?.lastName,
        email: contractorResponse?.data?.success?.email,
        phoneNumber: contractorResponse?.data?.success?.phoneNumber,
      });

      setIsInHouseValue(contractorResponse?.data?.success?.isInHouse);

      getServiceTypeFields();

      if (contractorResponse?.data?.success?.serviceTypesIds.length > 0) {
        setSelectedServiceType(
          contractorResponse?.data?.success?.serviceTypesIds[0]
        );
        getSubServiceType(
          contractorResponse?.data?.success?.serviceTypesIds[0]
        );
      }

      if (contractorResponse?.data?.success?.serviceSubTypesIds.length > 0) {
        setSelectedSubServiceType(
          contractorResponse?.data?.success?.serviceSubTypesIds[0]
        );
      }

      if (contractorResponse?.data?.success?.currentInsuranceDocumentId) {
        setInsuranceDocumentId(
          contractorResponse?.data?.success?.currentInsuranceDocumentId
        );
        await apiHelper(
          DocumentService.getApiDocumentDetails,
          { showNotification: false },
          contractorResponse?.data?.success?.currentInsuranceDocumentId
        )
          // await DocumentService.getApiDocumentDetails(
          //   contractorResponse?.data?.success?.currentInsuranceDocumentId
          // );
          .then((response) => {
            const validUntil = response?.data?.success?.validUntil;

            if (validUntil === null) {
              setInsuranceExpiryDate(validUntil);
            } else if (validUntil) {
              setInsuranceExpiryDate(validUntil.substring(0, 10));
            }
          });

        setUploadInsuranceDocument(
          contractorResponse?.data?.success?.currentInsuranceDocumentId
        );
      }

      if (contractorResponse?.data?.success?.currentLicenseDocumentId) {
        setLicenseDocumentId(
          contractorResponse?.data?.success?.currentLicenseDocumentId
        );

        apiHelper(
          DocumentService.getApiDocumentDetails,
          { showNotification: false },
          contractorResponse?.data?.success?.currentLicenseDocumentId
        )
          .then((response) => {
            const validUntil = response?.data?.success?.validUntil;

            if (validUntil === null) {
              setLicenceExpiryDate(validUntil);
            } else if (validUntil) {
              setLicenceExpiryDate(validUntil.substring(0, 10));
            }
          })
          .catch((error) => {
            console.error("Error fetching document details:", error);
          });

        // const licenseDocumentResponse =
        //   await DocumentService.getApiDocumentDetails(
        //     contractorResponse?.data?.success?.currentLicenseDocumentId
        //   );

        // setLicenceExpiryDate(
        //   licenseDocumentRespons.success.validUntil.substring(0, 10)
        // );

        setUploadLicenseDocument(
          contractorResponse?.data?.success?.currentLicenseDocumentId
        );
      }
    }
  };

  const formDetails = [
    {
      label: "First Name *",
      placeholder: "Enter First Name",
      value: values.firstName,
      name: "firstName",
      blurFunction: () => validateFirstName(values.firstName, setErrors),
      errorMessages: errorMessages.firstName,
      isError: errors.firstName,
      type: "text",
    },
    {
      label: "Last Name *",
      placeholder: "Enter Last Name",
      value: values.lastName,
      name: "lastName",
      blurFunction: () => validateLastName(values.lastName, setErrors),
      errorMessages: errorMessages.lastName,
      isError: errors.lastName,
      type: "text",
    },
    {
      label: "Email Address *",
      placeholder: "Enter Email Address",
      value: values.email,
      name: "email",
      blurFunction: () =>
        validateEmail(values.email, setErrors, setErrorMessages),
      errorMessages: errorMessages.email,
      isError: errors.email,
      type: "email",
    },
    {
      label: "Phone Number *",
      placeholder: "Enter Phone No.",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: () => validateNumber(values.phoneNumber, setErrors),
      errorMessages: errorMessages.phoneNumber,
      isError: errors.phoneNumber,
      type: "tel",
    },
  ];

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "email":
        setErrors({ ...errors, email: false });
        setErrorMessages({ ...errorMessages, email: "Email is required" });
        break;
      case "firstName":
        setErrors({ ...errors, firstName: false });
        setErrorMessages({
          ...errorMessages,
          firstName: "First Name is required",
        });
        break;
      case "lastName":
        setErrors({ ...errors, lastName: false });
        setErrorMessages({
          ...errorMessages,
          lastName: "Last Name is required",
        });
        break;
      case "phoneNumber":
        setErrors({ ...errors, phoneNumber: false });
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "Phone Number is required",
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const checkEmptyFields = (): boolean => {
    let hasError = false;
    const newErrors = { ...errors };
    const newErrorMessages = { ...errorMessages };

    if (values.email.trim() === "") {
      newErrors.email = true;
      newErrorMessages.email = "Email is required";
      hasError = true;
    }

    if (values.firstName.trim() === "") {
      newErrors.firstName = true;
      newErrorMessages.firstName = "First Name is required";
      hasError = true;
    }

    if (values.lastName.trim() === "") {
      newErrors.lastName = true;
      newErrorMessages.lastName = "Last Name is required";
      hasError = true;
    }

    if (values.phoneNumber.trim() === "") {
      newErrors.phoneNumber = true;
      newErrorMessages.phoneNumber = "Phone Number is required";
      hasError = true;
    }

    if (selectedServiceType === null) {
      setServiceTypeSelectError(true);
      hasError = true;
    }

    if (subServiceTypeFields.length > 0 && selectedSubServiceType === null) {
      setSubServiceTypeError(true);
      hasError = true;
    }

    setErrors(newErrors);
    setErrorMessages(newErrorMessages);

    return hasError;
  };

  const handleInsuranceExpiryDateChange = (e) => {
    setInsuranceExpiryDate(e.target.value);
  };

  const handleLicenceExpiryDateChange = (e) => {
    setLicenceExpiryDate(e.target.value);
  };

  const handleUploadInsuranceDocumentChange = (selectedFile: File | null) => {
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setUploadInsuranceFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadInsuranceDocument(blob);
    } else {
      setUploadInsuranceFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadInsuranceDocument(blob);
    }
  };

  const handleUploadLicenseDocumentChange = (selectedFile: File | null) => {
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setUploadLicenseFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadLicenseDocument(blob);
    } else {
      setUploadLicenseFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadLicenseDocument(blob);
    }
  };

  const submitTimeError = () => {
    return (
      errors.firstName ||
      errors.lastName ||
      errors.email ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === "" ||
      selectedServiceType === null ||
      (subServiceTypeFields.length > 0 && selectedSubServiceType === null)
    );
  };

  const editSubmitHandler = (e) => {
    e.preventDefault();
    checkEmptyFields();

    if (submitTimeError()) {
    } else {
      const newValues = {
        ...values,
        email: values.email.toLowerCase(),
        ServiceTypesIds: [selectedServiceType],
        serviceSubTypesIds: [selectedSubServiceType],
        isInHouse: isInHouseValue,
        id: id,
      };

      apiHelper(
        ContractorService.putApiContractor,
        {
          successMessage: "Contractor Edited Successfully",
          failureMessage: "Unable to Edit Contractor",
          showNotification: true,
        },
        newValues
      )
        .then(async (res) => {
          const contractorId = res?.data?.success.id;

          if (
            uploadLicenseDocument &&
            typeof uploadLicenseDocument != "number"
          ) {
            if (licenseExpiryDate.trim() != "") {
              var formattedlicenseExpiryDate = new Date(
                licenseExpiryDate
              ).toISOString();
            } else {
              var formattedlicenseExpiryDate = "";
            }

            const uploadLicenseDocData = {
              EntityTypeId: DocumentEntityTypesEnum.Contractor,
              EntityId: contractorId,
              DocumentTypeId: DocumentTypesEnum.License,
              IsMain: true,
              FileName: uploadLicenseFileName,
              Document: uploadLicenseDocument,
              ValidUntil: formattedlicenseExpiryDate,
            };

            apiHelper(
              DocumentService.postApiDocument,
              { showNotification: false },
              uploadLicenseDocData
            )
              .then((res) => {
                setUploadLicenseFileName(null);
                setUploadLicenseDocument(null);
                setLicenceExpiryDate("");
              })
              .catch((error) => {});
          } else if (licenseDocumentId && uploadLicenseDocument === null) {
            await apiHelper(
              DocumentService.deleteApiDocument,
              { showNotification: false },
              licenseDocumentId
            );
          }

          if (
            uploadLicenseDocument &&
            typeof uploadLicenseDocument != "number"
          ) {
            if (insuranceExpiryDate.trim() != "") {
              var formattedinsuranceExpiryDate = new Date(
                insuranceExpiryDate
              ).toISOString();
            } else {
              var formattedinsuranceExpiryDate = "";
            }

            const uploadInsuranceDocData = {
              EntityTypeId: DocumentEntityTypesEnum.Contractor,
              EntityId: contractorId,
              DocumentTypeId: DocumentTypesEnum.Insurance,
              IsMain: true,
              FileName: uploadInsuranceFileName,
              Document: uploadInsuranceDocument,
              ValidUntil: formattedinsuranceExpiryDate,
            };

            apiHelper(
              DocumentService.postApiDocument,
              { showNotification: false },
              uploadInsuranceDocData
            )
              .then((res) => {
                setUploadInsuranceDocument(null);
                setUploadInsuranceFileName(null);
                setInsuranceExpiryDate("");
              })
              .catch((error) => {
                {
                }
              });
          } else if (insuranceDocumentId && uploadInsuranceDocument === null) {
            await apiHelper(
              DocumentService.deleteApiDocument,
              { showNotification: false },
              insuranceDocumentId
            );
          }
          setValues({
            ...values,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
          });
          setSelectedServiceType(null);
          setSelectedSubServiceType(null);
          setShowModal(false);
        })
        .catch((error) => {});
    }
  };

  return (
    <Form onSubmit={editSubmitHandler}>
      <div className="editContractorFormContainer">
        <div className="newTenantDesciptionContainer1">
          <p className="newTenantDescriptionText sub_text black">Select contractor type *</p>
        </div>
        <div className="d-flex radioButtonContainer sub_heading">
          <Form.Check
            className="clickable"
            name="group1"
            type="radio"
            checked={isInHouseValue}
            onChange={() => setIsInHouseValue(true)}
            id="inHouseRadio"
          />
          <label className="clickable" htmlFor="inHouseRadio">
            In House
          </label>

          <Form.Check
            className="clickable"
            name="group1"
            type="radio"
            checked={!isInHouseValue}
            onChange={() => setIsInHouseValue(false)}
            id="Third-Party"
          />
          <label className="clickable" htmlFor="Third-Party">
            Third-Party
          </label>
        </div>
        <div className="d-flex addContractorInputContainer">
          {formDetails.map((item) => {
            return (
              <EditCustomComponent
                key={item.label}
                label={item.label}
                placeholder={item.placeholder}
                value={item.value}
                name={item.name}
                onChangeFunction={handleChange}
                onBlurFunction={item.blurFunction}
                errorMessages={item.errorMessages}
                isError={item.isError}
                width="290px"
                type={item.type}
              />
            );
          })}

          <div className="typeContainer">
            <NewSelectAuto
              disabled={isServiceTypeDisabled}
              setDisabled={setIsServiceTypeDisabled}
              selected={selectedServiceType}
              setSelected={setSelectedServiceType}
              data={serviceTypeFields && serviceTypeFields}
              title={"Wait till the data fetches"}
              type="Service Type"
              setResetDependencies={setSubServiceTypeData}
              error={serviceTypeSelectError}
              setError={setServiceTypeSelectError}
              fromContractor={true}
              label={"Service Type *"}
              width={"203px"}
            />
            {serviceTypeSelectError && (
              <p className="errorMessage"> {errorMessages.serviceType} </p>
            )}
          </div>

          <div className="typeContainer">
            <NewSelectAuto
              disabled={isSubServiceTypeDisabled}
              setDisabled={setIsSubServiceTypeDisabled}
              selected={selectedSubServiceType}
              setSelected={setSelectedSubServiceType}
              data={subServiceTypeFields && subServiceTypeFields}
              title={"Please select property first"}
              type="Service Sub Type"
              error={subServiceTypeError}
              setError={setSubServiceTypeError}
              fromContractor={true}
              label={"Service Sub Type *"}
              width={"203px"}
            />
            {subServiceTypeError && (
              <p className="errorMessage"> {errorMessages.subServiceType} </p>
            )}
          </div>
        </div>
        <div className="newTenantDesciptionContainer mb-3">
          <p className="newTenantDescriptionText sub_text black">License Detail (Optional)</p>
        </div>
        <div className="editContractorMonthComponentItem">
          {/* <p className="normal_text black mb-3">Select expiry date</p> */}
          <div>
            <Form.Group controlId="formDate">
              <CustomInputs
              labelText="Select Expiry Date"
                name={"licenseExpiryDate"}
                type={FORM_CONTROLFIELD_TYPES.DATE}
                value={licenseExpiryDate}
                onChange={(e) => handleLicenceExpiryDateChange(e)}
                placeholder="Select a Date"
                myClassName="normal_text grey"
              />
            </Form.Group>
          </div>
          <div></div>
        </div>
        <div className="labelContainer">
          <p className="normal_text black mb-2">Upload License Document</p>
        </div>
        {uploadLicenseDocument ? (
          <div
            style={{ width: "100%" }}
            className="d-flex align-items-center justify-content-between documentContainerEditForm"
          >
            <div className="d-flex gap-2 align-items-center">
              <img width={20} height={24} src={GetImages.pdfImage} />
              <p className="normal_text grey_subtext1">{uploadLicenseFileName}</p>
            </div>
            <img
              onClick={() => {
                setUploadLicenseDocument(null);
                setUploadLicenseFileName(null);
              }}
              className="clickable"
              width={20}
              height={20}
              src={GetImages.deleteIconRed}
            />
          </div>
        ) : (
          <FileUploader
            classes="documentUploaderContractor w-100"
            handleChange={handleUploadLicenseDocumentChange}
            children={
              <>
                <div className="fileUploaderTenantDocument normal_text w-100">
                  <img width={24} height={24} src={GetImages.upload} />
                  <p>
                    Drag & Drop or{" "}
                    <span className="normal_text grey">Browse</span>
                  </p>
                </div>
              </>
            }
          />
        )}
      </div>
      <div className="editFormFooterContractor">
        <p onClick={() => setShowModal(false)} className="normal_text fw-600 blue clickable">
          Cancel
        </p>
        <button type="submit" className="saveEditTenantButton sub_text fw-600 white">
          Save Changes <img src={GetImages.WhiteForwardArrow} />
        </button>
      </div>
    </Form>
  );
};

export default EditContractorForm;
