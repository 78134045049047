import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";

const FeeTemplete = ({ postData, 
  setPostData, }) => {
  


  return (
    <>
      <Card className="edit-card feeCard">
        <Card.Header>Application Fees</Card.Header>
        <Card.Body>
          <Card.Title>Fee Application</Card.Title>
          <Form.Control
            as="textarea"
            rows={3}
            value={postData?.templateSections?.ApplicationFeesClause?.clause}
           
            onChange={(e) => {
              setPostData({
                ...postData,
                templateSections:{...postData.templateSections,ApplicationFeesClause:{...postData?.templateSections?.ApplicationFeesClause,clause:e.target.value}}
                
              });
            }}
            placeholder="This fee is non-refundable and will be used to process your application for background checks, income verification, and credit report"
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default FeeTemplete;
