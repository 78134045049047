import React, { useState } from "react";
import "./tenantonboard.css";
import {
  OnboardingCreditCheckForm,
  OnboardingForms,
} from "../../../typings/onboarding";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import CustomInputs from "../../common/Inputs";
import RadioButton from "../../common/RadioButton";
const StepThree: React.FC<any> = ({
  formData,
  errors,
  handleRemoveError,
  setFormData,
}: {
  formData: OnboardingCreditCheckForm;
  errors: { [key: string]: any };
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  handleRemoveError: (
    field: string,
    form: keyof typeof OnboardingStepsEnums,
    error?: { key: string; subKey?: string }
  ) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      CreditCheck: {
        ...prev.CreditCheck,
        [name]: value,
      },
    }));
    handleRemoveError(name, OnboardingStepsEnums.CreditCheck, {
      key: name,
    });
  };

  const Radiodata = [
    {
      id: 1,
      label: "Yes",
      value: "yes",
      checked: formData?.creditPermission == "yes",
    },
    {
      id: 2,
      label: "No",
      value: "no",
      checked: formData?.creditPermission == "no",
    },
  ]

  return (
    <div className="onboarding-stepper">
    <div className="onboarding-steppercard">
      <p className="main_heading py-1">Credit Check</p>
      <div className="onboardingstepper-form">
        <CustomInputs
          type="text"
          labelText="Social Security Number"
          placeholder="xxx-xxx-xxxx"
          name="socialSecurityNumber"
          value={formData?.socialSecurityNumber}
          onChange={handleChange}
          isError={errors?.socialSecurityNumber}
          errorMessages={errors?.socialSecurityNumber}
        />
        <p className="normal-text">
          Do you give us permission to run Credit Report on your
          behalf?
        </p>
        <div  className="flexRow gap-4 py-2 ">
        {Radiodata?.map((item) => (
          <div key={item?.id}>
              <RadioButton

                id={`${item.id}`}
                label={item.label}
                value={item?.value}
                name={"creditPermission"}
                checked={item.checked}
                onChange={handleChange}
              />
              </div>
            ))}
            </div>
      </div>
    </div>
  </div>

  );
};

export default StepThree;
