import React, { useEffect, useState } from "react";
import Stepper from "./Stepper";
import { renderSteps } from "./RenderSteps";
import Footer from "./Footer";
import { OnboardingFormErrorsType, OnboardingForms, OnBoardingReferenceRadioType, OnboardingRentIncomeCheckForm } from "../../typings/onboarding";
import { DefaultFeeFormData, DefaultOnboardingStepsSequence, OnBoardingRentIncomeEnum, OnboardingStepsEnums, } from "../../constants/onboarding";
import { DocumentService, LeaseApplicationService, LoginService, OnboardingService, QuestionVM, TemplateService, } from "@propertelligent/client-api";
import { loginResponseHandler } from "../../services/login.service";
import { OnboardingStepsSchemas } from "./Validations/onboardingTenantValidation";
import "./tenantOnboard.css"
import OrganizationNameHeader from "../common/OrganizationNameHeader/OrganizationNameHeader";
import CardView from "../units/listView/cardView";
import apiHelper from "../../utils/apiHelper";
import CommonConfirmation from "../common/CommonConfirmation";
import { useNavigate } from "react-router";
import { URLS } from "../../constants/constants";
import { GetImages } from "../../utils/GetImages";
import BoardingReview from "./RenderSteps/BoardingReview";
import { useError } from "../../utils/context/ErrorContext";
import CustomDropDownMenu from "../common/CustomDropDownMenu";

const TenantOnboard: React.FC<any> = () => {
  const navigate = useNavigate()
  const [leaseApplication, setLeaseApplication] = useState<any>()
  const params = new URLSearchParams(location.search);
  const leaseApplicationToken = params.get("la");
  const tenantData = localStorage.getItem("tenantInfo");
  const tenantObject = JSON.parse(tenantData);
  const [template, setTemplate] = useState<any>()
  const [formData, setFormData] = useState<OnboardingForms>({
    BasicDetails: { firstName: "", lastName: "", email: "", phoneNumber: "", password: "", confirmPassword: "" },
    CreditCheck: { creditPermission: "yes", socialSecurityNumber: "" },
    RentIncomeCheck: {} as OnboardingRentIncomeCheckForm,
    EmploymentHistory: [],
    References: [],
    Questionnaire: [],
    ApplicationFeesClause: DefaultFeeFormData,
    //  SignatureConfirmationClause:{}
  });

  const [steps, setSteps] = useState(DefaultOnboardingStepsSequence);
  const [preStepIndex, setpreStepIndex] = useState(0);
  const [currentStepIndex, setCurrentStepIndex] = useState(tenantData ? 1 : 0);
  const [loading, setLoading] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [isReLogin, setIsReLogin] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [stepFivePart2, setStepFivePart2] = useState(false);
  const [leaseApplicationid, setLeaseApplicationId] = useState(null);
  const { errors, setErrors } = useError()
  const [docsArr, setDocsArr] = useState([]);
  const [OrgDetail, setOrgDetail] = useState<any>()
  const [codeExpired, setCodeExpired] = useState<Boolean>(false)

  const fetchData = () => {
    if (tenantData) {
      const { leaseApplicationId } = tenantObject;
      if (leaseApplicationId) {
        setLeaseApplicationId(leaseApplicationId);
        apiHelper(LeaseApplicationService.getApiLeaseApplicationOrganizationHeaderInfo, { showNotification: false }, leaseApplicationId).then((res) => {
          setOrgDetail(res?.data?.success)
        }).catch((error) => { })
        apiHelper(LeaseApplicationService.getApiLeaseApplication1, { showNotification: false }, leaseApplicationId)
          .then((response: any) => {
            setLeaseApplication(response?.data?.success)
            const getValue = response?.data?.success?.dataSections
            setFormData({
              ...formData,
              BasicDetails: { firstName: response?.data?.success?.tenant?.firstName, lastName: response?.data?.success?.tenant?.lastName, email: response?.data?.success?.tenant?.email, phoneNumber: response?.data?.success?.tenant?.phoneNumber, password: response?.data?.success?.tenant?.password, confirmPassword: response?.data?.success?.tenant?.password },
              ApplicationFeesClause: JSON.parse(getValue?.ApplicationFeesClause ? getValue?.ApplicationFeesClause : null),
              CreditCheck: JSON.parse(getValue?.CreditCheck ? getValue?.CreditCheck : null),
              EmploymentHistory: JSON.parse(getValue?.EmploymentHistory ? getValue?.EmploymentHistory : null),
              Questionnaire: JSON.parse(getValue?.Questionnaire ? getValue?.Questionnaire : null),
              References: JSON.parse(getValue?.References ? getValue?.References : null),
              RentIncomeCheck: JSON.parse(getValue?.RentIncomeCheck ? getValue?.RentIncomeCheck : null),
            });
            apiHelper(TemplateService.getApiTemplate, { showNotification: false }, response?.data?.success?.templateId)
              .then((templete) => {
                setTemplate(templete?.data?.success)
                if (!templete?.data?.success?.templateSections) {
                  setSteps(DefaultOnboardingStepsSequence.filter((item) => item !== OnboardingStepsEnums.BasicDetails));
                } else {
                  const stepsReceived = Array.from(new Set([
                    OnboardingStepsEnums.BasicDetails,
                    ...Object.keys(templete?.data?.success?.templateSections),
                    OnboardingStepsEnums.Questionnaire,
                    OnboardingStepsEnums.Thankyou,
                  ]));
                  if (!templete?.data?.success?.templateSections?.Questionnaire) {
                    setStepFivePart2(stepsReceived[0] == OnboardingStepsEnums.Questionnaire);
                  }

                  setSteps(steps.filter((itm) => stepsReceived.includes(itm)));
                  setIsReLogin(true);
                }
              }).catch((error) => console.log("error", JSON.stringify(error)))
          }).catch((tenantErr: any) => { console.log("tenantErr", JSON.stringify(tenantErr)) });

      }
    } else {
      if (leaseApplicationToken) {
        apiHelper(OnboardingService.getApiOnboardingTenantOrganizationHeaderInfo, { showNotification: false },
          leaseApplicationToken).then((res) => {
            setOrgDetail(res?.data?.success)
            if (res?.data?.success == null) {
              setCodeExpired(true)
            }
          }).catch((error) => { console.log("error?", JSON.stringify(error)) })
      }
      //  setCodeExpired(true)
    }
  }
  useEffect(() => {
    if (tenantData && !leaseApplicationToken) {
      setCodeExpired(false)
    }
    fetchData()
  }, [leaseApplicationid, currentStepIndex, tenantData]);

  useEffect(() => {
    const EmpHisRequiredRange = template?.templateSections?.EmploymentHistory?.requiredRange || 1;
    const RefRequiredRange = template?.templateSections?.References?.requiredRange || 1;

    setFormData(prevFormData => ({
      ...prevFormData,
      EmploymentHistory: prevFormData?.EmploymentHistory?.length
        ? prevFormData?.EmploymentHistory
        : Array.from({ length: EmpHisRequiredRange }, () => ({
          employerName: "",
          startDate: "",
          endDate: "",
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        })),
      References: prevFormData?.References?.length
        ? prevFormData?.References
        : Array.from({ length: RefRequiredRange }, () => ({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          referenceType: "Employer",
        })),
      RentIncomeCheck: prevFormData?.RentIncomeCheck ? prevFormData?.RentIncomeCheck
        : {
          payRentVia: "Income",
          employmentVerification: {
            employerName: "",
            phoneNumber: "",
            income: "",
            startDate: "",
          },
          contactDetails: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
          },
          socialWorkerDetails: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            AuthorityAmount: "",
            AuthorityName: "",
          }
        } as OnboardingRentIncomeCheckForm,
      Questionnaire: prevFormData?.Questionnaire?.length ? prevFormData?.Questionnaire : template?.templateSections?.Questionnaire?.questions,
      ApplicationFeesClause: { ...prevFormData?.ApplicationFeesClause, applicationFee: template?.applicationFee }
    }));
  }, [template, currentStepIndex]);
  const handleRemoveError = (
    field: string,
    form: keyof typeof OnboardingStepsEnums,
    error?: { key: string; subKey?: string }
  ) => {
    const oldErrors = { ...errors };
    oldErrors[form] = oldErrors[form] ?? {};
    if (error?.subKey) {
      oldErrors?.[form]?.[field]?.[error.subKey] &&
        delete oldErrors[form][field][error.subKey];
    }
    else {
      oldErrors?.[form]?.[field] && delete oldErrors[form][field];
    }
    setErrors({ ...oldErrors });
  };
  const goNextStep = () => {
    if (isEdit) {
      setStepFivePart2(true);
      setCurrentStepIndex(preStepIndex);
      setIsEdit(false)
      return;
    }
    if (steps[currentStepIndex] === OnboardingStepsEnums.Questionnaire && !stepFivePart2) {
      setStepFivePart2(true);
    } else {setCurrentStepIndex((step) => step + 1);}
  };
  const goPrevStep = () => {
    if (isReLogin && currentStepIndex <= 1) { return; }
    if (steps[currentStepIndex] === OnboardingStepsEnums.Questionnaire && stepFivePart2) {
      setStepFivePart2(false);
    } else { setCurrentStepIndex((step) => step - 1); }
  };
  const isEditClick = (index) => {
    setIsEdit(true);
    setCurrentStepIndex(index);
    setStepFivePart2(false);

  };
  const uploadImage = (doc) => {
    const docData = {
      EntityTypeId: doc.EntityTypeId,
      EntityId: doc.EntityId,
      DocumentTypeId: doc.DocumentTypeId,
      IsMain: doc.IsMain,
      FileName: doc.FileName,
      Document: doc.Document,
    };

    return DocumentService.postApiDocument(docData)
      .then((_res) => { })
      .catch((error) => { console.error("Error uploading document:",); });
  };

  const uploadAllImages = async (docArray) => {
    for (let i = 0; i < docArray.length; i++) {
      await uploadImage(docArray[i]);
    }

  };
  const goToApplicationView = () => {
    setCurrentStepIndex(null);
    setShowApp(true);
  };
  const handleSubmit = async () => {
    const currentStep = steps[currentStepIndex] as keyof typeof OnboardingStepsSchemas;
    try {
      setLoading(true);
      let cantGoNext = false;
      const data = formData[currentStep] || {}
      await OnboardingStepsSchemas[currentStep as keyof typeof OnboardingStepsSchemas]?.validate(data, { abortEarly: false, context: data });
      if (currentStep == OnboardingStepsEnums.BasicDetails) {
        const result: any = await apiHelper(LoginService.postApiLoginAcceptLeaseApplicationInvite, { showNotification: false }, leaseApplicationToken, data as any)
          .then(async (res) => {
            if (res?.data?.success) {
              loginResponseHandler(res);
              setLeaseApplicationId(res?.data?.success.tenantInfo?.leaseApplicationId);
              if (res?.data?.success.tenantInfo?.leaseApplicationId)
                setLeaseApplicationId(res?.data?.success.tenantInfo?.leaseApplicationId);
              fetchData()
            }
            return res;
          })
          .catch((err) => { err.body; });
        //  cantGoNext = !result.errors;
      }
      else {
        cantGoNext = await apiHelper(LeaseApplicationService.postApiLeaseApplication1, { showNotification: false }, parseInt(leaseApplicationid), currentStep, data)
          .then((res) => true).catch((err) => false);
      }
      await uploadAllImages(docsArr);
      setLoading(false);
      //  cantGoNext &&
      goNextStep();
    } catch (error: any) {
      setLoading(false);
      if (error.inner) {
        const formErrors = {};
        error.inner.forEach((error) => {
          let { path, message } = error;
          const split = path.split(".");
          message = message.split(".").reverse()[0];
          if (split.length > 1) {
            if (!formErrors[split[0]])
              formErrors[split[0]] = { [split[1]]: message };
            else formErrors[split[0]][split[1]] = message;
          } else if (!formErrors[path]) {
            formErrors[path] = message;
          }
        });
        setErrors({ ...errors, [currentStep]: formErrors });
      } else console.log("Something went wrong in the handleSubmit", error);
    }
  };
  const actions = [
    {
      id: 1,
      img: undefined,
      name: "Withdraw Application",
      onClick: () => { },
      linkpath: undefined,
      textClass: 'sub_text fw-600 blue'

    },
    {
      id: 2,
      img: undefined,
      name: "Download  Application",
      onClick: undefined,
      linkpath: undefined,
      textClass: 'sub_text fw-600 blue'
    },
  ]
  return (
    <>
      {codeExpired && !tenantData ? <>
        <div className="loginFullScreen"
          style={{ backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`, }} >
          <CommonConfirmation
            show={true}
            onConfirm={() => navigate(URLS.LOGIN)}
            onClose={undefined}
            heading="Invitation Code Invalid"
            subHeading={`Lease Application Code is Invalid, is Expired, or is already been used. If used, please login with your email and password`}
            confirmLabel="Login"
          />
        </div>
      </> : <>
        <div className="outer-container">
          <div className="onboarding-header">
            {steps[currentStepIndex] !== OnboardingStepsEnums.Thankyou && (<>
              <OrganizationNameHeader OrgName={OrgDetail?.name} OrgImg={OrgDetail?.logoImageBase64} SubHeaderName="Tenant Onboarding" />
              {showApp && (<OrganizationNameHeader OrgName={"Your Application is Under Process"} element={<div className="actionsDropdownContainer clickable">
                <CustomDropDownMenu Action={true} Element={<img src={GetImages.BlueChevronDown} alt="BlueChevronDown" />} data={actions} />
              </div>} headerNameClass="" OrgImg={GetImages.folder} SubHeaderName="" />)}
              <CardView item={leaseApplication?.unit} />
              {!showApp && (<Stepper
                currentStepIndex={currentStepIndex}
                steps={steps}
                totalSteps={steps?.length - 1} />)
                }
            </>)}
          </div>
          <>
            {renderSteps({
              currentStepIndex,
              setpreStepIndex,
              stepFivePart2,
              errors,
              handleRemoveError,
              formData,
              setFormData,
              steps,
              isEditClick,
              docsArr,
              setDocsArr,
              template,
              leaseApplicationid,
              goToApplicationView
            })}

            {steps[currentStepIndex] !== OnboardingStepsEnums.Thankyou &&
              !showApp && (
                <Footer
                  goPrevStep={goPrevStep}
                  currentStepIndex={currentStepIndex + 1}
                  stepFivePart2={stepFivePart2}
                  stepsCount={steps.length - 1}
                  handleSubmit={handleSubmit}
                />)} </>
          {showApp && (<section style={{ maxHeight: "100vh" }} className="onboarding-stepper ">
            <BoardingReview editable={false} Template={false} leaseApplication={leaseApplicationid} data={formData} name="on-boarding" isEditClick={isEditClick} />
          </section>)}
        </div>
      </>
      }
    </>
  );
};
export default TenantOnboard;