import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the shape of the error state
interface ErrorState {
  BasicDetails: {
    firstName: boolean;
    lastName: boolean;
    phoneNumber: boolean;
    email: boolean;
    password: boolean;
    confirmPassword: boolean;
  };
  EmploymentHistory: {
  }
  References: {

  }
  cardHolderName: boolean;
  cardNumber: boolean;
  cvvNumber: boolean;
  billingZipCode: boolean;
  expMonth: boolean;
  routingNumber: boolean;
  accountingNumber: boolean;
  accountingName: boolean;
  email: boolean;
  password: boolean;
  confirmPassword: boolean;
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  organizationName: boolean;
  signUpError: boolean;
  billingZipCode1: boolean;
  serviceType: boolean;
  subServiceType: boolean;
  unitNumber: boolean;
  unitNumberSize: boolean;
  dueDate: boolean;
  gracePeriods: boolean;
  amount: boolean;
  name: boolean;
  website: boolean;
  address: boolean;
  emergencyPhoneNumber: boolean;
  startDate: boolean;
  endDate: boolean;
  unit: boolean;
  document: boolean;
  selectedServiceType: boolean;
  selectedSubServiceType: boolean;
  contractorName: boolean;
  estimate: boolean;
  description: boolean;
  property: boolean;
  unitTypeId: boolean;
  reportName: boolean;
  leaseAgreement: boolean;
  leaseApplication: boolean;
  template: boolean;
  city: boolean;
  state: boolean;
  payeeAccountId: boolean;
  refrenceNum: boolean;
  streetAddress: boolean;
  title: boolean;
  securityDeposit: boolean;
  rent: boolean;
}

// Define the shape of the error messages
interface ErrorMessages {
  cardHolderName: string;
  cardNumber: string;
  cvvNumber: string;
  billingZipCode: string;
  expMonth: string;
  routingNumber: string;
  email: string;
  accountingNumber: string;
  accountingName: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  organizationName: string;
  signUpError: string;
  billingZipCode1: string;
  serviceType: string;
  subServiceType: string;
  unitNumber: string;
  unitNumberSize: string;
  dueDate: string;
  gracePeriods: string;
  amount: string;
  name: string;
  website: string;
  address: string;
  emergencyPhoneNumber: string;
  startDate: string;
  endDate: string;
  unit: string;
  document: string;
  selectedServiceType: string;
  selectedSubServiceType: string;
  contractorName: string;
  estimate: string;
  description: string;
  property: string;
  unitTypeId: string;
  reportName: string;
  leaseAgreement: string;
  leaseApplication: string;
  template: string;
  city: string;
  state: string;
  payeeAccountId: string;
  refrenceNum: string;
  streetAddress: string;
  title: string;
  securityDeposit: string;
  rent: string;
}

// Default values for error state and messages
const defaultErrors: ErrorState = {
  BasicDetails: {
    email: false,
    password: false,
    confirmPassword: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
  },
  EmploymentHistory: {
  },
  References: {},
  cardHolderName: false,
  cardNumber: false,
  cvvNumber: false,
  billingZipCode: false,
  expMonth: false,
  routingNumber: false,
  accountingNumber: false,
  accountingName: false,
  email: false,
  password: false,
  confirmPassword: false,
  firstName: false,
  lastName: false,
  phoneNumber: false,
  organizationName: false,
  signUpError: false,
  billingZipCode1: false,
  serviceType: false,
  subServiceType: false,
  unitNumber: false,
  unitNumberSize: false,
  dueDate: false,
  gracePeriods: false,
  amount: false,
  name: false,
  website: false,
  address: false,
  emergencyPhoneNumber: false,
  startDate: false,
  endDate: false,
  unit: false,
  document: false,
  selectedServiceType: false,
  selectedSubServiceType: false,
  contractorName: false,
  estimate: false,
  description: false,
  property: false,
  unitTypeId: false,
  reportName: false,
  leaseAgreement: false,
  leaseApplication: false,
  template: false,
  city: false,
  state: false,
  payeeAccountId: false,
  refrenceNum: false,
  streetAddress: false,
  title: false,
  securityDeposit: false,
  rent: false,
};

const defaultErrorMessages: ErrorMessages = {
  cardHolderName: "Card Holder Name is required",
  cardNumber: "Credit Card Number is required",
  cvvNumber: "CVV Number is required",
  billingZipCode: "Zip Code is required",
  billingZipCode1: "Zip Code must be 5 digits",
  expMonth: "Expiry Date is required",
  routingNumber: "Routing Number is required",
  accountingNumber: "Account Number is required",
  accountingName: "Account Name is required",
  email: "Email is required",
  password: "Password is required",
  confirmPassword: "Plaese Enter Confirm Password ",
  firstName: "First Name is required",
  lastName: "Last Name is required",
  phoneNumber: "Number is required",
  organizationName: "Organization Name is required",
  signUpError:
    "Some error occured, please make sure that email and phone number has not already been registered",
  serviceType: "Please select service type",
  subServiceType: "Please select sub service type",
  unitNumber: "Unit Number is required", // New error message
  unitNumberSize: "Unit Number must be at least 2 characters long",
  dueDate: "Due date is required",
  gracePeriods: "Grace period is required",
  amount: "Amount is required",
  name: "Name is required",
  website: "Website link is required",
  address: "Address is required",
  emergencyPhoneNumber: "Emergency phone number is required",
  startDate: "Start date is required",
  endDate: "End date is required",
  unit: "Unit is required",
  document: "Document is required",
  selectedServiceType: "Please select service type",
  selectedSubServiceType: "Please select sub service type",
  contractorName: "Contractor name is required",
  estimate: "Estimate is required",
  description: "Description is required",
  property: "Property is required",
  unitTypeId: "Unit Type is required",
  reportName: "Report Name is required",
  leaseAgreement: "Lease Agreement is required",
  leaseApplication: "Lease Application is required",
  template: "Template is required",
  city: "City is required",
  state: "State is required",
  payeeAccountId: "Payee Account is required",
  refrenceNum: "Refrence Number is required",
  streetAddress: "Street Address is required",
  title: "Title is required",
  securityDeposit: "Security Deposit is required",
  rent: "Rent is required"
};

// Create the ErrorContext
const ErrorContext = createContext<{
  errors: ErrorState;
  errorMessages: ErrorMessages;
  setErrors: React.Dispatch<React.SetStateAction<ErrorState>>;
  setErrorMessages: React.Dispatch<React.SetStateAction<ErrorMessages>>;
  clearErrors: () => void;
} | null>(null);

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider");
  }
  return context;
};

export const ErrorProvider = ({ children }: { children: ReactNode }) => {
  const [errors, setErrors] = useState<ErrorState>(defaultErrors);
  const [errorMessages, setErrorMessages] =
    useState<ErrorMessages>(defaultErrorMessages);

  const clearErrors = () => {
    setErrors(defaultErrors); // Reset to default values
    setErrorMessages(defaultErrorMessages); // Reset to default messages
  };

  return (
    <ErrorContext.Provider
      value={{
        errors,
        errorMessages,
        setErrors,
        setErrorMessages,
        clearErrors,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};
