import React, { ChangeEvent, useState } from "react";
import { Card, Form } from "react-bootstrap";

const ConfirmSignature = ({setPostData,postData}) => {
 
  return (
    <>
      <Card className="edit-card feeCard">
        <Card.Header>Signature Confirmation Text </Card.Header>
        <Card.Body>
          <Card.Title>Add Custom Signature Text</Card.Title>
          <Form.Control
            as="textarea"
            rows={3}
            value={postData?.templateSections?.SignatureConfirmationClause?.clause}
            onChange={(e)=>{setPostData({...postData,templateSections:{...postData.templateSections,SignatureConfirmationClause:{...postData.templateSections.SignatureConfirmationClause,clause:e.target.value}}})}}
            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a elementum dui. Mauris quis rutrum orci, sed vulputate velit. Cras laoreet massa id risus pharetra suscipit. Maecenas volutpat id turpis sed fringilla. Quisque porttitor nisi est, nec dignissim mi condimentum eu. Quisque lobortis congue augue,"
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default ConfirmSignature;
