import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { GetImages } from '../../utils/GetImages'
import AddTraspButton from '../firstTimeUserExperience/commonCom/AddTraspButton'
import AddButtonWithArrow from '../firstTimeUserExperience/commonCom/AddButtonWithArrow'
import { Col, Row, Form } from 'react-bootstrap'
import CustomInputs from '../common/Inputs'
import { FORM_CONTROLFIELD_TYPES } from '../../constants/constants'
import PaymentListCard from './PaymentListCard'
import EmptyData from '../common/EmptyData'
import { PaymentContext } from '../../utils/context/PaymentContext'
import PaymentDetail from './Modals/PaymentDetail'
import apiHelper from '../../utils/apiHelper'
import { PaymentService, TenantService } from '@propertelligent/client-api'
import Electrical from '../../assets/electrical_services.svg'
import Rent from "../../assets/rent.svg"
import { UserLoginData } from '../common/charts/Enums'


const SettlePayment = () => {

    const location = useLocation();
    const { selectedPayment, paymentMode } = location.state || {};
    const [currentPaymentMode, setCurrentPaymentMode] = useState(paymentMode || 'Payables');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [show, setShow] = useState(false)
    const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
    const [tenantData, setTenantData] = useState([])
    const [payeeName, setPayeeName] = useState("")
    const { id } = useParams()
    const navigate = useNavigate()
    const [date, setDate] = useState({})
    const [paymentMethod, setPaymentMethod] = useState('Cash');
    const { selectedItems, setSelectedItems } = useContext(PaymentContext);
    const tenantId = localStorage.getItem("tenantId")

    const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));;

    const accountOptions = [
        { id: 1, name: "Cash" },
        { id: 2, name: "Check" },
        { id: 3, name: "Online" },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const handleEdit = () => {
        setShow(true)
    }

    const getTotalAmount = () => {
        return selectedItems.reduce((total, item) => total + item.amount, 0);
    }

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDate(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const getTenantId = () => {
        apiHelper(
            TenantService.getApiTenant1,
            { showNotification: false },
            tenantId
        )
            .then((res) => {
                setPayeeName(res?.data?.success?.accountName)
                setTenantData(res?.data?.success)
            })
            .catch((error) => {
            });
    };
    useEffect(() => {
        getTenantId();
        PaymentMethodList();
    }, []);

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const PaymentMethodList = () => {
        apiHelper(
            PaymentService.getApiPaymentMethodOrganizationlist,
            { showNotification: false },
            orgId
        )
            .then((ptRes) => {
                if (ptRes?.data?.success) {
                    setPaymentMethodsArr(ptRes?.data?.success);
                }
            })
            .catch((ptErr) => { });
    }

    const renderPaymentFields = () => {
        switch (paymentMethod) {
            case 'Cash':
                return (
                    <Row className="paymentinputrow">
                        <Col className='paymentinputCol'>
                            <Form.Group controlId="dueDateInput">
                                <CustomInputs
                                    name="dueDate"
                                    labelText="Date of Payment"
                                    type={FORM_CONTROLFIELD_TYPES.DATE}
                                    isFormControl
                                    placeholder="Select Date"
                                    myClassName="normal_text grey w-50"
                                    height="37px"
                                    onChange={handleDateChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                );

            case 'Check':
                return (
                    <>
                        <Row className="paymentinputrow">
                            <Col className='paymentinputCol'>
                                <Form.Group controlId="ChequeNo">
                                    <CustomInputs
                                        labelText="Cheque No."
                                        placeholder="Enter Cheque No."
                                        onChange={undefined} 
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="paymentinputrow">
                            <Col className='paymentinputCol'>
                                <Form.Group controlId="dueDateInput">
                                    <CustomInputs
                                        name="dueDate"
                                        labelText="Date of Payment"
                                        type={FORM_CONTROLFIELD_TYPES.DATE}
                                        isFormControl
                                        placeholder="Select Date"
                                        myClassName="normal_text grey w-50"
                                        height="37px"
                                        onChange={handleDateChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                );

            default:
                return (
                    <>
                        <Row className="paymentinputrow">
                            <Col className='paymentinputCol'>
                                <Form.Group controlId="payeeAccountSelect">
                                    <CustomInputs
                                        labelText="Transaction ID"
                                        placeholder="Enter Transaction ID"
                                        onChange={undefined} 
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="paymentinputrow">
                            <Col className='paymentinputCol'>
                                <Form.Group controlId="dueDateInput">
                                    <CustomInputs
                                        name="dueDate"
                                        labelText="Date of Payment"
                                        type={FORM_CONTROLFIELD_TYPES.DATE}
                                        isFormControl
                                        placeholder="Select Date"
                                        myClassName="normal_text grey w-50"
                                        height="37px"
                                        onChange={handleDateChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                );

        }
    };


    return (
        <>
            <div className='m-3 '>
                <div className="newTenantFormTopBar d-flex align-items-center">
                    <img
                        onClick={() => {
                            navigate(-1);
                            setSelectedItems([])
                        }}
                        height={28}
                        width={28}
                        src={GetImages.leftChevronBlack}
                        className="clickable"
                    />
                    <p className="main_heading black">Settle Payment</p>
                </div>

                <div className='settlePaymentCard'>


                    <div className="settlePaymentLeft">

                        {selectedPayment ? (
                            <PaymentListCard
                                tenantData={tenantData}
                                data={[selectedPayment]}
                                selectedItems={selectedItems}
                                paymentMode={currentPaymentMode}
                                onCardClick={undefined} />
                        ) : (
                            <EmptyData mainText={`No Due Payments`} button={undefined} ImageSrc={GetImages.DuePayments} />
                        )}

                        <Form className="paymentForm" onSubmit={handleSubmit}>
                            <div className="paymentMode BorderBottom">
                                <span className='sub_heading black mb-1'>Select Payment Mode</span>
                                {/* <div className="paymentRadioButton">
                                    {accountOptions.map((option) => (
                                        <div className={paymentMethod === option.name ? "paymentTypeBtnActive" : "paymentTypeBtn"} key={option.id}>
                                            <AddTraspButton
                                                bname={option.name}
                                                imageValue={GetImages[option.name]}
                                                textclass="normal_text black fw-500"
                                                className="paymentTypeBtn"
                                                iconPosition="right"
                                                IconClassName="Icon32"
                                                onClick={() => setPaymentMethod(option.name)}
                                            />
                                        </div>
                                    ))}
                                </div> */}
                            </div>


                            <Row className="paymentinputrow">
                                <Col>
                                    <Form.Group controlId="paymentMethodSelect">
                                        <Form.Label className="normal_text black">
                                            Payment Method *
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            value={paymentMethod}
                                            onChange={handlePaymentMethodChange}
                                        >
                                            <option value="">Select Payment Method</option>
                                            {paymentMethodsArr.map((item) => (
                                                <option key={item.id} value={item.name}>
                                                    {item.accountName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="invalid-feedback"
                                        >
                                            Please select a Payment Method.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Row>
                            {paymentMethod && renderPaymentFields()}

                            {/* 
                            {paymentMethod === 'Cash' && (
                                <Row className="paymentinputrow"  >
                                    <Col className='paymentinputCol'>
                                        <Form.Group controlId="dueDateInput">
                                            <CustomInputs
                                                name={"dueDate"}
                                                labelText="Date of Payment"
                                                type={FORM_CONTROLFIELD_TYPES.DATE}
                                                isFormControl
                                                placeholder="Select Date"
                                                myClassName="normal_text grey w-50"
                                                height="37px"
                                                onChange={handleDateChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}

                            {paymentMethod === 'Cheque' && (
                                <>
                                    <Row className="paymentinputrow">
                                        <Col className='paymentinputCol'>
                                            <Form.Group controlId="ChequeNo">
                                                <CustomInputs
                                                    onChange={undefined}
                                                    labelText='Cheque No.'
                                                    placeholder='Enter Cheque No.'
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="paymentinputrow">
                                        <Col className='paymentinputCol'>
                                            <Form.Group controlId="dueDateInput">
                                                <CustomInputs
                                                    name={"dueDate"}
                                                    labelText="Date of Payment"

                                                    type={FORM_CONTROLFIELD_TYPES.DATE}
                                                    isFormControl
                                                    placeholder="Select Date"
                                                    myClassName="normal_text grey w-50"
                                                    height="37px"
                                                    onChange={undefined}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {paymentMethod === 'Online' && (
                                <>
                                    <Row className="paymentinputrow">
                                        <Col className='paymentinputCol'>
                                            <Form.Group controlId="paymentMethodSelect">
                                                <Form.Label className="normal_text black">
                                                    Select Account
                                                </Form.Label>
                                                <Form.Select onChange={undefined}>
                                                    <option value="">Select Account</option>
                                                    {accountOptions.map((item) => (
                                                        <option key={item.id} value={item.name}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" className="invalid-feedback">
                                                    Please select account.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col className='paymentinputCol'>
                                            <Form.Group controlId="payeeAccountSelect">
                                                <CustomInputs
                                                    onChange={undefined}
                                                    labelText='Transaction ID'
                                                    placeholder='Enter Transaction ID'
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="paymentinputrow">
                                        <Col className='paymentinputCol'>
                                            <Form.Group controlId="dueDateInput">
                                                <CustomInputs
                                                    name={"dueDate"}
                                                    labelText="Date of Payment"

                                                    type={FORM_CONTROLFIELD_TYPES.DATE}
                                                    isFormControl
                                                    placeholder="Select Date"
                                                    myClassName="normal_text grey w-50"
                                                    height="37px"
                                                    onChange={undefined}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>
                            )} */}

                            <div className="editFormFooter ">
                                <AddButtonWithArrow
                                    buttonname="Confirm Payment"
                                    disabled={undefined}
                                    onClick={() => {
                                        setShow(false)
                                        // setShowConfirmation(true)
                                    }}
                                />
                            </div>
                            {/* {showConfirmation && (
                                <CommonConfirmation
                                    show={true}
                                    onConfirm={undefined}
                                    onClose={handleClose}
                                    heading="Confirm payment"
                                    subHeading={`Do you want to confirm payment?`}
                                    confirmLabel="Confirm"
                                />
                            )} */}
                        </Form>
                    </div>


                    <div className='settlePaymentRight'>
                        <div className='borderWithRadius'>
                            <div className='d-flex justify-content-between p-3 BorderBottom'>
                                <p className='heading'>
                                    Payment Overview
                                </p>
                                <AddTraspButton bname="Edit" width="unset" id="editPayment" onClick={() => handleEdit()} />
                                {
                                    show && selectedPayment &&
                                    (
                                        <PaymentDetail
                                            onClose={() => setShow(false)}
                                            heading={"Edit Payment Details"}
                                            show={true}
                                            paymentMode={paymentMode}
                                            payeAccountId={selectedPayment}
                                            handleClick={undefined}
                                            isEdit={true}
                                            selectedItemId={selectedItems.id}
                                        />
                                    )

                                }
                            </div>
                            {selectedItems.length === 0 ? (
                                <EmptyData
                                    mainText="No Due Payments"
                                    button={undefined}
                                    ImageSrc={GetImages.DuePayments}
                                />
                            ) : (
                                selectedItems.map((item, index) => {
                                    return (
                                        <div key={index} className="Last_transaction">
                                            <div className='d-flex gap-1'>
                                                <img src={paymentMode === "Payables" ? Electrical : Rent} />
                                                <div className="d-flex flex-column">
                                                    <p className="normal_text">
                                                        {`${item.description} for ${item.payerPayeeName}`}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className='d-flex gap-3'>
                                                <p className='normal_text fw-500'>${item.amount}</p>
                                            </div>
                                        </div>
                                    );
                                })
                            )}

                            <div className="d-flex w-100 flex-column p-3 BorderBottom">
                                <div className='d-flex justify-content-between'>
                                    <p className='normal_text'>You're Paying</p>
                                    <p className='normal_text fw-600'>${getTotalAmount()}</p>
                                </div>

                                <div className='PerformanceOverview_Tag'>
                                    <p className='normal_text'>Outstanding amount due in 30 days</p>
                                    <p className='normal_text fw-600'>$250</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SettlePayment