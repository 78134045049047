import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../../hooks/windowDimensionsHook";
import "./topFixedContainer.css";
import Search from "../search";
import AddButton from "../../firstTimeUserExperience/commonCom/AddButton";
import { useNavigate } from "react-router";
import TopFixedSubBar from "./TopFixedSubBar";
import GenericSearchAbleDropdown from "../GenericSearchAbleDropdown";

const TopFixedBar = ({
  rowCount,
  rowCountLabel,
  searchPlaceholder,
  addButtonLabel,
  searchValue,
  setSearchValue,
  navigateTo = null,
  addCallback = null,
  currentSortDirection = undefined,
  handleSort = undefined,
  SelectInitialOptions = undefined,
  selectedOption = null,
  setSelectedOption = (value: any) => { },
  isSelectDropdown = false

}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="listViewTopHeader">
        <p className="top_header">
          {" "}
          {rowCount} {rowCountLabel}
        </p>
        <div className="topBarLeft">
          <div>
            <Search
              placeholderName={searchPlaceholder}
              searchValue={searchValue}
              searchFilterChange={setSearchValue}
            />
          </div>
          {isSelectDropdown &&
            <>
              <div>
                <TopFixedSubBar
                  currentSortDirection={currentSortDirection}
                  handleSort={handleSort}
                  isSelectDropdown={isSelectDropdown}
                />
              </div>

              <div>
                <GenericSearchAbleDropdown
                  fieldName={"name"}
                  initialOptions={SelectInitialOptions}
                  name={"selectedOption"}
                  isFormControl={true}
                  value={selectedOption}
                  required={true}
                  onChange={(e: any) => {
                    setSelectedOption(e.target.value)
                  }}
                  placeholder="Select Options"
                  myClassName="sub_text grey_subtext1"
                />
              </div>
            </>
          }
          <AddButton
            onClick={() => (navigateTo ? navigate(navigateTo) : addCallback())}
            bname={addButtonLabel}
          />
        </div>
      </div>
    </>
  );
};

export default TopFixedBar;
