import React, { useEffect, useState } from "react";
import { Badge, } from "react-bootstrap";
import "./tenantonboard.css";
import { GetImages } from "../../../utils/GetImages";
import signImg from "../../../assets/sign-img.png";
import ApplicationCard from "./ApplicationCard";
import BasicInfo from "./BasicInfo";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import { LeaseApplicationService, QuestionTypeEnum } from "@propertelligent/client-api";
import apiHelper from "../../../utils/apiHelper";
import { OnboardingCreditCheckForm, OnboardingForms, OnboardingRentIncomeCheckForm } from "../../../typings/onboarding";
import EmptyData from "../../common/EmptyData";
import CustomInputs from "../../common/Inputs";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import RadioButton from "../../common/RadioButton";

const BoardingReview: React.FC<any> = ({
  editable,
  Template = false,
  name,
  isEditClick,
  postData,
  setPostData,
  leaseApplication = null,
}) => {
  const [formData, setFormData] = useState({
    firstName: "Murtaza Abdeali",
    date: "jun-10-2024",
    permission: true
  });
  const [Userinfo, setUserInfo] = useState({ firstName: "", lastName: "", email: "", phoneNumber: "" })
  const [leaseApplicationData, setleaseApplicationData] = useState({
    CreditCheck: {} as OnboardingCreditCheckForm,
    EmploymentHistory: [],
    References: [],
    RentIncomeCheck: {} as OnboardingRentIncomeCheckForm,
    Questionnaire: []
  })

  const fetchLeaseData = () => {
    if (leaseApplication) {
      apiHelper(LeaseApplicationService.getApiLeaseApplication1, { showNotification: false }, leaseApplication)
        .then((response: any) => {
          if (response?.data?.success) {

            setUserInfo(prev => ({ ...prev, firstName: response?.data?.success?.tenant?.firstName, lastName: response?.data?.success?.tenant?.lastName, email: response?.data?.success?.tenant?.email, phoneNumber: response?.data?.success?.tenant?.phoneNumber }))
            setleaseApplicationData(prevFormData => ({
              ...prevFormData,
              CreditCheck: response?.data?.success?.dataSections?.CreditCheck
                ? JSON.parse(response.data.success.dataSections.CreditCheck)
                : null,
              EmploymentHistory: response?.data?.success?.dataSections?.EmploymentHistory
                ? JSON.parse(response.data.success.dataSections.EmploymentHistory)
                : null,
              References: response?.data?.success?.dataSections?.References
                ? JSON.parse(response.data.success.dataSections.References)
                : null,
              RentIncomeCheck: response?.data?.success?.dataSections?.RentIncomeCheck
                ? JSON.parse(response.data.success.dataSections.RentIncomeCheck)
                : null,
              Questionnaire: response?.data?.success?.dataSections?.Questionnaire
                ? JSON.parse(response.data.success.dataSections.Questionnaire)
                : null,
            }));
          }
        }).catch((error) => {

        })
    } else {
      setUserInfo(prev => ({ ...prev, firstName: "Murtaza", lastName: "Abdeali", email: "murtaza.abdeali@gmail.com", phoneNumber: "+1-111-222-3333" }))

    }
  }

  useEffect(() => {
    fetchLeaseData()
    if (postData) {
      setleaseApplicationData(prevFormData => ({
        ...prevFormData,
        CreditCheck: {
          socialSecurityNumber: "123-123-1234",
          creditPermission: "yes"
        },
        EmploymentHistory: Array.from(
          { length: postData?.templateSections?.EmploymentHistory?.requiredRange },
          () => ({
            employerName: "Hill & Associates",
            startDate: "2024-11-30 ",
            endDate: "2024-11-31",
            firstName: "Khurram",
            lastName: "Hameed",
            email: "khurram@gmail.com",
            phoneNumber: "+1-111-222-3333",
          })
        ),
        References: Array.from(
          { length: postData?.templateSections?.References?.requiredRange },
          () => ({
            firstName: "Khurram",
            lastName: "Hameed",
            email: "khurram@gmail.com",
            phoneNumber: "+1-111-222-3333",
            referenceType: "Employer",
          })
        ),
        RentIncomeCheck: {
          payRentVia: "Both",
          employmentVerification: {
            employerName: "Propertelligent",
            phoneNumber: "+1-111-222-3333",
            income: "3000",
            startDate: "Jun 29, 2023",
          },
          contactDetails: {
            firstName: "murtaza",
            lastName: "Abdeali",
            email: "murtaza.abdeali@gmail.com",
            phoneNumber: "+1-111-222-3333",
          },
          socialWorkerDetails: {
            firstName: "Murtaza",
            lastName: "Abdeali",
            email: "murtaza.abdeali@gmail.com",
            phoneNumber: "+1-111-222-3333",
            AuthorityAmount: "4000",
            AuthorityName: "Propertelligent",

          },
        },
        Questionnaire: postData?.templateSections?.Questionnaire?.questions
      }))
    }
  }, [postData?.templateSections?.References?.requiredRange, leaseApplication]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <section className="application-review-body">
        <div className="application-header">
          {!Template && editable && (
            <div className="flexRow justify-content-between">
              <h4 className="main_heading">On Boarding Application Review</h4>
              <AddTraspButton imageValue={GetImages.pdfImage} iconPosition="right" textclass="sub_text fw-600" bname={"Download Application"} border={true} />
            </div>
          )}
        </div>
        {Template && (
          <div className="application-preview-header">
            <p className="sub_heading black"> Application Preview: Not Actual Data</p>
            <p className="normal_text black"> <i>
              Please note, this preview is for visualization purposes only and
              does not contain real data.
            </i>
            </p>
          </div>
        )}
        <div className="landlordInfo p-2">
          <div className="d-flex gap-2 align-items-center">
            <BasicInfo editable={editable} data={Userinfo} isEditClick={undefined} />
          </div>
          {editable && <AddTraspButton
            width={0}
            bname={"Edit"}
            onClick={() => isEditClick(0)}
            border={false}
          />}
        </div>
        <div className="gap-3">
          <ApplicationCard
            cardHeaderName={"CURRENT ADDRESS"}
            editButton={editable && <AddTraspButton
              width={0}
              bname={"Edit"}
              onClick={() => { }
                // isEditClick(2)
              }
              border={false}
            />}
            component={
              <>
                <div className="current_address">
                  <div className="current_address_Fdetail">
                    <img src={GetImages.homeIconOrange} />
                    <p className="normal_text black">
                      205, 5250 Truman Dr, Decatur, GA 30035
                    </p>
                  </div>
                  <div className="current_address_Sdetail">
                    <div className="current_address_Sdetail_box">
                      <p className="normal_text fw-bold black">Move out date</p>
                      <p className="normal_text black">Jun 29, 2023 </p>
                    </div>
                    <div className="current_address_Sdetail_box">
                      <p className="normal_text fw-bold black">
                        Reason to move
                      </p>
                      <p className="normal_text black">
                        Looking for better apartment
                      </p>
                    </div>
                  </div>
                </div>
              </>
            }
          />
          {(postData?.templateSections?.CreditCheck || leaseApplicationData?.RentIncomeCheck) && (
            <ApplicationCard
              cardHeaderName={"CREDIT CHECK"}
              editButton={editable && <AddTraspButton
                width={0}
                bname={"Edit"}
                onClick={() => isEditClick(2)}
                border={false}
              />}
              component={
                <>
                  {leaseApplicationData?.RentIncomeCheck == null ? (
                    <EmptyData mainText={"Data not found"} button={undefined} />
                  ) : (<div className="creditcheck">
                    <div className="creditcheck_left">
                      <div className="CommonRoundBackground BackgroundlightOrange Icon40">
                        <img src={GetImages.SSN_ORANGE} alt="SSN_ORANGE" />
                      </div>
                      <>
                        <p className="normal_text fw-bold black"> Social Security Number</p>
                        <p className="normal_text black">{leaseApplicationData?.CreditCheck?.socialSecurityNumber}</p>
                      </>
                    </div>
                    <div className="creditcheck_right">
                      <p className="normal_text black">
                        Authorization obtained from the tenant to conduct a
                        credit report check.
                      </p>
                      <div className="BackgroundlightBlue CommonSquareBackground p-3">
                        <p className="normal_text">{leaseApplicationData?.CreditCheck?.creditPermission} </p>
                      </div>
                    </div>
                  </div>)}
                </>}
            />)}
          {(postData?.templateSections?.RentIncomeCheck || leaseApplicationData?.RentIncomeCheck) && (
            <>
              <ApplicationCard
                cardHeaderName={"RENT PAY INFORMATION"}
                editButton={editable && <AddTraspButton
                  width={0}
                  bname={"Edit"}
                  onClick={() => isEditClick(1)}
                  border={false}
                />}
                component={
                  <>
                    {(leaseApplicationData?.RentIncomeCheck?.payRentVia === "Income" ||
                      leaseApplicationData?.RentIncomeCheck?.payRentVia === "Both") && (
                        <div className="rentpay_information">
                          <div className="rentpay_information_income">
                            <div className="rentpay_information_income_head">
                              <p className="main_heading fw-normal green">Income</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="rentpay_information_income_info gap-2">
                                <div className="rentpay_information_income_info_card ">
                                  <p className="normal_text black"> Employer Details </p>
                                </div>
                                <div className="rentpay_information_income_info_card">
                                  <p className="heading black">{leaseApplicationData?.RentIncomeCheck?.employmentVerification?.employerName}</p>
                                  <p className="normal_text black"><i>Income: {leaseApplicationData?.RentIncomeCheck?.employmentVerification?.income}$ /m</i></p>
                                  <p className="normal_text black">Since: {leaseApplicationData?.RentIncomeCheck?.employmentVerification?.startDate}</p>
                                </div>
                              </div>
                              <div className="rentpay_information_income_info gap-2">
                                <p className="normal_text black">Contact Details</p>
                                <div className="rentpay_information_income_detail_first">
                                  <p className="sub_heading fw-normal black">
                                    {`${leaseApplicationData?.RentIncomeCheck?.contactDetails?.firstName} ${leaseApplicationData?.RentIncomeCheck?.contactDetails?.lastName}`}
                                  </p>
                                  <p className="normal_text blue">{leaseApplicationData?.RentIncomeCheck?.contactDetails?.email}</p>
                                </div>
                              </div>
                              <p className="sub_heading fw-normal black">{leaseApplicationData?.RentIncomeCheck?.contactDetails?.phoneNumber} </p>
                            </div>
                          </div>
                        </div>)}
                    {(leaseApplicationData?.RentIncomeCheck?.payRentVia === "Assisted" ||
                      leaseApplicationData?.RentIncomeCheck?.payRentVia === "Both") && (
                        <div className="rentpay_information">
                          <div className="rentpay_information_income">
                            <div className="rentpay_information_income_head">
                              <p className="main_heading fw-normal green"> Assisted </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="rentpay_information_income_info gap-2">
                                <div className="rentpay_information_income_info_card ">
                                  <p className="normal_text black"> Authority Name</p>
                                </div>
                                <div className="rentpay_information_income_info_card">
                                  <p className="heading black">Propertelligent</p>
                                   <p className="normal_text black"><i>Amount: $  {leaseApplicationData?.RentIncomeCheck?.socialWorkerDetails?.AuthorityAmount}/m</i> </p>
                                </div>
                              </div>
                              <div className="rentpay_information_income_info gap-2">
                                <p className="normal_text black"> Social Worker Details </p>
                                <div className="rentpay_information_income_detail_first">
                                  <p className="sub_heading fw-normal black">
                                    {`${leaseApplicationData?.RentIncomeCheck?.socialWorkerDetails?.firstName} ${leaseApplicationData?.RentIncomeCheck?.socialWorkerDetails?.lastName}`}
                                  </p>
                                  <p className="normal_text blue"> {leaseApplicationData?.RentIncomeCheck?.socialWorkerDetails?.email}</p>
                                </div>
                              </div>
                              <p className="sub_heading fw-normal black">{leaseApplicationData?.RentIncomeCheck?.socialWorkerDetails?.phoneNumber} </p>
                            </div>
                          </div>
                        </div>)}
                    {leaseApplicationData?.RentIncomeCheck == null && (<EmptyData mainText={"Data not found"} button={undefined} />)}
                  </>}
              />
            </>)}
          {(postData?.templateSections?.EmploymentHistory || leaseApplicationData?.EmploymentHistory) && (
            <ApplicationCard
              cardHeaderName={"EMPLOYMENT HISTORY"}
              editButton={editable && <AddTraspButton
                width={0}
                bname={"Edit"}
                onClick={() => isEditClick(3)}
                border={false}
              />}
              component={
                <>
                  {leaseApplicationData?.EmploymentHistory?.map((item, i) => (
                    <div key={i} className="Employment_history d-flex justify-content-between align-items-center">
                      <div className="w-100">
                        <p className="heading black">{item?.employerName}</p>
                        <p className="normal_text grey">
                          <i>{`${item?.startDate} - ${item?.endDate}`}</i>
                        </p>
                      </div>
                      <div className="w-100">
                        <p className="sub_heading fw-normal black">
                          {`${item?.firstName} ${item?.lastName} `}
                        </p>
                        <p className="normal_text blue ">
                          {item?.email}
                        </p>
                      </div>
                      <p className="d-flex sub_heading fw-normal black">
                        {item?.phoneNumber}
                      </p>
                    </div>
                  ))}
                  {leaseApplicationData?.EmploymentHistory == null && (<EmptyData mainText={"Data not found"} button={undefined} />)}
                </>
              }
            />
          )}
          {(postData?.templateSections?.References || leaseApplicationData?.References) && (
            <ApplicationCard
              cardHeaderName={"REFERENCES"}
              editButton={editable && <AddTraspButton
                width={0}
                bname={"Edit"}
                onClick={() => isEditClick(4)}
                border={false}
              />}
              component={<>
                {leaseApplicationData?.References?.map((item, i) => (
                  <div key={i} className="Employment_history d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-3 w-100  employerType">
                      <p className="normal_text fw-bold black">Type :<span className="normal_text green">{item?.referenceType}</span></p>
                    </div>
                    <div className="w-100">
                      <p className="sub_heading fw-normal black"> {`${item?.firstName} ${item?.lastName} `}</p>
                      <p className="normal_text blue "> {item?.email}  </p>
                    </div>
                    <p className="sub_heading fw-normal black">{item?.phoneNumber} </p>
                  </div>
                ))}
                {leaseApplicationData?.References == null && (<EmptyData mainText={"Data not found"} button={undefined} />)}
              </>}
            />)}
          {(postData?.templateSections?.Questionnaire || leaseApplicationData?.Questionnaire) && (
            <ApplicationCard
              cardHeaderName={"QUESTIONNAIRE"}
              editButton={editable && <AddTraspButton
                width={0}
                bname={"Edit"}
                onClick={() => isEditClick(5)}
                border={false}
              />}
              component={
                <>
                  <div className="application-card">
                    <div className="question-list">
                      {leaseApplicationData?.Questionnaire?.map(
                        (item, index) => (
                          <div key={index}>
                            {(item?.questionTypeId === QuestionTypeEnum.SingleChoice || item?.questionTypeId === QuestionTypeEnum.MultipleChoice) && (
                              <div className="question-row">
                                <p className="normal_text black fw-500">{item?.questionText}</p>
                                 <p className="d-flex gap-1 ">
                                  {item?.choices?.map((subItem,i) => (
                                   <> 
                                      {!leaseApplication ?
                                        <Badge bg="primary">
                                          {subItem?.optionText}
                                        </Badge>
                                        :
                                        <Badge bg="primary">
                                          {subItem?.isRadio ? subItem?.optionText : ""}
                                        </Badge>
                                      }
                                    </>
                                  ))}
                                </p>
                              </div>
                            )}
                            {item?.questionTypeId === QuestionTypeEnum.SingleChoice && item?.choices?.some(choice => choice.isRadio && choice.optionText === "Yes") && (
                              <div className="BackgroundlightRed py-3 my-3 px-2 rounded-1 ">
                                <p className="normal_text fw-bold black">Additional Details : <span className="normal_text fw-400"><i>{item.followUpQuestionText}</i> </span></p>
                              </div>
                            )}
                            {item?.questionTypeId === QuestionTypeEnum.ShortAnswer && (
                              <div className="answer">
                                <p className="normal_text black fw-500">{item?.questionText}</p>
                                <p className="normal_text">{item?.shortAnswer}</p>
                              </div>
                            )}
                            {item?.questionTypeId === QuestionTypeEnum.Paragraph && (
                              <div className="answer">
                                <p className="normal_text black fw-500">{item?.questionText}</p>
                                <p className="normal_text italic">{item?.paragraph}</p>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              }
            />
          )}
          {(postData?.templateSections?.SignatureConfirmationClause || leaseApplication) && (
            <ApplicationCard
              cardHeaderName={"SIGNATURE"}
              component={
                <>
                  <div className="signature-card">
                    <p className="fst-italic normal_text black">{postData?.templateSections?.SignatureConfirmationClause?.clause}</p>
                    <RadioButton label={"I confirm that I have read and reviewed the document."}
                      type="checkbox" value={undefined} checked={formData?.permission}
                      onChange={() => {
                        setFormData(pre => ({ ...pre, permission: !formData?.permission }))
                      }}
                      name={undefined}
                    />
                    {!Template && editable && (
                      <>
                        <div className="authority_form">
                          <CustomInputs
                            name="firstName"
                            type={FORM_CONTROLFIELD_TYPES.TEXT}
                            value={formData.firstName}
                            labelText={"Name"}
                            isFormControl={true}
                            onChange={handleChange}
                            placeholder={"Name"}
                            myClassName={"containerTextField"}
                            isError={undefined}
                            errorMessages={undefined}
                          />
                          <CustomInputs
                            name="date"
                            type={FORM_CONTROLFIELD_TYPES.DATE}
                            value={formData.date}
                            labelText={"Name"}
                            isFormControl={true}
                            onChange={handleChange}
                            placeholder={"Select a date"}
                            myClassName={"containerTextField"}
                            isError={undefined}
                            errorMessages={undefined}
                          />
                        </div>
                      </>
                    )}
                    <div className="signature-sign authority_form">
                      <div className="d-flex flex-column " >
                        <div className="d-flex gap-2 ">
                          <img src={signImg} alt="" />
                          <p className="signature">{formData.firstName} </p>
                        </div>
                        <div className="signature_border"></div>
                      </div>
                      <div className="d-flex flex-column ">
                        <p className="normal_text black py-3">{formData.date}</p>
                        <div className="signature_border"></div>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          )}
        </div>
      </section>
    </>
  );
};
export default BoardingReview;
