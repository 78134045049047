import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  PayDueAccountStatusEnum,
  PayPaymentDueAccountVM,
  PaymentDueAccountVM,
  PaymentTypesEnum,
} from "@propertelligent/client-api";
import {
  convertENUMtoJSONArray,
  isValidPositiveNumber,
} from "../../../utils/helper";
import RadioButton from "../RadioButton";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../utils/GetImages";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import { CustomInputTextArea } from "../formControls";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import "./payment.css";
import CustomInputs from "../Inputs";
import { useError } from "../../../utils/context/ErrorContext";

const PaymentForm = ({
  showModel,
  entityId,
  payeeAccountsArr,
  onClose,
  onConfirm,
  title,
  paymentMethodsArr,
  selectedPaymentType = null,
  amt,
}) => {
  const paymentDueAccountStatusArr = convertENUMtoJSONArray(PaymentTypesEnum);

  const selectedPaymentTypeObj = selectedPaymentType
    ? { paymentTypeId: selectedPaymentType }
    : {};
  const [values, setValues] = useState<PaymentDueAccountVM>({
    ...selectedPaymentTypeObj,
    entityId: entityId,
    paymentDueAccountStatusId: PayDueAccountStatusEnum.Paid,
  
  });

  const [payvalues, setPayValues] = useState<PayPaymentDueAccountVM>();
  const [validated, setValidated] = useState(false);
  const [selectedOption, setSelectedOption] = useState("option1");
  const [dueDate, setDueDate] = useState<string>("");
  const [gracePeriods, setGracePeriods] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const { errors, setErrors, errorMessages, setErrorMessages, clearErrors } =
    useError();

  const checkEmptyFields = (): boolean => {
    clearErrors();
    const newErrors = { ...errors };
    const newErrorMessages = { ...errorMessages };

    if (!dueDate.trim()) {
      newErrors.dueDate = true;
      newErrorMessages.dueDate = "Due date is required";
    } else {
      newErrors.dueDate = false;
    }

    if (!gracePeriods) {
      newErrors.gracePeriods = true;
      newErrorMessages.gracePeriods = "Grace period is required";
    } else {
      newErrors.gracePeriods = false;
    }

    if (!amount) {
      newErrors.amount = true;
      newErrorMessages.amount = "Amount is required";
    } else {
      newErrors.amount = false;
    }
    if (!description.trim()) {
      newErrors.description = true;
      newErrorMessages.description = "Description is required";
    } else {
      newErrors.description = false;
    }

    setErrors(newErrors);
    setErrorMessages(newErrorMessages);

    return Object.values(newErrors).some((error) => error);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    checkEmptyFields();

    const form = e.currentTarget;

    const isValid =
      form.checkValidity() &&
      !errors.amount &&
      !errors.dueDate &&
      !errors.gracePeriods &&
      !errors.description;

    if (!isValid) {
      setValidated(true);
      return;
    }
    setErrors({ ...errors, dueDate: false });
    setErrors({ ...errors, gracePeriods: false });
    setErrors({ ...errors, amount: false });
    setErrors({ ...errors, description: false });

    const data = {
      values: {
        ...values,
        isRecordPayment:
          values.paymentDueAccountStatusId === PayDueAccountStatusEnum.Paid,
      },
      payvalues: payvalues,
    };

    onConfirm(data);
  };

  useEffect(() => {
    if (checkEmptyFields()) {
      clearErrors();
    }
  }, []);

  return (
    <div className="paymentContainer">
      <Form
        className="paymentForm"
        noValidate
        validated={validated}
        onSubmit={handleFormSubmit}
      >
        <div className="paymentMode">
          <p className="normal_text black">Payment Status *</p>
          <div className="paymentRadioButton">
            {" "}
            <RadioButton
              label="Record Payment"
              value={PayDueAccountStatusEnum.Paid}
              name={undefined}
              checked={values?.paymentDueAccountStatusId === 2}
              onChange={(e) =>
                setValues({
                  ...values,
                  paymentDueAccountStatusId: Number(e.target.value),
                  // isRecordPayment: true,
                })
              }
            />
            <RadioButton
              label="Schedule Payment"
              value={PayDueAccountStatusEnum.Due}
              name={undefined}
              checked={values?.paymentDueAccountStatusId === 1}
              onChange={(e) =>
                setValues({
                  ...values,
                  paymentDueAccountStatusId: Number(e.target.value),
                  // isRecordPayment: false,
                })
              }
            />
          </div>
        </div>

        <Row className="paymentinputrow">
          {values?.paymentDueAccountStatusId ===
          PayDueAccountStatusEnum.Paid ? (
            <Col>
              <Form.Group controlId="paymentMethodSelect">
                <Form.Label className="normal_text black">
                  Payment Method *
                </Form.Label>
                <Form.Select
                  required
                  value={payvalues?.fromPaymentMethodId || ""}
                  onChange={(e) =>
                    setPayValues({
                      ...payvalues,
                      fromPaymentMethodId: Number(e.target.value),
                      amount: values.amount,
                    })
                  }
                >
                  <option value="">Select Payment Method</option>
                  {paymentMethodsArr?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.accountName}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback
                  type="invalid"
                  className="invalid-feedback"
                >
                  Please select a Payment Method.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          ) : null}

          <Col style={{ height: "88px" }}>
            <Form.Group controlId="payeeAccountSelect">
              <Form.Label className="normal_text black">
                Payee Account *
              </Form.Label>
              <Form.Select
                required
                name="payeeAccountId"
                value={values?.payeeAccountId || ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    payeeAccountId: Number(e.target.value),
                  })
                }
              >
                <option value="">Select Payee Account</option>
                {payeeAccountsArr.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.accountName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback
                type="invalid"
                className="invalid-feedback"
              >
                Please select a Payee Account.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="paymentinputrow">
          <Col>
            <Form.Group controlId="dueDateInput">
              <CustomInputs
                name={"dueDate"}
                labelText="Due Date *"
                required
                type={FORM_CONTROLFIELD_TYPES.DATE}
                isFormControl
                value={values?.dueDate || ""}
                onChange={(e) => {
                  setValues({ ...values, dueDate: e.target.value });
                  setErrors({ ...errors, dueDate: false });
                }}
                placeholder="Select Date"
                myClassName="normal_text grey"
                isError={errors.dueDate}
                errorMessages={errorMessages.dueDate}
                height="37px"
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group
              controlId="paymentTypeSelect"
              style={{ height: "84px" }}
            >
              <Form.Label className="normal_text black">
                Payment Type *
              </Form.Label>
              <Form.Select
                required
                value={values.paymentTypeId}
                onChange={(e) => {
                  setValues({
                    ...values,
                    paymentTypeId: Number(e.target.value),
                  });
                }}
                disabled={!!selectedPaymentType}
              >
                <option value="">Select Payment Type</option>
                {paymentDueAccountStatusArr?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback
                type="invalid"
                className="invalid-feedback"
              >
                Please select a Payment Type.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="paymentinputrow">
          <Col>
            <Form.Group controlId="amountInput">
              <CustomInputs
                name={"amount"}
                required
                labelText="Amount *"
                currencySymbol="$"
                isFormControl
                currencyHeight="46px"
                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                value={
                  values?.amount !== undefined
                    ? values.amount
                    : amt !== undefined
                    ? amt
                    : ""
                }
                onChange={(e) => {
                  const enteredAmount = isValidPositiveNumber(e.target.value);
                  setValues({ ...values, amount: enteredAmount });
                  setPayValues({ ...payvalues, amount: enteredAmount });
                  setErrors({ ...errors, amount: false });
                }}
                placeholder="Enter Amount"
                myClassName="normal_text grey"
                isError={errors.amount}
                errorMessages={errorMessages.amount}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="gracePeriodInput">
              <CustomInputs
                labelText=" Grace Period (Days) *"
                name="gracePeriodInDays"
                isFormControl
                required
                type={FORM_CONTROLFIELD_TYPES.NUMBER}
                value={values?.gracePeriodInDays || ""}
                onChange={(e) => {
                  const value = isValidPositiveNumber(e?.target?.value)
                    ? e.target.value
                    : 0;
                  setValues({
                    ...values,
                    gracePeriodInDays: value,
                  });
                  // setGracePeriodsError(!value && selectedOption === "2");
                  setErrors({ ...errors, gracePeriods: false });
                }}
                placeholder="Enter Grace Period (In Days)"
                myClassName="normal_text grey"
                isError={errors.gracePeriods}
                errorMessages={errorMessages.gracePeriods}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="paymentinputrow">
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label className="normal_text black">Description *</Form.Label>
            <CustomInputTextArea
              name="description"
              placeholder="Enter Description"
              required
              isFormControl
              value={values?.description}
              onChange={(e) => {
                setValues({ ...values, description: e?.target?.value });
                setErrors({ ...errors, description: false });
              }}
              isError={errors.description}
              errorMessage={errorMessages.description}
              myClassName="normal_text grey"
            />
          </Form.Group>
        </Row>

        <div className="editFormFooter ">
          <AddTraspButton
            bname="Cancel"
            iconPosition={undefined}
            imageValue={undefined}
            onClick={onClose}
            border={false}
          />

          <AddButtonWithArrow
            buttonname="Add Payment"
            disabled={undefined}
            onClick={undefined}
            iconDirection="left"
            Icon={GetImages.WhiteForwardArrow}
          />
        </div>
      </Form>
    </div>
  );
};

export default PaymentForm;
