import { useEffect, useState } from 'react'
import ChatRoom from '../Messaging/Chat/ChatRoom'
import apiHelper from '../../utils/apiHelper';
import { CommunicationService, MessageEntityTypesEnum, MessageVM } from '@propertelligent/client-api';
import { UserLoginData } from '../common/charts/Enums';

const TenantChatSection = ({ tenantId, chatName }) => {
    const [chatdata, setChatdata] = useState([]);
    const [entityId, setEntityId] = useState<any>();
    const [entityTypeId, setEntityTypeId] = useState<any>();
    const [messageChannel, setMessageChannel] = useState([]);
    const [loading, setLoading] = useState(true);
    const [messageData, setmessageData] = useState<MessageVM>({
        messageData: "",
        entityTypeId: entityTypeId || MessageEntityTypesEnum.Tenant,
        entityId: entityId || tenantId,
    });

    const fetchMessageChannel = () => {
        setLoading(true);

        apiHelper(CommunicationService.getApiMessageTenantlist, { showNotification: false }, tenantId)
            .then((res) => {
                setChatdata(res?.data?.success);
                setMessageChannel(res?.data?.success);
                setEntityTypeId(res?.data?.success[0].entityTypeId);
                setEntityId(res?.data?.success[0]?.entityId);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchMessageChannel();
    }, [messageChannel?.length, tenantId]);

    const sendMessage = () => {
        if (messageData.messageData !== "") {
            apiHelper(CommunicationService.postApiMessage, { showNotification: false }, messageData)
                .then((res) => {
                    if (res?.data?.success) {
                        setmessageData({
                            ...messageData,
                            messageData: "",
                            entityTypeId: entityTypeId,
                            entityId: entityId,
                        });
                        fetchMessageChannel();
                    }
                })
                .catch((error) => { });
        }
    };

    const fullName = localStorage.getItem(UserLoginData.user_name) || "";
    const nameParts = fullName.split(/\s+/).filter(Boolean);

    const firstName = nameParts[0] || "";
    const lastName = nameParts[1] || "";


    return (
        <ChatRoom
            firstName={firstName}
            lastName={lastName}
            chatdata={chatdata}
            messageData={messageData}
            setmessageData={setmessageData}
            sendMessage={sendMessage}
            tenantId={tenantId}
            chatname={chatName}
        />
    )
}

export default TenantChatSection