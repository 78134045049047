import { useEffect, useState } from 'react'
import TemplateMainHeader from './Templete/CommonComp/TemplateMainHeader'
import SimpleSpinner from '../loader';
import { AnalyticsService, ReportTypesEnum } from '@propertelligent/client-api';
import TemplateCard from './Templete/CommonComp/TemplateCard';
import SeparaterLine from '../common/SeparaterLine';
import { infiniteScrollHook } from '../../hooks/infiniteScrollHook';
import CommonConfirmation from '../common/CommonConfirmation';
import apiHelper from '../../utils/apiHelper';

const Reports = ({ resetForm }) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [reportId, setReportId] = useState()

  const handleClose = () => setShowConfirmation(false);

  const {
    rows,
    loading,
    hasMore,
    fetchData,
    currentPage
  } = infiniteScrollHook({
    httpFetchDataFn: AnalyticsService.getApiReportOrganizationlist,
    FullyQualifiedFilters: `reportTypeId == ${ReportTypesEnum.Pnl}`
  });

  const {
    rows: rows1,
    loading: loading1,
    hasMore: hasMore1,
    fetchData: fetchData1,
    currentPage: currentPage1
  } = infiniteScrollHook({
    httpFetchDataFn: AnalyticsService.getApiReportOrganizationlist,
    FullyQualifiedFilters: `reportTypeId == ${ReportTypesEnum.RentRoll}`
  });

  const handleDelete = () => {
    apiHelper(AnalyticsService.deleteApiReport,
      {
        showNotification: true,
        successMessage: "Report Deleted Successfully",
        failureMessage: "Unable to Delete Report",
      }, reportId)
      .then((delRes) => {
        if (delRes?.data?.success) {
          setShowConfirmation(false);
          fetchData(1)
          fetchData1(1)
        }
      })
      .catch((delErr) => { });
  };

  useEffect(() => {
    resetForm()
  }, [])

  return (
    <>
      <div className="Template_View_Main">
        <TemplateMainHeader
          searchTerm={undefined}
          placeholder={"Search Statement"}
          setSearchTerm={undefined}
          headerName={"Reports"}
        />
        <div className="Template_View_Card_Section gap-3" style={{ overflow: "auto" }}>
          {loading && loading1 ? <SimpleSpinner /> : <>
            <TemplateCard
              rows={rows}
              list_Name="PROFIT & LOSS"
              addNewRedirection={"filters"}
              reportType={ReportTypesEnum.Pnl}
              loading={loading}
              fetchData={fetchData}
              hasMore={hasMore}
              currentPage={currentPage}
              isEdit={false}
              isReport={true}
              setReportId={setReportId}
              setShowConfirmation={setShowConfirmation}
            />
            <SeparaterLine height={"100%"} background={"#e5e7eb"} />
            <TemplateCard
              rows={rows1}
              list_Name="RENT ROLL"
              addNewRedirection={"filters"}
              reportType={ReportTypesEnum.RentRoll}
              loading={loading1}
              fetchData={fetchData1}
              hasMore={hasMore1}
              currentPage={currentPage1}
              isEdit={false}
              isReport={true}
              setReportId={setReportId}
              setShowConfirmation={setShowConfirmation}
            />
          </>}
        </div>
      </div>
      {showConfirmation && (
        <CommonConfirmation
          show={true}
          onConfirm={handleDelete}
          onClose={handleClose}
          heading="Delete Report"
          subHeading={`Do you want to delete this Report ?`}
          confirmLabel="Delete"
        />
      )}
    </>
  )
}

export default Reports