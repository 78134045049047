import * as React from "react";
import { useState, useEffect, ChangeEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { LoginService, OpenAPI } from "@propertelligent/client-api";
import PI, { PhoneInputProps } from "react-phone-input-2";
const ReactPhoneInput: React.FC<PhoneInputProps> = (PI as any).default || PI;
import "react-phone-input-2/lib/style.css";
import { TopBar } from "../common/TopBar";
import "./SignupStyles.css";
import Inputs from "../common/Inputs";
import OtpInput from "./OtpInput";
import { Button, Form } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";
import { UserLoginData } from "../common/charts/Enums";
import { URLS, USER_ROLES, USERS } from "../../constants/constants";
import { MyContext } from "../../utils/context/LoginContext";
import apiHelper from "../../utils/apiHelper";
import CustomInputs from "../common/Inputs";
import { useError } from "../../utils/context/ErrorContext";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateNumber,
  validatePassword,
} from "../../utils/validateHelper";
type Values = {
  firstName: string;
  lastName: string;
  number: string;
  email: string;
  password: string;
  organizationName: string;
};

const Signup = () => {
  document.title = "Signup | Propertelligent";
  const { isLogin, setIsLogin } = useContext(MyContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [signedIn, SetSignedIn] = useState<boolean>(false);

  const [codeError, setCodeError] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [resendPassword, setResendPassword] = useState<boolean>(false);
  const initialSeconds: number = 59;
  const [seconds, setSeconds] = useState<number>(initialSeconds);
  const [historyPageURL, setHistoryPageURL] = useState(
    window?.location?.pathname
  );
  const navigate = useNavigate();
  const [values, setValues] = useState<Values>({
    firstName: "",
    lastName: "",
    number: "",
    email: "",
    password: "",
    organizationName: "",
  });
  const [intervalId, setIntervalId] = useState(null);
  const [signUpError, setSignUpError] = useState(false);
  const { errors, errorMessages, setErrors, setErrorMessages, clearErrors } = useError();
  // const toggleShowPassword = (): void => {
  //   setShowPassword(!showPassword);
  // };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSignUpError(false);
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setErrors({ ...errors, email: false });
        // setEmailError(false);
        setErrorMessages({ ...errorMessages, email: "" });
        break;
      case "firstName":
        setErrors({ ...errors, firstName: false });
        // setFirstNameError(false);
        setErrorMessages({
          ...errorMessages,
          firstName: "",
        });
        break;
      case "lastName":
        setErrors({ ...errors, lastName: false });
        // setLastNameError(false);
        setErrorMessages({
          ...errorMessages,
          lastName: "",
        });
        break;
      case "number":
        setErrors({ ...errors, phoneNumber: false });
        // setNumberError(false);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "",
        });
        break;
      case "password":
        setErrors({ ...errors, password: false });
        // setPasswordError(false);
        setErrorMessages({
          ...errorMessages,
          password:
            "",
        });
        break;
      case "organizationName":
        setErrors({ ...errors, organizationName: false });
        // setOrganizationError(false);
        setErrorMessages({
          ...errorMessages,
          organizationName: "",
        });
        break;
      default:
        break;
    }

    //   // Update values state
    setValues({ ...values, [name]: value });
  };

  const checkEmptyFields = (): void => {
    if (values.email.trim() === "") {
      // setEmailError(true);
      setErrors({ ...errors, email: true });
    }

    if (values.firstName.trim() === "") {
      // setFirstNameError(true);
      setErrors({ ...errors, firstName: true });
    }

    if (values.lastName.trim() === "") {
      // setLastNameError(true);
      setErrors({ ...errors, lastName: true });
    }

    if (values.organizationName.trim() === "") {
      // setOrganizationError(true);
      setErrors({ ...errors, organizationName: true });
    }

    if (values.password.trim() === "") {
      // setPasswordError(true);
      setErrors({ ...errors, password: true });
    }

    if (values.number.trim() === "") {
      // setNumberError(true);
      setErrors({ ...errors, phoneNumber: true });
    }
  };

  const handleCodeInputChange = (value: string) => {
    setCodeError(false);
    setCode(value);
    const requestObject = { email: values.email, code: Number(value) };
    if (value.length == 6) {
      setLoading(true);
      apiHelper(
        LoginService.putApiLoginVerifyEmail,
        { showNotification: false },
        requestObject
      )
        .then((response: any) => {
          if (response.data.success) {
            setLoading(false);
            setSuccess(true);
            stopCountdown();
            // navigate("/login", {
            //   state: {
            //     isComingFromSignUP: true,
            //   },
            // });
            localStorage.setItem(
              UserLoginData.token,
              response.data.success.token
            );
            OpenAPI.TOKEN = response.data.success.token;

            localStorage.setItem(
              UserLoginData.user_name,
              response.data.success.firstName +
                " " +
                response.data.success.lastName
            );
            localStorage.setItem(
              UserLoginData.user_id.toString(),
              response.data.success.userId
            );
            localStorage.setItem(
              UserLoginData.organization_id,
              response.data.success.organizationUserInfo.organizationId
            );
            localStorage.setItem(
              UserLoginData.currency_symbol,
              response.data.success.currencySymbol
            );

            localStorage.setItem(
              "userOnboarded",
              response.data.success.userOnboarded
            );
            localStorage.setItem(
              "userRoleId",
              response?.data?.success?.userRoleId
            );

            if (
              USER_ROLES[response?.data?.success?.userRoleId] === USERS.TENANT
            ) {
              localStorage.setItem(
                "tenantInfo",
                JSON.stringify(response?.data?.success?.tenantInfo)
              );
            } else if (
              USER_ROLES[response?.data?.success?.userRoleId] ===
              USERS.CONTRACTOR
            ) {
              localStorage.setItem(
                "contractorInfo",
                JSON.stringify(response?.data?.success?.contractorInfo)
              );
            }

            setIsLogin(true);
            if (response?.data?.success?.userOnboarded == false) {
              navigate(URLS.QUESTIONNAIRE);
            } else if (historyPageURL !== URLS.HOME) {
              navigate(historyPageURL);
            } else {
              navigate(URLS.HOME);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setCodeError(true);
        });
    }
  };

  const startCountdown = () => {
    setSeconds(59);
    const id = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          setResendPassword(true);
          clearInterval(id); // Clear the interval when countdown reaches 0
          return prevSeconds; // Return the same value to prevent further state updates
        } else {
          return prevSeconds - 1; // Update the countdown value
        }
      });
    }, 1000);

    setIntervalId(id);
  };

  const stopCountdown = () => {
    if (intervalId) {
      clearInterval(intervalId); 
      setIntervalId(null);
    }
  };

  const formatTime = (time: number): string => {
    return time < 10 ? `0${time}` : `${time}`;
  };

  const submitTimeError = () => {
    let updatedError = { ...errors };

    if (values.firstName.trim() === "") {
      updatedError.firstName = true;
    }
    if (values.lastName.trim() === "") {
      updatedError.lastName = true;
    }
    if (values.number.trim() === "") {
      updatedError.phoneNumber = true;
    }
    if (values.email.trim() === "") {
      updatedError.email = true;
    }
    if (values.password.trim() === "") {
      updatedError.password = true;
    }
    if (values.password.trim() === "") {
      updatedError.password = true;
    }
    if (values.organizationName.trim() === "") {
      updatedError.organizationName = true;
    }

    setErrors(updatedError);
    return Object.values(updatedError).some((error) => error); // Return true if any error exists
  };

  const handleSignUp = (e: Values) => {
    checkEmptyFields();
    if (!submitTimeError()) {
      setLoading(true);
      apiHelper(LoginService.postApiLoginSignup, { showNotification: false }, e)
        .then((response: any) => {
          if (response.data.success) {
            setLoading(false);
            setSeconds(59);
            SetSignedIn(true);
            startCountdown();
          }
        })
        .catch((error) => {
          setLoading(false);
          setSignUpError(true);
        });
    }
  };

  useEffect(()=>{
    clearErrors()
  },[])

  return (
    <div
      className="signupFullScreen"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`,
        height: signedIn ? "100vh" : "100%",
        paddingBottom:
          errors.firstName ||
          errors.lastName ||
          errors.password ||
          errors.phoneNumber ||
          errors.email ||
          errors.organizationName
            ? "100px"
            : "0px",
      }}
    >
      {/* TopBar */}
      <TopBar haveAccount="Have an account?" signInUp="Log in" />

      {/* Main Content. Some Information and Sign up form */}
      <div className="mainContainer">
        {/* Left Side Information */}
        <div className="leftSideMainContainer">
          <div className="leftSideContainerContent">
            <p className="leftSideTitleText top_header">What sets Propertelligent apart</p>
            <div className="textBox">
              <div className="smallLeftImage">
                <img src={GetImages.smart} />
              </div>
              <div>
                <p className="main_heading navyBlue">Smart Rental Applications</p>
                <p className="boxDescription normal_text fw-500">
                  Easily screen the right tenant for your properties with the
                  information that is most important to you.
                </p>
              </div>
            </div>
            <div className="textBox">
              <div className="smallLeftImage">
                <img src={GetImages.finance} />
              </div>
              <div>
                <p className="main_heading navyBlue">Finance Tools Just for Landlords</p>
                <p className="boxDescription normal_text fw-500">
                  Optimize your finances with industry-leading accounting and
                  tax prep tools built for landlords.
                </p>
              </div>
            </div>
            <div className="textBox">
              <div className="smallLeftImage">
                <img src={GetImages.simplified} />
              </div>
              <div>
                <p className="main_heading navyBlue">Simplified Rent Collection</p>
                <p className="boxDescription normal_text fw-500">
                  Easily screen the right tenant for your properties with the
                  information that is most important to you.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Right side Forms */}
        <div className="rightSideFormContainer">
          {signedIn ? (
            // Email verify code
            <div className="emailVerifyMainBox">
              <div>
                <p className="top_header black mb-2">
                  Enter email verification code
                </p>
                <p className="normal_text grey_subtext1 mb-4">
                  A 6-digit code was sent to{" "}
                  <span className="emailSpan">{values.email},</span>
                </p>
                <div style={{ marginBottom: codeError ? "8px" : "32px" }}>
                  <OtpInput
                    handleCodeInputChange={handleCodeInputChange}
                    code={code}
                    setCode={setCode}
                    status={signedIn}
                    comingFrom="signup"
                  />
                </div>
                {codeError && (
                  <p className="sub_text red mb-4 ">
                    Incorrect code. Please try again
                  </p>
                )}
                {resendPassword ? (
                  <p className="normal_text grey_subtext1 fw-500">
                    <span className="resendCodeText normal_text blue">Resend code</span>
                  </p>
                ) : (
                  <p className="normal_text fw-500 grey_subtext1">
                    Resend In
                    <span className="resendCounter normal_text">
                      {formatTime(Math.floor(seconds / 60))}:
                      {formatTime(seconds % 60)}
                    </span>
                  </p>
                )}
              </div>
            </div>
          ) : (
            // Sign up form Form
            <div className="formContainer">
              <p className="signInFormTitle top_header">Create an account</p>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSignUp(values);
                }}
              >
                {/* All Inputs */}
                <div className="allInputsWrapper">
                  {/* FirstName Input */}
                  <CustomInputs
                    labelText="First Name*"
                    isError={errors.firstName}
                    value={values.firstName}
                    onChange={(e) => {
                      handleChange(e);
                      setErrors({ ...errors, firstName: false });
                    }}
                    onBlur={() => {
                      validateFirstName(values.firstName, setErrors);
                    }}
                    errorMessages={errorMessages.firstName}
                    id="firstNameInput"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    isPassword={false}
                    // focus={true}
                  />

                  {/* LastName input */}
                  <CustomInputs
                    labelText="Last Name*"
                    isError={errors.lastName}
                    value={values.lastName}
                    onChange={(e) => handleChange(e)}
                    onBlur={() => {
                      validateLastName(values.lastName, setErrors);
                    }}
                    errorMessages={errorMessages.lastName}
                    id="lastNameInput"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    isPassword={false}
                    focus={false}
                  />

                  {/* PhoneNumber Input */}
                  <div className="singleInputsWrapper">
                    <p className="normal_text black mb-2">Phone Number*</p>
                    <ReactPhoneInput
                      country="us"
                      value={values.number}
                      onChange={(phone) => {
                        setValues({ ...values, number: phone });
                        setErrors({ ...errors, phoneNumber: false });
                      }}
                      copyNumbersOnly={false}
                      onBlur={() => {
                        validateNumber(values.number, setErrors);
                      }}
                      containerClass="phoneInputContainer normal_text"
                      containerStyle={{
                        border: errors.phoneNumber
                          ? "2px solid #F05252"
                          : "1px solid #E5E7EB",
                        marginBottom: errors.phoneNumber ? "8px" : "16px",
                      }}
                      buttonClass="phoneInputButton"
                      inputClass="phoneInputClass"
                    />
                    {errors.phoneNumber && (
                      <div style={{ marginBottom: "16px" }}>
                        <p className="errorMessageStyles">
                          {errorMessages.phoneNumber}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* Email Input */}
                  <CustomInputs
                    labelText="Email*"
                    isError={errors.email}
                    value={values.email}
                    onChange={(e) => handleChange(e)}
                    onBlur={() => {
                      validateEmail(values.email, setErrors, setErrorMessages);
                    }}
                    errorMessages={errorMessages.email}
                    id="emailInput"
                    name="email"
                    type="text"
                    placeholder="Email Address"
                    isPassword={false}
                    focus={false}
                  />

                  {/* OrganizationName Input */}
                  <CustomInputs
                    labelText="Organization Name*"
                    isError={errors.organizationName}
                    value={values.organizationName}
                    onChange={(e) => handleChange(e)}
                    onBlur={() => {
                      validateEmail(values.email, setErrors, setErrorMessages);
                    }}
                    errorMessages={errorMessages.organizationName}
                    id="organizationNameInput"
                    name="organizationName"
                    type="text"
                    placeholder="Organization Name"
                    isPassword={false}
                    focus={false}
                  />

                  {/* Password Input */}
                  <CustomInputs
                    labelText="Password*"
                    isError={errors.password}
                    value={values.password}
                    onChange={(e) => handleChange(e)}
                    onBlur={() => {
                      validatePassword(
                        values.password,
                        setErrors,
                        setErrorMessages
                      );
                    }}
                    errorMessages={errorMessages.password}
                    id="passwordInput"
                    name="password"
                    type="text"
                    placeholder="Password"
                    isPassword={true}
                  />
                </div>

                {/* Signup Button */}
                <Button
                  id="signUpButton"
                  type="submit"
                  variant="contained"
                  className="signUpButton sub_heading"
                  disabled={
                    errors.firstName ||
                    errors.lastName ||
                    errors.password ||
                    errors.organizationName ||
                    errors.email ||
                    errors.phoneNumber
                  }
                >
                  {loading ? (
                    <img height={50} src={GetImages.loadingGif} />
                  ) : (
                    "Sign Up"
                  )}
                </Button>
                {signUpError && (
                  <div style={{ marginBottom: "16px" }}>
                    <p className="errorMessageStyles sub_text ">
                      {errorMessages.signUpError}
                    </p>
                  </div>
                )}
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signup;
