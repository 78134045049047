import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { Button, Col, Container, Form, Row, Image } from "react-bootstrap";
import { updateServiceRequest } from "../../redux/actions/serviceRequestAction";
import {
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
} from "@propertelligent/client-api";
import {
  CustomDatePicker,
  CustomInput,
  CustomInputTextArea,
} from "../common/formControls";
import { FORM_CONTROLFIELD_TYPES } from "../../constants/constants";
import upload from "../../assets/Upload.png";
import Arrow_forward from "../../assets/arrow_forward_400.png";
import Warnning from "../../assets/Warnning.png";
import Time_Red from "../../assets/Time_Red.png";
import Worker from "../../assets/Worker.png";
import apiHelper from "../../utils/apiHelper";
import CustomInputs from "../common/Inputs";

const InProgressForm = React.memo(
  ({
    id,
    state,
    latestContractor,
    calculateCurrentDate,
    setState,
    handleMarkCompleteNShowVerify,
    showBtn,
    setShowBtn,
  }: any) => {
    const dispatch: any = useDispatch();
    const dateField = "completeDate";
    const initialDocInfo = {
      EntityTypeId: DocumentEntityTypesEnum.ServiceRequest,
      EntityId: id,
      DocumentTypeId: DocumentTypesEnum.AfterImage,
      IsMain: false,
      FileName: "Service request file",
      Document: null,
      _rawFile: null,
    };

    const [uploadDocument, setUploadDocument] = useState<any>();
    const [fileName, setFileName] = useState<any>("");
    const [docsArr, setDocsArr] = useState([]);
    const [documentError, setDocumentError] = useState(false);
    const [triedSubmit, setTriedSubmit] = useState(false);
    const [errorMessages, setErrorMessages] = useState({
      actualCost: "This field is required",
      materialCost: "This field is required",
      [dateField]: "This field is required",
      document: "This field is required",
    });
    const [errors, setErrors] = useState({
      actualCost: false,
      materialCost: false,
      [dateField]: false,
      document: false,
    });

    /**Important: Docs method stable & working */
    const getDocuments = () => {
      apiHelper(
        DocumentService.getApiDocumentList,
        { showNotification: false },
        id,
        DocumentEntityTypesEnum.ServiceRequest,
        DocumentTypesEnum.AfterImage
      )
        .then((res) => {
          let documentArray = res?.data?.success;

          if (documentArray) {
            const promises = documentArray.map((item) => {
              const fileNameParts = item.fileName.split(".");
              if (
                fileNameParts.length > 1 &&
                ["png", "jpg", "jpeg"].includes(
                  fileNameParts[fileNameParts.length - 1].toLowerCase()
                )
              ) {
                return DocumentService.getApiDocumentBase641(item.id)
                  .then((res) => {
                    item["Document"] = "data:image/png;base64," + res.success;
                    item["_rawFile"] = "data:image/png;base64," + res.success;
                  })
                  .catch((error) => {});
              }
              return Promise.resolve(); // Return a resolved promise for non-image files
            });

            // Wait for all promises to complete before updating the state
            Promise.all(promises)
              .then(() => {
                setDocsArr(documentArray);
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    };

    const handleChange = (fieldName: string, value: any) => {
      let newValue =
        ["actualCost", "materialCost"].includes(fieldName) && value < 0
          ? 0
          : value;
      setState((prevState: any) => ({ ...prevState, [fieldName]: newValue }));
      if (triedSubmit) {
        setErrors((prevValues) => ({ ...prevValues, [fieldName]: !newValue }));
      }
    };

    const handleUpdateServiceRequest = () => {
      setTriedSubmit(true);
      if (!state?.actualCost) {
        setErrors((prevValues) => ({ ...prevValues, actualCost: true }));
      }
      if (!state?.materialCost) {
        setErrors((prevValues) => ({ ...prevValues, materialCost: true }));
      }
      if (!state?.[dateField]) {
        setErrors((prevValues) => ({ ...prevValues, [dateField]: true }));
      }
      if (!!state?.actualCost && !!state[dateField] && !!state.materialCost) {
        dispatch(
          updateServiceRequest({
            ...state,
            [dateField]: `${state[dateField]}T18:59:04.734Z`,
            id,
          })
        );
        handleMarkCompleteNShowVerify();
        setShowBtn(false);
      } else return;
    };

    const handleUploadServiceRequestDocument = (selectedFile: File | null) => {
      if (selectedFile) {
        setDocumentError(false);
        const newDoc = { ...initialDocInfo };
        const blob = new Blob([selectedFile]);

        Object.assign(newDoc, {
          FileName: selectedFile?.name,
          Document: blob,
          _rawFile: selectedFile,
        });

        const { _rawFile, ...apiDocObj } = newDoc;
        apiHelper(
          DocumentService.postApiDocument,
          { showNotification: false },
          apiDocObj
        )
          .then((docRes) => {
            setDocsArr((prevState: any) => [...prevState, newDoc]);
            getDocuments();
          })
          .catch((docErr) => {
            setDocumentError(true);
          });
      }
    };

    useEffect(() => {
      getDocuments();
    }, []);

    return (
      <>
        <Container fluid>
          {showBtn && (
            <div className="newTenantDesciptionContainer">
              <p className="newTenantfixNotesText">WORK DETAIL</p>
            </div>
          )}
          {!showBtn && (
            <div>
              <div className="singleServiceNameDetailsLeftSide">
                <div>
                  <Row style={{ width: "auto" }}>
                    <Col xs={12}>
                      <h4 className="singleTenantDetailsName">
                        Contractor Details
                      </h4>
                      <div className="singleContractorDetails">
                        <span className="sub_text black">
                          <img
                            height={16}
                            width={16}
                            src={Worker}
                            style={{ marginRight: "4px" }}
                          />
                          {latestContractor}
                        </span>

                        <span className="sub_text black">
                          <img
                            height={16}
                            width={16}
                            src={Time_Red}
                            style={{ marginRight: "4px" }}
                          />
                          Assigned on : {calculateCurrentDate}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}
          <Row style={{ width: "auto" }}>
            <Col xs={12} md={12} lg={12} sm={12}>
              {showBtn && (
                <>
                  <div className="parentDiv gap-1" key="div1">
                    <Form.Group>
                      <CustomInputs
                        labelText="Actual Cost *"
                        name="actualCost"
                        type={FORM_CONTROLFIELD_TYPES.NUMBER}
                        required
                        value={state?.actualCost}
                        isFormControl
                        onChange={(e) =>
                          handleChange("actualCost", e.target.value)
                        }
                        placeholder="Enter Actual Cost"
                        myClassName="containerTextField"
                        isError={errors.actualCost}
                        errorMessages={errorMessages.actualCost}
                      />
                    </Form.Group>
                    <Form.Group>
                      <CustomInputs
                        labelText="Material Cost *"
                        name="materialCost"
                        type={FORM_CONTROLFIELD_TYPES.NUMBER}
                        isFormControl
                        value={state?.materialCost}
                        onChange={(e) =>
                          handleChange("materialCost", e.target.value)
                        }
                        placeholder="Enter Material Cost"
                        myClassName="containerTextField"
                        isError={errors.materialCost}
                        errorMessages={errorMessages.materialCost}
                      />
                    </Form.Group>
                    <Form.Group controlId="formDate">
                      <CustomInputs
                        labelText="Fix Date *"
                        name={dateField}
                        type={FORM_CONTROLFIELD_TYPES.DATE}
                        isFormControl
                        value={state[dateField] || null}
                        onChange={(e) =>
                          handleChange(dateField, e.target.value)
                        }
                        placeholder="Enter Fix Date"
                        myClassName="containerTextField"
                        isError={errors?.[dateField]}
                        errorMessages={errorMessages?.[dateField]}
                      />
                    </Form.Group>
                  </div>
                  <br />

                  <Form.Label className="normal_text text-start">
                    Fix Note *
                  </Form.Label>
                  <CustomInputTextArea
                    name="description"
                    rows={3}
                    placeholder="Enter Note"
                    required
                    isFormControl
                    value={state?.fixNotes}
                    myClassName="normal_text black"
                    onChange={(e) => handleChange("fixNotes", e.target.value)}
                  />
                  <br />
                </>
              )}

              {showBtn && (
                <>
                  <div className="nemwTenantDesciptionContainer">
                    <p className="newTenantfixNotesText">
                      UPLOAD WORK IMAGES *
                    </p>
                  </div>

                  <div
                    className="documentUploadContainer"
                    style={{ marginBottom: "8px" }}
                  >
                    <FileUploader
                      handleChange={handleUploadServiceRequestDocument}
                      classes="documentUploader"
                      children={
                        <>
                          <div className="fileUploaderTenant">
                            <img src={upload} />
                            <p className="normal_text fw-600 black text-center">
                              Upload Images or Videos
                            </p>
                            <p className="sub_text grey_subtext1 text-center">
                              Max. size 5MB. .png, .jpg
                            </p>
                          </div>
                        </>
                      }
                    />

                    {documentError && (
                      <p className="errorMessage"> {errorMessages.document} </p>
                    )}
                  </div>
                </>
              )}
              {!showBtn && docsArr.length > 0 && (
                <div style={{ margin: "24px 6px 12px" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "24px",
                      color: "#111928",
                    }}
                  >
                    Review work
                  </span>
                </div>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: "25px",
                  flexWrap: "wrap",
                  gap: "5px",
                  // borderBottom: "1px solid #E5E7EB",
                }}
              >
                {docsArr.length > 0 &&
                  docsArr.map((doc) => (
                    <div
                      style={{
                        width: "24%",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        width={"100%"}
                        height={"126px"}
                        style={{ borderRadius: "8px" }}
                        src={doc?._rawFile}
                      />
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
);

export default InProgressForm;
