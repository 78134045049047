import React, { createContext, useContext, useState } from 'react';

const GeneratePdfContext = createContext(null);

export const GeneratePdfProvider = ({ children }) => {
    const [statementData, setStatementData] = useState({});

    const [orgDetails, setOrgDetails] = useState({});
    const [subHeaderName, setSubHeaderName] = useState();
    const [reportStatementId, setReportStatementId] = useState(null);

    const values = { 
        statementData,
         setStatementData,
          reportStatementId, 
          setReportStatementId,
          orgDetails,
          setOrgDetails,
          subHeaderName,
          setSubHeaderName
        }


    return (
        <GeneratePdfContext.Provider value={values}>
            {children}
        </GeneratePdfContext.Provider>
    );
};

export const useGeneratePdfContext = () => {
    return useContext(GeneratePdfContext);
};
