import React, { useState } from 'react';
import SeparaterLine from '../common/SeparaterLine';
import AddButtonWithArrow from '../firstTimeUserExperience/commonCom/AddButtonWithArrow';

const FilterDropdown = ({ filters, selectedFilters, onFilterChange }) => {

  const handleCheckboxChange = (category, value) => {
    onFilterChange(category, value);
  };

  return (
    <div className="filter-component">
      <div className="BorderBottom">
        <h4 className="heading px-3">Filter by</h4>
      </div>

      <div className="d-flex ">
        {filters.map((filter) => (
          <>
            <div key={filter.category} className='filterRow'>
              <h5 className="normal_text fw-500 p-3">
                {filter.category}
              </h5>
              <div>
                {filter.options.map((option) => (
                  <label key={option} className="filtersOption sub_text">
                    <input
                      className="p-3"
                      type="checkbox"
                      value={option}
                      checked={selectedFilters[filter.category]?.includes(option)}
                      onChange={() => handleCheckboxChange(filter.category, option)}
                    />
                    {option}
                  </label>
                ))}
              </div>

            </div>
            <SeparaterLine height={undefined} background={undefined} />
          </>
        ))}
      </div>

      <div className="BorderTop applyFilterBtn">
       <AddButtonWithArrow buttonname="Apply filter"/>
      </div>
    </div>
  );
};

export default FilterDropdown;
