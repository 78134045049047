import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
// import "./tenantonboard.css";
import "../RenderSteps/tenantonboard.css";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import AddButton from "../../firstTimeUserExperience/commonCom/AddButton";
// import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
const Footer = ({
  currentStepIndex,
  stepsCount,
  stepFivePart2,
  goPrevStep,
  handleSubmit,
}) => {
  let buttonname = !stepFivePart2 ? "Review" : "Pay Fee" ;
  return (
    <>
      <div className="footerSection">
        <div className="flexRow gap-2">
          <AddTraspButton bname={"Cancel"} iconPosition={undefined} onClick={undefined} border={true} />
         
          {currentStepIndex >1  && (
          <AddButton onClick={goPrevStep} bname={"Previous"} />
            
          )}
           <AddButton onClick={handleSubmit} bname={currentStepIndex != stepsCount ? "Next" :  buttonname} />
          
        </div>
      </div>
    </>
  );
};
export default Footer;
