import React from 'react'
import { GetImages } from '../../../utils/GetImages'

const OrganizationNameHeader = ({
  SubHeaderName,
  OrgName="Organization Name",
  OrgImg=GetImages.HomeBlackFilled,
  element = undefined,
  headerNameClass="top_header fw-normal grey_subtext1"
}) => {
  
  return (
    <>
      <div className="organization-header">
        <div className="flexFullRow itemContainer">
          <div className="flexColumn">
            <div className="flexFullRow align-items-center">
              <div className="flexColumn itemContainerimg">
                <img
                  alt={"unit"}
                  src={ OrgImg ? OrgImg : GetImages.HomeBlackFilled}
                />
              </div>
              <div className="flexColumn itemContainerimgDetail">
                <p className={headerNameClass}>
                  {OrgName}
                </p >
                <p className="main_heading grey_subtext1">{SubHeaderName}</p>
              </div>
            </div>
          </div>
          <div className='d-flex gap-2 right_section'>
            {element}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrganizationNameHeader