import React from "react";
import Info from "../../../assets/info.png";
import CustomSwitchToggle from "../../common/Switch";
import { GetImages } from "../../../utils/GetImages";

const QuestionOptional: React.FC<any> = ({
  data,
  postData
}) => {
  
  return (
    <>
      <section className="application-sidebar">
        <div className=" application-sidebar-head">
          <p className="main_heading black">Application Sections</p>
          <p className="normal_text black"><i>
            "Customize your experience with toggle buttons to enable or disable
            sections in our app."</i>
          </p>
        </div>
        {data?.map((item ,i) => (
          <div key={i} className=" application-sidebar-item">
            <p className={`normal_text fw-600 ${!postData?.templateSections?.[item.name.replace(/\s+/g, "")] ? "grey" :"greyHeading" } `}>{item.name}</p>
            <div className="option d-flex gap-2 justify-content-center align-items-center">
              {item?.editable && (   !postData?.templateSections?.[item.name.replace(/\s+/g, "")] ?          
                <img onClick={undefined} src={ GetImages.GreyPencilEdit} alt="pencil-outline.png" className="Icon20" /> 
                :
                <img onClick={item.editClick} src={GetImages.BluePencilEdit} alt="pencil-outline.png" className="Icon20" />                
              )}
              <CustomSwitchToggle checked={!postData?.templateSections?.[item.name.replace(/\s+/g, "")] ? false :true} onClick={item.onClick} 
              //  disabled={!postData?.templateSections[item.name.replace(/\s+/g, "")] ? true :false}
              />
              <img src={Info} className="Icon30" alt="info.png" />{" "}
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default QuestionOptional;
