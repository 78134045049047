import React from 'react'
import DisplayNamewithLeftBorder from '../../common/DisplayNamewithLeftBorder';
import SeparaterLine from '../../common/SeparaterLine';

const MoveOutDetails = () => {
    return (
        <div className="ledgerNotesSection leaseDetailsSection">
            <div className="rentLedgerContainer">
                <div className="d-flex justify-content-between">
                    <div className='d-flex align-items-baseline gap-2'>
                        <DisplayNamewithLeftBorder
                            HeaderName="Move Out Details"
                            className="sub_text black mb-3"
                        />
                    </div>
                </div>

                <div className="my-3">
                    <div className="d-flex justify-content-between leaseDetailDataContainer">
                        <div>
                            <p className="normal_text black fw-600">Move Out Date</p>
                            <span className="normal_text black fw-500">
                                Jun 29, 2023
                            </span>
                        </div>
                        <SeparaterLine
                            height={"48px"}
                            background={"#e5e7eb"}
                        />
                        <div>
                            <p className="normal_text black fw-600">Move Out Reason</p>
                            <span className="normal_text black fw-500">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut enim vitae risus rutrum lobortis
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoveOutDetails