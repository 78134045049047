import { Route, Routes, useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import EmailSignUp from "./emailSignUp";

export const GenerateEmail = () => {
  const query = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

   useEffect(() => {
    let emailType = query.get('emailType');
    if(emailType){
      navigate("/generateEmail/" + emailType);
    }    
  }, []);
  
  return (<> 
  <Routes>
    <Route path="/signup" element={<EmailSignUp />} />
   </Routes>
  </>)
};
