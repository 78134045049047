import { useEffect, useState, useRef } from "react";
import { Button, Card, FormControl, Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";
import "./Screens.css";
import { LeaseApplicationService, LeaseApplicationStatusTypesEnum } from "@propertelligent/client-api";

const ApprovedScreen = ({leaseApplicationId,status}) => {
  const [interalTenant, setInteralTenant] = useState();
  const [noteTenant, setNoteTenant] = useState();

  const handleChange = (e) => {
    setInteralTenant(e.target.value);
  };

  const handleTenantChange = (e) => {
    setNoteTenant(e.target.value);
  };

  const handleSubmit = () => {
      const dataToSend = {
        leaseApplicationStatusTypeId: status,
        internalNotes:interalTenant,
        tenantNotes:  noteTenant
      };
    
      LeaseApplicationService.putApiLeaseApplicationReivew(leaseApplicationId, dataToSend)
        .then((response) => {
         console.log("response",response )
        })
        .catch((error) => {
          console.error("Error toggling section:", error);
        });
 
  };
  return (
    <>
      <div className="approved-app-form">
        <div className="InnerBody">
          <p className="innerBodyTitle">INTERAL NOTES</p>
          <p className="mt-3 mb-2">
            This is internal notes and would not be sent to Tenat
          </p>

          <textarea
            className="w-100 mb-3"
            value={interalTenant}
            onChange={handleChange}
            rows={3}
            placeholder="Add note here..."
          ></textarea>

          <p className="innerBodyTitle">NOTES FOR TENANT</p>
          <p className="mt-3">This note will be sent to Tenant</p>
          <br />
          <textarea
            className="w-100 p-3"
            value={noteTenant}
            onChange={handleTenantChange}
            rows={3}
            placeholder="Add note here..."
          ></textarea>
        </div>

        <div className="ButtonFrame">
          <Button variant="primary"> Cancel</Button>
          {status == "5" ? (
             <select
            id="approvedScreendropdown"
            className="ml-5 btn-sm"
            value={"option"}
            onClick={handleSubmit}
          >
            <option disabled>Actions</option>
            <option>Approve Only</option>
            <option>Approve and Send leade Agreement</option>
          </select>
  ):
  <Button variant="danger">Close Application</Button>
}
        </div>
      </div>
    </>
  );
};

export default ApprovedScreen;
