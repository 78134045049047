import { Row, Col, Button } from 'react-bootstrap';
import GenericSearchAbleDropdown from './GenericSearchAbleDropdown';
import CustomInputs from './Inputs';
import { FORM_CONTROLFIELD_TYPES } from '../../constants/constants';
import { useError } from '../../utils/context/ErrorContext';
import { GetImages } from '../../utils/GetImages';


const DynamicAddMoreForm = ({ headers, data, setData, initialOptions, handleAddPayment }) => {
    const { errors, setErrors, errorMessages } = useError()

    const handleDeletePayment = (id) => {
        setData(data.filter(payment => payment.id !== id));
    };

    const handleChange = (id, field, value) => {
        setData(data.map(payment =>
            payment.id === id ? { ...payment, [field]: value } : payment
        ));
        setErrors({ ...errors, [field]: false });
    };

    return (
        <>
            <div className='dynamicAddMoreForm'>
                <Row className="align-items-center justify-content-start mb-3 paymentRow normal_text">
                    {headers && headers.map((header, index) => (
                        <Col key={index} md={2}>{header}</Col>
                    ))}
                </Row>

                {data && data.map(payment => (
                    <Row key={payment.id} className="align-items-baseline justify-content-start mb-3 paymentRow">
                        {headers.includes("Payment Type") && (
                            <Col md={2}>
                                <GenericSearchAbleDropdown
                                    fieldName={"name"}
                                    initialOptions={initialOptions}
                                    name={"paymentType"}
                                    isFormControl={true}
                                    value={payment.paymentType}
                                    required={true}
                                    onChange={(e) => handleChange(payment.id, 'paymentType', e.target.value)}
                                    placeholder="Select an Option"
                                    myClassName="normal_text grey_subtext1"
                                    style={{ height: "46px" }}
                                />
                            </Col>
                        )}
                        {headers.includes("Amount ($)") && (
                            <Col md={2}>
                                <CustomInputs
                                    name={"amount"}
                                    type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                    value={payment.amount}
                                    isFormControl={true}
                                    currencySymbol="$"
                                    onChange={(e) => handleChange(payment.id, 'amount', e.target.value)}
                                    placeholder="Enter Amount"
                                    myClassName="containerTextField normal_text grey"
                                    isError={errors.amount}
                                    errorMessages={errorMessages.amount}
                                />
                            </Col>
                        )}
                        {headers.includes("Due Date") && (
                            <Col md={2}>
                                <CustomInputs
                                    name={"startDate"}
                                    type={FORM_CONTROLFIELD_TYPES.DATE}
                                    isFormControl={true}
                                    value={payment.dueDate || null}
                                    onChange={(e) => handleChange(payment.id, 'dueDate', e.target.value)}
                                    placeholder="Select a Date"
                                    myClassName="containerTextField normal_text grey"
                                    isError={errors.dueDate}
                                    errorMessages={errorMessages.dueDate}
                                />
                            </Col>
                        )}
                        {headers.includes("Grace Period") && (
                            <Col md={2}>
                                <CustomInputs
                                    name={"graceperiod"}
                                    type={FORM_CONTROLFIELD_TYPES.NUMBER}
                                    value={payment.gracePeriod}
                                    isFormControl={true}
                                    currencySymbol="Days"
                                    onChange={(e) => handleChange(payment.id, 'gracePeriod', e.target.value)}
                                    placeholder="0"
                                    myClassName="containerTextField normal_text grey"
                                    isError={errors.gracePeriods}
                                    errorMessages={errorMessages.gracePeriods}
                                />
                            </Col>
                        )}
                        {headers.includes("Description") && (
                            <Col md={3}>
                                <CustomInputs
                                    name={"description"}
                                    type={FORM_CONTROLFIELD_TYPES.TEXT}
                                    value={payment.description}
                                    isFormControl={true}
                                    onChange={(e) => handleChange(payment.id, 'description', e.target.value)}
                                    placeholder="Enter Description"
                                    myClassName="containerTextField normal_text grey"
                                    isError={errors.description}
                                    errorMessages={errorMessages.description}
                                />
                            </Col>
                        )}
                        <Col md={1} className="text-center">
                            <img src={GetImages.DeleteRedBg} alt='' onClick={() => handleDeletePayment(payment.id)} />
                        </Col>
                    </Row>
                ))}
            </div>
            <Button variant="link"
                onClick={handleAddPayment}
                className="p-0 text-decoration-none sub_heading blue">
                Add More
            </Button>
        </>
    )
}

export default DynamicAddMoreForm