import {TemplateService} from "@propertelligent/client-api";
import TemplateCard from "./CommonComp/TemplateCard";
import TemplateMainHeader from "./CommonComp/TemplateMainHeader";
import { infiniteScrollHook } from "../../../hooks/infiniteScrollHook";
import SimpleSpinner from "../../loader";

const TempleteView = () => {
  const {
    loading,
    rows,
    fetchData,
    hasMore,
    currentPage
  } = infiniteScrollHook({
    httpFetchDataFn:
      TemplateService.getApiTemplateOrganizationlist,
  });
  return (
    <div className="Template_View_Main">
      <TemplateMainHeader searchTerm={undefined} placeholder={"Search Templates"} setSearchTerm={undefined} headerName={"Template"} />
      <div className="Template_View_Card_Section gap-3">
        {loading ? <SimpleSpinner /> : <>
          <TemplateCard
            rows={rows}
            list_Name="LEASE APPLICATION"
            addNewRedirection={"create"}
            loading={loading}
            fetchData={fetchData}
            hasMore={hasMore}
            currentPage={currentPage}
          />
        </>
        }
      </div>
    </div>

  );
};

export default TempleteView;
