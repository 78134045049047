import {
  DocumentEntityTypesEnum,
  DocumentTypesEnum,
  LookupService,
  LookupTypesEnum,
  ServiceRequestEntityTypesEnum,
} from "@propertelligent/client-api";
import { useState, useEffect } from "react";
import SimpleSpinner from "../../../components/loader";
import { Button, Col, Form, Row } from "react-bootstrap";
import RadioButton from "../../../components/common/RadioButton";
import { CustomInputTextArea } from "../../../components/common/formControls";
import { FileUploader } from "react-drag-drop-files";
import { GetImages } from "../../../utils/GetImages";
import AddButtonWithArrow from "../../../components/firstTimeUserExperience/commonCom/AddButtonWithArrow";
import apiHelper from "../../../utils/apiHelper";
import CustomInputs from "../../../components/common/Inputs";
import AddTraspButton from "../../../components/firstTimeUserExperience/commonCom/AddTraspButton";

const AddServiceRequestTenant = ({
  ServiceTypes,
  isLoading,
  onClose,
  onConfirm,
}) => {
  const [docsArr, setDocsArr] = useState([]);
  const [ServiceSubTypes, setServiceSubTypes] = useState([]);

  const tenantInfoString = localStorage.getItem("tenantInfo");
  const tenantInfo = JSON.parse(tenantInfoString);
  const unitId = tenantInfo && tenantInfo.unitId;

  const [values, setValues] = useState({
    entityTypeId: ServiceRequestEntityTypesEnum.Unit,
    serviceType: null,
    entityId: unitId,
    serviceSubType: null,
    description: null,
    requestDate: new Date(),
  });

  const [validated, setValidated] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    onConfirm({ values, docsArr });
  };

  const handleUploadServiceRequestDocument = async (selectedFile: any) => {
    if (selectedFile) {
      const newDoc = {
        EntityTypeId: DocumentEntityTypesEnum.ServiceRequest,
        EntityId: null,
        DocumentTypeId: DocumentTypesEnum.BeforeImage,
        IsMain: false,
        FileName: selectedFile?.name,
        Document: new Blob([selectedFile]),
        _rawFile: selectedFile,
      };

      setDocsArr((prevState) => [...prevState, newDoc]);
    }
  };

  const handleChangeType = (value) => {
    apiHelper(
      LookupService.getApiLookup1,
      { showNotification: false },
      LookupTypesEnum.ServiceSubTypes,
      value
    )
      .then((response) => setServiceSubTypes(response?.data?.success))
      .catch((err) => {});
  };

  useEffect(() => {
    return () => {
      setServiceSubTypes([]);
      setDocsArr([]);
      setValues({
        entityTypeId: ServiceRequestEntityTypesEnum.Unit,
        serviceType: null,
        entityId: unitId,
        serviceSubType: null,
        description: null,
        requestDate: new Date(),
      });
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <SimpleSpinner />
      ) : (
        <div className="paymentContainer">
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => handleFormSubmit(e)}
          >
            <div className="paymentInputsContainer">
              <Row className="paymentinputrow">
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label className="sub_text  grey_subtext1">
                      Service Type *
                    </Form.Label>
                    <Form.Select
                      className="sub_text  grey_subtext1"
                      aria-label="Default Service Typ"
                      required
                      value={values?.serviceType}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          serviceType: Number(e.target.value),
                        });
                        handleChangeType(Number(e.target.value));
                      }}
                    >
                      <option value="">Service Type</option>
                      {ServiceTypes?.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback
                      type="invalid"
                      className="invalid-feedback"
                    >
                      Please select a Service Type
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label className="sub_text  grey_subtext1">
                      Service Sub Type *
                    </Form.Label>
                    <Form.Select
                      className="sub_text  grey_subtext1"
                      aria-label="Default select Service Sub Type"
                      required
                      value={values?.serviceSubType}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          serviceSubType: Number(e.target.value),
                        })
                      }
                    >
                      <option value="">Service Sub Type</option>
                      {ServiceSubTypes?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback
                      type="invalid"
                      className="invalid-feedback"
                    >
                      Please select a Service Sub Type
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="paymentinputrow">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <CustomInputs
                    name="description"
                    labelText="Description *"
                    placeholder="Enter Description"
                    required
                    isFormControl
                    value={values?.description}
                    onChange={(e) =>
                      setValues({ ...values, description: e?.target?.value })
                    }
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="invalid-feedback"
                  >
                    Entere Description.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="paymentinputrow">
                <Col>
                  <div
                    className="documentUploadContainer"
                    style={{ marginBottom: "8px", width: "100%" }}
                  >
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label className="sub_text  grey_subtext1">
                        Add Attachments (Optional)
                      </Form.Label>
                      <FileUploader
                        handleChange={handleUploadServiceRequestDocument}
                        classes="documentUploader"
                        style={{ width: "100%" }}
                        children={
                          <>
                            <div
                              className="fileUploaderTenant"
                              style={{ width: "100%" }}
                            >
                              <img src={GetImages.upload} />
                              <p className="normal_text fw-600 black text-center">
                                Upload Documents
                              </p>
                              <p className="sub_text grey_subtext1 text-center">
                                Max. size 5MB. .png, .jpg
                              </p>
                            </div>
                          </>
                        }
                      />
                    </Form.Group>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "40px",
                      flexWrap: "wrap",
                    }}
                  >
                    {docsArr.length > 0 &&
                      docsArr.map((doc) => (
                        <div
                          style={{
                            width: "24%",
                            flexDirection: "column",
                            padding: "4px",
                          }}
                        >
                          <img
                            width={"100%"}
                            height={"126px"}
                            src={URL.createObjectURL(doc?._rawFile)}
                          />
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="tenantFormFooter">
              <div className="footerRightButtons">
                {/* <Button onClick={onClose} variant="light">
                  Cancel
                </Button> */}
                <AddTraspButton
                  bname="Cancel"
                  onClick={onClose}
                />
                <AddButtonWithArrow
                  buttonname="Add Request"
                  onClick={undefined}
                  disabled={undefined}
                  iconDirection="left"
                  Icon={GetImages.WhiteForwardArrow}
                />
              </div>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default AddServiceRequestTenant;
