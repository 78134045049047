import React, { useEffect, useState } from 'react';
import { PubSub, TopicsEnum } from '../../utils/pubsub';
import AddTemplate from '../my-organization/Templete/AddTemplate/AddTemplate';
import BoardingReview from '../TenantOnboard/RenderSteps/BoardingReview';
import { TemplateEntityTypesEnum, TemplateSectionVM, TemplateService, TemplateTypeEnum } from '@propertelligent/client-api';
import OrganizationNameHeader from '../common/OrganizationNameHeader/OrganizationNameHeader';

const LeaseAgreement = () => {
  const [postData, setPostData] = useState<any | undefined>();

  const id = 61;
  useEffect(() => {
    if (id) {
      getTempleteData();
    }
  }, [id]);

  useEffect(() => {

  }, [postData])

  const getTempleteData = () => {
    TemplateService.getApiTemplate(Number(id))
      .then((res) => {
        if (res?.success) {

          setPostData(res?.success);

        }
      })
      .catch((err) => {

      });
  };

  useEffect(() => {
    {
      //Fire this when the report is created
      //PubSub.publish(TopicsEnum.PDFReportCreated, null);
    }
  }, []);

  const cleanPathname = location.pathname.replace(/^\/+|\/+$/g, "");
  const generatePdf = cleanPathname.startsWith("generatePdfDocument");

  return (

    <div className='leaseAgreement'>
      <BoardingReview postData={postData} setPostData={setPostData} editable={false} Template={true} name="print boarding review" />
    </div>

  );
};

export default LeaseAgreement;