import { useEffect, useState, useRef } from "react";
import { Button, Card, FormControl, Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";
import "./Screens.css";

const AddNote = ({ onClose, type ,onSave ,toggle}) => {
  const [note, setNote] = useState("");

  const handleSaveClick = () => {
    onSave(note, type ,toggle);  
    onClose(); 
  };
  return (
    <>
      <div className="approved-app-form">
        <div className="InnerBody NotesBody">
          <button onClick={onClose} className="CloseBtn">
            X
          </button>
          <p className="innerBodyTitle"> NOTES</p>

          <textarea
            className="w-100 mb-3"
            rows={2}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Add note here..."
          ></textarea>
          <div className="ButtonFrame noteBtns">
            <Button variant="light" onClick={onClose} className="white-button">
              {" "}
              Cancel
            </Button>
            <Button className="saveBtn" onClick={handleSaveClick}>Save</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNote;
