import React from 'react'
import EmptyData from '../../common/EmptyData'
import DateHelper from '../../../utils/DateHelper'

const RentRollStatementTable = ({ statementData }) => {

    return (
        <>

            <table className="table table-hover statement_table">
                <thead>
                    <tr>
                        <th className='heading grey_subtext1' scope="col">Tenant Name / Unit Address</th>
                        <th className='heading grey_subtext1' scope="col">Beds /Baths /sqft</th>
                        <th className='heading grey_subtext1' scope="col">Lease Start</th>
                        <th className='heading grey_subtext1' scope="col">Lease End</th>
                        <th className='heading grey_subtext1' scope="col">Rent</th>
                    </tr>
                </thead>
                <tbody>
                    {statementData && statementData?.rentRollItems?.length > 0 ? (
                        <>
                            {statementData.rentRollItems && statementData?.rentRollItems?.map((elem, index) => (
                                <tr key={index}>
                                    <td className='normal_text grey_subtext1'>
                                        <p className='fw-bold'>{elem.tenantName}</p>
                                        <p>{elem.unitAddress}</p>
                                    </td>
                                    <td className='normal_text grey_subtext1'>
                                        {elem.numberOfBedrooms} / {elem.numberOfBathrooms} / {elem.size}
                                    </td>
                                    <td className='normal_text grey_subtext1'>
                                        {elem.leaseStartDate ? DateHelper.convertUTCtoDateTimeSimple(elem.leaseStartDate) : 'N/A'}
                                    </td>
                                    <td className='normal_text grey_subtext1'>
                                        {elem.leaseEndDate ? DateHelper.convertUTCtoDateTimeSimple(elem.leaseEndDate) : 'N/A'}
                                    </td>
                                    <td className='normal_text grey_subtext1'>
                                        {elem.rent ? `$${elem.rent.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A'}
                                    </td>
                                </tr>
                            ))}
                        </>
                    ) : (
                        <tr className='emptyDataRow'>
                            <td colSpan={5}>
                                <EmptyData mainText={undefined} subText={"No data available"} button={undefined} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

        </>
    )
}

export default RentRollStatementTable