import Forward from "../../../assets/blueforwardarrow.png";
import { GetImages } from "../../../utils/GetImages";

const AddTraspButton = ({
  bname,
  iconPosition = undefined,
  imageValue = null,
  onClick = undefined,
  border = undefined,
  disabled = undefined,
  textclass = undefined,
  width = undefined,
  id = undefined,
  className = undefined,
  IconClassName = undefined,
  type="submit"
}:any) => {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={`addtrasparent ${className}`}
        id={id}
        style={{
          border: !border ? "none" : "",
          boxShadow: !border ? "none" : "",
          background: !border ? "none" : "",
          minWidth: width,
        }}
        disabled={disabled}
      >
        {iconPosition === "right" && imageValue !== null && (
          <img src={imageValue} className={`iconstyle ${IconClassName}`} />
        )}
        <p className={textclass ? `${textclass}` : "normal_text fw-600 blue"}>
          {" "}
          {bname}{" "}
        </p>
        {iconPosition === "left" && imageValue !== null && (
          <img src={GetImages.blueForwardArrow} className="iconstyle" />
        )}
      </button>
    </>
  );
};

export default AddTraspButton;
