import React from 'react'
import { Route, Routes } from 'react-router'
import TransactionView from './view'
import "./transactions.css"

const Transaction = () => {
    return (
        <Routes>
            <Route index element={<TransactionView />} />
        </Routes>
    )
}

export default Transaction