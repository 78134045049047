import React, {  useCallback } from "react";
import "./tenantonboard.css";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import CustomInputs from "../../common/Inputs";
import DisplayNamewithLeftBorder from "../../common/DisplayNamewithLeftBorder";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";

const EmploymentHistory: React.FC<any> = ({
  formData,
  errors,
  setFormData,
  handleRemoveError,
}: {
  formData: any;
  errors: { [key: string]: any };
  setFormData: any;
  handleRemoveError: (
    field: string,
    form: keyof typeof OnboardingStepsEnums,
    error?: { key: string; subKey?: string }
  ) => void;

}) => {
  
  const handleContactDetailChange = useCallback((index: number, key: string, value: string) => {
    const updatedContactDetails = [...formData];
    updatedContactDetails[index][key] = value;
    setFormData({ ...formData, EmploymentHistory: updatedContactDetails });
    handleRemoveError(key, "EmploymentHistory", { key });
  },[formData])

  const generateBasicDetails = (index: number) => [
    {
      id: 1,
      name: "firstName",
      labelText: "First Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData[index].firstName,
      placeholder: "Enter First Name",
      myClassName: "containerTextField",
      isError: errors[`[${index}]`]?.firstName?.length > 0 ,
      errorMessages:errors[`[${index}]`]?.firstName,
    },
    {
      id: 2,
      name: "lastName",
      labelText: "Last Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData[index].lastName,
      placeholder: "Enter Last Name",
      myClassName: "containerTextField",
      isError: errors[`[${index}]`]?.lastName?.length > 0,
      errorMessages:errors[`[${index}]`]?.lastName,
    },
    {
      id: 3,
      name: "email",
      labelText: "Email",
      type: FORM_CONTROLFIELD_TYPES.EMAIL,
      value: formData[index].email,
      placeholder: "Enter Email",
      myClassName: "containerTextField",
      isError:  errors[`[${index}]`]?.email?.length > 0,
      errorMessages:  errors[`[${index}]`]?.email,
    },
    {
      id: 4,
      name: "phoneNumber",
      labelText: "Phone",
      type: FORM_CONTROLFIELD_TYPES.NUMBER,
      value: formData[index].phoneNumber,
      placeholder: "Enter Phone No",
      myClassName: "containerTextField",
      isError: errors[`[${index}]`]?.phoneNumber?.length > 0,
      errorMessages: errors[`[${index}]`]?.phoneNumber,
    },
  ];

  const employeeHistory = (index: number) => [
    {
      id: 1,
      name: "employerName",
      labelText: "Employer Name",
      type: FORM_CONTROLFIELD_TYPES.TEXT,
      value: formData[index].employerName,
      placeholder: "Enter Employer Name",
      myClassName: "containerTextField",
      isError: errors[`[${index}]`]?.employerName?.length > 0,
      errorMessages: errors[`[${index}]`]?.employerName,
    },
    {
      id: 2,
      name: "startDate",
      labelText: "Start Date",
      type: FORM_CONTROLFIELD_TYPES.DATE,
      value: formData[index].startDate,
      placeholder: "startDate",
      myClassName: "containerTextField",
      isError: errors[`[${index}]`]?.startDate?.length > 0,
      errorMessages: errors[`[${index}]`]?.startDate,
    },
    {
      id: 3,
      name: "endDate",
      labelText: "End Date",
      type: FORM_CONTROLFIELD_TYPES.DATE,
      value: formData[index].endDate,
      placeholder: "endDate",
      myClassName: "containerTextField",
      isError: errors[`[${index}]`]?.endDate?.length > 0,
      errorMessages: errors[`[${index}]`]?.endDate,
    },
  ]
  return (
    <>
      <div className="onboarding-stepper">
        <div className="onboarding-steppercard">
          <p className="main_heading pb-2">Employment History</p>
          <div className="onboardingstepper-form flexColumn gap-3">
            {formData?.map((_, index) => (            
                <div className="d-flex flex-column gap-3" key={`${index}`}>
                  <DisplayNamewithLeftBorder HeaderName={"Add previous employment history"} />
                  <div className="basic-form">
                    {employeeHistory(index)?.map((item) => (
                      <div  key={`${item.id}`}>
                      <CustomInputs
                        name={`${item.name}-${index}`}
                        type={item.type}
                        value={item.value}
                        labelText={item.labelText}
                        isFormControl={true}
                        onChange={(e) => handleContactDetailChange(index, item.name, e.target.value)}
                        placeholder={item.placeholder}
                        myClassName={item.myClassName}
                        isError={item.isError}
                        errorMessages={item.errorMessages}
                      />
                      </div>

                    ))
                    }
                  </div>
                  <DisplayNamewithLeftBorder HeaderName={"Contact Details"} />
                  <div className={`social-worker`}>
                    {generateBasicDetails(index).map((item) => (
                      <div key={`${item.id}-${index}`}>
                        <CustomInputs
                          name={`${item.name}-${index}`}
                          type={item.type}
                          value={item.value}
                          labelText={item.labelText}
                          isFormControl={true}
                          onChange={(e) => handleContactDetailChange(index, item.name, e.target.value)}
                          placeholder={item.placeholder}
                          myClassName={item.myClassName}
                          isError={item.isError}
                          errorMessages={item.errorMessages}
                        />
                      </div>
                    ))}
                  </div>
               </div>
             
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmploymentHistory;


