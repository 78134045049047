import { useEffect, useState } from "react";
import { websiteRegex } from "../common/Regex";
import { DocumentEntityTypesEnum, DocumentService, DocumentTypesEnum, OrganizationService, PropertyVM } from "@propertelligent/client-api";
import { Form } from "react-bootstrap";
import { UserLoginData } from "../common/charts/Enums";
import { GetImages } from "../../utils/GetImages";
import apiHelper from "../../utils/apiHelper";
import { useError } from "../../utils/context/ErrorContext";
import { validateEmail, validateFirstName, validateLastName, validateName, validateNumber, validateUnitNumber, validateZipCode } from "../../utils/validateHelper";
import RequestBreadcrumb from "../common/RequestBreadcrumb";
import FormSections from "./FormSections";
import { FORM_CONTROLFIELD_TYPES } from "../../constants/constants";

const BasicDetailsComponent = () => {
  const { errors, errorMessages, setErrors, setErrorMessages, clearErrors } = useError();
  const [values, setValues] = useState({
    organizationName: "",
    email: "",
    phoneNumber: "",
    website: "",
    streetAddress: "",
    unitNumber: "",
    city: "",
    state: "",
    zip: ""
  });
  const [mainImage, setMainImage] = useState<any>();
  const [fileName, setFileName] = useState("");
  const [updatedMainImageIdObj, setUpdatedMainImageIdObj] = useState({
    mainImageId: 0,
  });
  const [propertyValue, setPropertyvalue] = useState<PropertyVM>();
  const organizationId = Number(localStorage.getItem(UserLoginData.organization_id));

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    apiHelper(OrganizationService.getApiOrganization1, { showNotification: false }, organizationId)
      .then((res) => {
        setValues({
          ...values,
          organizationName: res?.data?.success?.name,
          email: res?.data?.success?.email,
          phoneNumber: res?.data?.success?.phoneNumber,
          website: res?.data?.success?.website,
          streetAddress: res?.data?.success?.address?.streetAddress,
          unitNumber: res?.data?.success?.unitNumber,
          city: res?.data?.success?.address?.city,
          state: res?.data?.success?.address?.state,
          zip: res?.data?.success?.address?.zip
        });
      })
      .catch((error) => { });
  };

  const validateWebsite = (): boolean => {
    if (values.website.trim().length > 0) {
      if (websiteRegex.test(values.website)) {
        setErrors({ ...errors, website: false });
      } else {
        setErrors({ ...errors, website: true });
        setErrorMessages({
          ...errorMessages,
          website: errorMessages.website,
        });
        return true;
      }
    }
    return false;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "email":
        setErrors({ ...errors, email: false });
        break;
      case "organizationName":
        setErrors({ ...errors, organizationName: false });
        break;
      case "website":
        setErrors({ ...errors, website: false });
        break;
      case "phoneNumber":
        setErrors({ ...errors, phoneNumber: false });
        break;
      case "address":
        setErrors({ ...errors, streetAddress: false });
        break;
      case "unitNumber":
        setErrors({ ...errors, unitNumber: false });
        break;
      case "city":
        setErrors({ ...errors, city: false });
        break;
      case "state":
        setErrors({ ...errors, state: false });
        break;
      case "zip":
        setErrors({ ...errors, billingZipCode: false });
        break;
      default:
        break;
    }
    setValues({ ...values, [name]: value });
  };

  const formDetails = {
    basicDetails: [
      {
        label: "Organization Name *",
        placeholder: "Enter Organization Name",
        value: values.organizationName,
        name: "organizationName",
        type: FORM_CONTROLFIELD_TYPES.TEXT,
        blurFunction: () => validateName(values.organizationName, setErrors),
        errorMessages: errorMessages.organizationName,
        isError: errors.organizationName,
        linkWebsite: false,
      },
      {
        label: "Website link *",
        placeholder: "Enter Website Link",
        value: values.website,
        name: "website",
        type: FORM_CONTROLFIELD_TYPES.TEXT,
        blurFunction: validateWebsite,
        errorMessages: errorMessages.website,
        isError: errors.website,
        linkWebsite: true,
      },
    ],
    contactInfo: [
      {
        label: "Email Address *",
        placeholder: "Enter Email Address",
        value: values.email,
        name: "email",
        type: FORM_CONTROLFIELD_TYPES.EMAIL,
        blurFunction: () =>
          validateEmail(values.email, setErrors, setErrorMessages),
        errorMessages: errorMessages.email,
        isError: errors.email,
        linkWebsite: false,
      },
      {
        label: "Phone Number *",
        placeholder: "Enter Phone No.",
        value: values.phoneNumber,
        name: "phoneNumber",
        type: FORM_CONTROLFIELD_TYPES.TELEPHONE,
        blurFunction: () => validateNumber(values.phoneNumber, setErrors),
        errorMessages: errorMessages.phoneNumber,
        isError: errors.phoneNumber,
        linkWebsite: false,
      }
    ],
    officeAddress: [
      {
        label: "Unit No. *",
        placeholder: "Enter Unit Number",
        value: values.unitNumber,
        name: "unitNumber",
        type: FORM_CONTROLFIELD_TYPES.TEXT,
        blurFunction: () => validateUnitNumber(values.unitNumber, setErrors, setErrorMessages),
        errorMessages: errorMessages.unitNumber,
        isError: errors.unitNumber,
        linkWebsite: false,
      },
      {
        label: "Street Address *",
        placeholder: "Enter Street Address",
        value: values.streetAddress,
        name: "address",
        type: FORM_CONTROLFIELD_TYPES.TEXT,
        blurFunction: () => validateName(values.streetAddress, setErrors),
        errorMessages: errorMessages.streetAddress,
        isError: errors.streetAddress,
        linkWebsite: false,
      },
      {
        label: "City *",
        placeholder: "Enter City",
        value: values.city,
        name: "city",
        type: FORM_CONTROLFIELD_TYPES.TEXT,
        blurFunction: () => { },
        errorMessages: errorMessages.city,
        isError: errors.city,
        linkWebsite: false,
      },
      {
        label: "State *",
        placeholder: "Enter State",
        value: values.state,
        name: "state",
        type: FORM_CONTROLFIELD_TYPES.TEXT,
        blurFunction: () => { },
        errorMessages: errorMessages.state,
        isError: errors.state,
        linkWebsite: false,
      },
      {
        label: "Zip *",
        placeholder: "Enter Zip",
        value: values.zip,
        name: "zip",
        type: FORM_CONTROLFIELD_TYPES.TEXT,
        blurFunction: () => validateZipCode(values.zip, setErrors, setErrorMessages),
        errorMessages: errorMessages.billingZipCode,
        isError: errors.billingZipCode,
        linkWebsite: false,
      },
    ]
  }

  const checkEmptyFields = (): boolean => {
    let hasErrors = false;
    clearErrors();
    if (values.email.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: false }));
    }

    if (values.phoneNumber.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: false }));
    }

    if (values.streetAddress?.trim() === "" || values.streetAddress?.trim() === undefined) {
      setErrors(({ ...errors, streetAddress: true }));
      hasErrors = true;
    } else {
      setErrors(({ ...errors, streetAddress: false }));
    }

    if (values.unitNumber?.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, unitNumber: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, unitNumber: false }));
    }

    if (values.state?.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, state: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, state: false }));
    }

    if (values.city?.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, city: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, city: false }));
    }

    if (values.zip?.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, billingZipCode: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, billingZipCode: false }));
    }

    if (values.organizationName.trim() === "") {
      setErrors({ ...errors, organizationName: true });
      setErrorMessages({ ...errorMessages, organizationName: "Organization Name is required" });
      hasErrors = true;
    }
    return hasErrors;
  };

  const submitTimeError = () => {
    return (
      errors.organizationName || errors.firstName || errors.lastName || errors.email ||
      errors.phoneNumber || errors.streetAddress || errors.website || errors.unitNumber ||
      errors.city || errors.state || errors.billingZipCode ||
      values.organizationName.trim() === "" || values.streetAddress?.trim() === "" ||
      values.phoneNumber.trim() === "" || values.email.trim() === "" ||
      values.unitNumber.trim() === "" || values.city.trim() === "" ||
      values.state.trim() === "" || values.zip.trim() === ""
    );
  };

  const handleAddDetails = (e) => {
    e.preventDefault();
    checkEmptyFields();
    if (submitTimeError()) {
    } else {
      const newValues = {
        name: values.organizationName,
        logoImageId: updatedMainImageIdObj.mainImageId,
        logoImageBase64: mainImage,
        unitNumber: values.unitNumber,
        phoneNumber: values.phoneNumber,
        email: values.email,
        website: values.website,
        address: {
          streetAddress: values.streetAddress,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        id: Number(localStorage.getItem(UserLoginData.organization_id)),
      };
      apiHelper(OrganizationService.putApiOrganization,
        {
          successMessage: "Basic Details Updated Successfully",
          failureMessage: "Unable to Update Basic Details",
          showNotification: true,
        }, newValues
      ).then(({ data }) => {
        setValues({
          ...values,
          organizationName: "",
          email: "",
          phoneNumber: "",
          streetAddress: "",
          website: "",
        });
        getUserData();
      });
    }
  };

  useEffect(() => {
    if (checkEmptyFields()) {
      clearErrors();
    }
  }, []);

  const handleChangeMainImage = (selectedFile: File | null) => {
    setFileName(selectedFile?.name);
    const blob = new Blob([selectedFile], { type: "image/png|jpg" });
    setMainImage(blob);
    // setUploadImage(blob);

    const imgData = {
      EntityTypeId: DocumentEntityTypesEnum.Organization,
      EntityId: organizationId,
      DocumentTypeId: DocumentTypesEnum.Logo,
      Document: blob,
      FileName: selectedFile?.name,
    };

    apiHelper(DocumentService.postApiDocument, { showNotification: false }, imgData)
      .then((imageResponse: any) => {
        const currentMainImageId = imageResponse?.data?.success?.id;

        setUpdatedMainImageIdObj({ mainImageId: currentMainImageId });
        DocumentService.getApiDocumentBase641(currentMainImageId)
          .then((imageResponse: any) => {
            const imageData = "data:image/*;base64," + imageResponse?.success;
            setMainImage(imageData);
            // if (setMainImageId) {
            //   setTimeout(() => {
            //     setMainImageId(currentMainImageId);
            //   }, 1000);
            // }
          })
          .catch((imageErr: any) => { });
      })
      .catch((imageErr: any) => { });
  };

  const handleDelete = () => {
    setMainImage(null);
    setFileName("");
  };

  useEffect(() => {
    if (propertyValue?.address) {
      setValues({
        ...values,
        streetAddress: propertyValue?.address?.streetAddress,
        city: propertyValue?.address?.city,
        state: propertyValue?.address?.state,
        zip: propertyValue?.address?.zip
      })
    }
  }, [propertyValue])

  return (
    <div className="basic_details">
      <div className="basic_detail_breadcrumb">
        <RequestBreadcrumb
          firstName={"Dashboard"}
          link={"/"}
          currentStep={"My Organization"}
        />
      </div>
      <Form onSubmit={handleAddDetails}>
        <div className="basic_details_container">
          <FormSections
            formDetails={formDetails.basicDetails}
            handleChangeMainImage={handleChangeMainImage}
            handleDelete={handleDelete}
            mainImage={mainImage}
            setFileName={setFileName}
            handleChange={handleChange}
            headerName={"Basic Details"}
            isUploader={true}
            isBasicDetail={true}
          />

          <FormSections
            formDetails={formDetails.contactInfo}
            handleChangeMainImage={handleChangeMainImage}
            handleDelete={handleDelete}
            mainImage={mainImage}
            setFileName={setFileName}
            handleChange={handleChange}
            headerName={"Contact Info"}
            isUploader={false}
          />

          <FormSections
            formDetails={formDetails.officeAddress}
            handleChangeMainImage={handleChangeMainImage}
            handleDelete={handleDelete}
            mainImage={mainImage}
            setFileName={setFileName}
            handleChange={handleChange}
            headerName={"Office Address"}
            isUploader={false}
            propertyValue={propertyValue}
            setPropertyvalue={setPropertyvalue}
          />
        </div>

        <div className="basic_details_footer">
          <button type="submit" className="saveChangesButton normal_text fw-600">
            Save Changes <img src={GetImages.WhiteForwardArrow} />
          </button>
        </div>
      </Form>
    </div>
  );
};

export default BasicDetailsComponent;
