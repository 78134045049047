import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import EmploymentHistory from "./EmployementHistory";
import { OnboardingStepsEnums } from "../../../constants/onboarding";
import folder from "../../../assets/folder.png";
import {
  OnboardingFormErrorsType,
  OnboardingForms,
} from "../../../typings/onboarding";
import EmptyData from "../../common/EmptyData";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../utils/GetImages";

export const renderSteps = ({
  currentStepIndex,
  setpreStepIndex,
  stepFivePart2,
  errors,
  handleRemoveError,
  formData,
  setFormData,
  steps,
  isEditClick,
  docsArr, 
  setDocsArr,
  template,
  
  leaseApplicationid,
  goToApplicationView
}: {
  currentStepIndex: number;
  setpreStepIndex:any;
  stepFivePart2: boolean;
  errors:any;
  //  errors: OnboardingFormErrorsType;
  handleRemoveError: (
    field: string,
    form: keyof typeof OnboardingStepsEnums,
    error?: { key: string; error: string }
  ) => void;
  formData: OnboardingForms;
  setFormData: React.Dispatch<React.SetStateAction<OnboardingForms>>;
  //  steps: OnboardingStepsEnums[];
  steps:any;
  isEditClick:any;
  docsArr:any;
  setDocsArr:any;
  template:any;
  leaseApplicationid:Number
  goToApplicationView:any
}) => {
  
  switch (steps[currentStepIndex]) {
    case OnboardingStepsEnums.BasicDetails:
      return (
        <StepOne
          formData={formData?.BasicDetails  }
          setFormData={setFormData}
          errors={errors?.BasicDetails}
          handleRemoveError={handleRemoveError}
          docsArr={docsArr}
          setDocsArr={setDocsArr}
        />
      );
    case OnboardingStepsEnums.RentIncomeCheck:
      return (
        <StepTwo
          formData={formData?.RentIncomeCheck }
          setFormData={setFormData}
          errors={errors?.RentIncomeCheck}
          handleRemoveError={handleRemoveError}
          leaseApplicationid={leaseApplicationid}
        />
      );
    case OnboardingStepsEnums.CreditCheck:
      return (
        <StepThree
          formData={formData?.CreditCheck}
          setFormData={setFormData}
          errors={errors?.CreditCheck}
          handleRemoveError={handleRemoveError}
          
        />
      );
    case OnboardingStepsEnums.EmploymentHistory:
      return (
        <EmploymentHistory
          formData={formData?.EmploymentHistory}
          setFormData={setFormData}
          errors={errors?.EmploymentHistory}
          handleRemoveError={handleRemoveError}
        />
      );
    case OnboardingStepsEnums.References:
      return (
        <StepFour
          formData={formData?.References}
          setFormData={setFormData}
          errors={errors?.References}
          handleRemoveError={handleRemoveError}
        />
      );
    case OnboardingStepsEnums.Questionnaire:
      return (
        <StepFive
          formData={formData?.Questionnaire} 
          data = {formData}
          setFormData={setFormData}
          leaseApplication={leaseApplicationid}
          errors={errors?.Questionnaire}
          currentStepIndex={currentStepIndex}
          setpreStepIndex={setpreStepIndex}
          stepFivePart2={stepFivePart2}
          handleRemoveError={handleRemoveError}
          isEditClick={isEditClick}
        />
      );
    case OnboardingStepsEnums.ApplicationFeesClause:
      return (
        <StepSix
          formData={formData?.ApplicationFeesClause}
          setFormData={setFormData}
          errors={errors?.ApplicationFeesClause}
          handleRemoveError={handleRemoveError}
        />
      );
      case OnboardingStepsEnums.Thankyou:
        return(
         <EmptyData 
         ImageSrc={folder}
         mainText={"Thank You"}
          subText="for Submitting Your Tenant Onboarding Form! Your form has been received. We'll be in touch soon!" 
          button={<AddButtonWithArrow buttonname={"View Your Application"} onClick={goToApplicationView} iconDirection={"left"} Icon={GetImages.ArrowForward} />} />
        )
    // Add more cases for additional pages
    default:
      return null;
  }
};



