import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { OrganizationService } from "@propertelligent/client-api";
import { usePaginationHookUpdated } from "../../../hooks/usePaginationHookUpdated";
import AccountingDropDown from "../../../components/common/AccountingDropDown";
import EmptyData from "../../../components/common/EmptyData";
import SimpleSpinner from "../../../components/loader";
import DateHelper from "../../../utils/DateHelper";
import { insertUniqKeyInArr } from "../../../utils/helper";
import { UserLoginData } from "../../../components/common/charts/Enums";
import ComponentHeading from "../../common/componentHeading";
import { infiniteScrollHook } from "../../../hooks/infiniteScrollHook";

const TransactionView = () => {
    const [selectedType, setSelectedType] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [fullyQualifiedFilters, setFullyQualifiedFilters] = useState(null);
    const [fullyQualifiedSortFilters, setFullyQualifiedSortFilters] = useState(null);
    const [rev_monthValue, setRev_MonthValue] = useState(-12);
    let isLoadingElement = null;

    const { rows, loading, hasMore, rowCount, distinctFilterValues,currentPage,
        aggregateValues, calculateValue, fetchData } = infiniteScrollHook({
            httpFetchDataFn: OrganizationService.getApiOrganizationTransactionTenantlist,
            // paginationDataMapper: insertUniqKeyInArr,
            FullyQualifiedFilters: fullyQualifiedFilters || `Created > DateTime.UtcNow.AddMonths(${rev_monthValue})`,
            FullyQualifiedSorts: fullyQualifiedSortFilters,
            // Id: parseInt(localStorage.getItem(UserLoginData.tenant_id))
        });
    if (loading) {
        isLoadingElement = (<tr><td colSpan={7}><SimpleSpinner myClassName={rows?.length == 0 ? 'h_45vh' : 'h_70'} /></td></tr>);
    }
    else {
        isLoadingElement = null;
    }

    const loadMoreRecords = () => {
        if (!loading) {
            fetchData(currentPage + 1);
        }
    }

    const [sentryRef] = useInfiniteScroll({
        loading: loading,
        hasNextPage: hasMore, // This can be dynamic based on your API response
        onLoadMore: loadMoreRecords,
        rootMargin: '0px 0px 100px 0px', // Adjust root margin as per your requirement
    });

    const handleTypeChange = (event, column) => {
        const selectedValue = event.target.value;
        setSelectedType((prevSelected) => {
            const updatedState = {
                ...prevSelected,
                [column]: selectedValue,
            };
            const result = Object.entries(updatedState)
                .map(([key, value]) => `${key} ${value}`)
                .join(",");
            setFullyQualifiedSortFilters(result);

            return updatedState;
        });
    };

    const handleCategoryChange = (event, column) => {
        const category = event.target.value;
        setSelectedCategories((prevSelected) => {
            const updatedColumnCategories = prevSelected[column] || [];
            if (updatedColumnCategories.includes(category)) {
                const updatedCategories = updatedColumnCategories.filter(
                    (c) => c !== category
                );
                const newSelectedCategories = {
                    ...prevSelected,
                    [column]:
                        updatedCategories.length > 0 ? updatedCategories : undefined,
                };

                Object.keys(newSelectedCategories).forEach((key) => {
                    if (
                        newSelectedCategories[key] === undefined ||
                        newSelectedCategories[key].length === 0
                    ) {
                        delete newSelectedCategories[key];
                    }
                });

                return newSelectedCategories;
            } else {
                return {
                    ...prevSelected,
                    [column]: [...updatedColumnCategories, category],
                };
            }
        });
    };

    useEffect(() => {
        const filterClauses = Object.entries(selectedCategories)
            .map(([column, values]) => {
                if (values && (values as string[]).length > 0) {
                    const valueClauses = (values as string[])
                        .map((value) => `${column}=="${value}"`)
                        .join(" or ");
                    return valueClauses ? `(${valueClauses})` : "";
                } else {
                    return null;
                }
            })
            .filter(Boolean);

        const newFullyQualifiedFilters =
            filterClauses.length > 0 ? filterClauses.join(" and ") : null;

        setFullyQualifiedFilters(newFullyQualifiedFilters);
    }, [selectedCategories, fullyQualifiedFilters, fullyQualifiedSortFilters]);

    const { Category, From, To, PaymentType, PaymentMethod } = distinctFilterValues;


    return (
        <div className="AccountingContainer relativeContainer">
            <div className="w-100">
                <div className="accountingHeader border-bottom-0">
                    <p className="top_header black">{rowCount} Transactions</p>
                    <div className="monthhead ">
                        <div className="monthhead ">
                            <button
                                onClick={() => setRev_MonthValue(-1)}
                                className={rev_monthValue === -1 ? "overviewSelectedButton sub_text fw-500 blue" : "overviewUnselectedButton sub_text fw-500 grey_subtext1"}
                            >
                                Last months
                            </button>
                            <button
                                onClick={() => setRev_MonthValue(-6)}
                                className={
                                    rev_monthValue === -6
                                        ? "overviewSelectedButton sub_text fw-500 blue"
                                        : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                                }
                            >
                                Last 6 month
                            </button>
                            <button
                                onClick={() => setRev_MonthValue(-12)}
                                className={
                                    rev_monthValue === -12
                                        ? "overviewSelectedButton sub_text fw-500 blue"
                                        : "overviewUnselectedButton sub_text fw-500 grey_subtext1"
                                }
                            >
                                Last 12 Months
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                (rows?.length == 0 && !loading) ?
                    (<EmptyData
                        mainText={undefined}
                        subText="Click on the chat from left to start the conversation"
                        button={undefined}
                    />
                    ) : (

                        <div className="accountinf_table customScrollbarContainer" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                            <Table bordered hover className="accountinf_table mb-0">
                                <thead className="stickyContainer">
                                    <tr>
                                        <th>
                                            <div className="d-flex flex-row justify-content-between align-items-center">
                                                <p className="normal_text fw-500 grey_subtext1 text-uppercase">Date</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex flex-row justify-content-between align-items-center">
                                                <p className="normal_text fw-500 grey_subtext1 text-uppercase">Category</p>
                                                <AccountingDropDown
                                                    sortName="paymentMethod.accountName"
                                                    selectedType={selectedType}
                                                    handleTypeChange={(e) =>
                                                        handleTypeChange(e, "paymentMethod.accountName")
                                                    }
                                                    data={From}
                                                    name="paymentMethod.accountName"
                                                    handleCategoryChange={(e) =>
                                                        handleCategoryChange(e, "paymentMethod.accountName")
                                                    }
                                                    selectedCategories={
                                                        selectedCategories["paymentMethod.accountName"] || []
                                                    }
                                                />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex flex-row justify-content-between align-items-center">
                                                {" "}
                                                <p className="normal_text fw-500 grey_subtext1 text-uppercase">Payment Method</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex flex-row justify-content-between align-items-center">
                                                {" "}
                                                <p className="normal_text fw-500 grey_subtext1 text-uppercase">Amount</p>{" "}
                                                <div></div>
                                                <AccountingDropDown
                                                    sortName="paymentMethod.PaymentMethodTypeId"
                                                    selectedType={selectedType}
                                                    handleTypeChange={(e) =>
                                                        handleTypeChange(
                                                            e,
                                                            "paymentMethod.PaymentMethodTypeId"
                                                        )
                                                    }
                                                    data={PaymentMethod}
                                                    name="paymentMethod.PaymentMethodTypeId"
                                                    handleCategoryChange={(e) =>
                                                        handleCategoryChange(
                                                            e,
                                                            "paymentMethod.PaymentMethodTypeId"
                                                        )
                                                    }
                                                    selectedCategories={
                                                        selectedCategories[
                                                        "paymentMethod.PaymentMethodTypeId"
                                                        ] || []
                                                    }
                                                />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="tableBody scrollContainer">
                                    {rows?.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p className="normal_text black">
                                                    {" "}
                                                    {DateHelper.convertUTCtoDateTimeSimple(
                                                        item?.formatted?.CreatedDateWithTime,
                                                        true
                                                    )}
                                                </p>{" "}
                                            </td>
                                            <td>
                                                <p className="normal_text black">
                                                    {" "}
                                                    {item?.paymentDueAccount?.formatted?.PaymentTypeId}
                                                </p>{" "}
                                            </td>
                                            <td>
                                                <p className="normal_text black">
                                                    {" "}
                                                    {item?.paymentMethod?.formatted?.PaymentMethodTypeId
                                                    }
                                                </p>{" "}
                                            </td>
                                            <td>
                                                <p className="normal_text black">
                                                    {" "}
                                                    ${item?.paymentDueAccount?.amount}
                                                </p>{" "}
                                            </td>
                                        </tr>
                                    ))}
                                    {isLoadingElement}
                                    <div ref={sentryRef} />
                                </tbody>
                            </Table>
                        </div>
                    )}
        </div>
    );
};

export default TransactionView;
